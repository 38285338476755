<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      Configuración de notificaciones del tag {{ data.tag }}
    </mat-card-title>
  </mat-card-header>

  <!-- Contenido -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <!-- Tabla -->
    <div>
      <app-tabla
        [datos]="configNotificaciones"
        [columnas]="columnas"
        [name]="name"
        [pageSizeOptions]="helper.pageSizeOptions"
        [paginatorArriba]="false"
        [paginatorAbajo]="false"
        [selectorColumnas]="false"
      ></app-tabla>
    </div>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <!-- Boton crear -->
    <button
      mat-raised-button
      color="accent"
      type="button"
      form="form"
      style="width: 100%"
      (click)="crear()"
    >
      Crear Nueva Configuración
    </button>
    <!-- Volver -->
    <button
      mat-raised-button
      type="button"
      form="form"
      style="width: 100%"
      (click)="dialogRef.close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
