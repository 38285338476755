import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Division,
  IConfigDispositivoNSP4G,
  IConfigDispositivoNUC4G,
  ICoordenadas,
  ICorrectora,
  ICromatografia,
  IDispositivo,
  IFilter,
  IMantenimiento,
  IMedidorResidencial,
  IMedidorResidencialAgua,
  IScada,
  IPuntoMedicion,
  IQueryParam,
  IReporteWRC,
  IUnidadPresion,
  Nivel,
  Rol,
  TipoDispositivo,
} from 'modelos/src';
import { LoginService } from '../modulos/login/login.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IRowIcon } from './tabla/tabla.component';

export enum batSemaforo {
  verde = 'green',
  amarillo = 'yellow',
  rojo = 'red',
  error = 'error',
}

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public regTel = /^(?!0|15)\d{10}$/;

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );

  public pageSize(name: string) {
    if (name) {
      const pageName = `page-${name}`;
      const saved = localStorage.getItem(pageName);
      if (saved) {
        return +saved;
      }
    }
    return 10;
  }
  public pageSizeOptions = [5, 10, 15, 25, 50, 100];

  static esModoOscuro(): boolean {
    return localStorage.getItem('temaOscuro') === 'true';
  }

  static filtroToObject<T>(filtro?: string): IFilter<T> {
    let filter: IFilter<T>;
    try {
      filter = JSON.parse(filtro || '{}');
    } catch (error) {
      filter = {} as any;
    }
    return filter;
  }

  static isDefined(value: any): boolean {
    return value !== undefined && value !== null;
  }

  static get mapOptions(): google.maps.MapOptions {
    if (HelperService.esModoOscuro()) {
      return HelperService.mapOptionsDark;
    } else {
      return HelperService.mapOptionsLight;
    }
  }

  public static readonly mapOptionsDark: google.maps.MapOptions = {
    zoom: 8,
    streetViewControl: false,
    fullscreenControl: false,
    rotateControlOptions: {
      // position: google.maps.ControlPosition.RIGHT_CENTER,
      position: 7,
    },
    zoomControl: false,
    zoomControlOptions: {
      // position: google.maps.ControlPosition.TOP_RIGHT,
      // position: 11,
    },
    isFractionalZoomEnabled: true,
    mapTypeId: 'roadmap',
    mapTypeControl: true,
    mapTypeControlOptions: {
      mapTypeIds: ['satellite', 'roadmap', 'hybrid', 'terrain'],
      // style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      // position: google.maps.ControlPosition.TOP_LEFT,
      // style: 2,
      // position: 10,
    },
    styles: [
      { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
      { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
      {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#c8c8c8' }],
      },
      {
        featureType: 'administrative.province',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#c8c8c8' }],
      },
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
      },
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#38414e' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#212a37' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9ca5b3' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#746855' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#1f2835' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#f3d19c' }],
      },
      {
        featureType: 'transit',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#17263c' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#515c6d' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#17263c' }],
      },
    ],
  };

  public static readonly mapOptionsLight: google.maps.MapOptions = {
    zoom: 8,
    streetViewControl: false,
    fullscreenControl: false,
    rotateControlOptions: {
      // position: google.maps.ControlPosition.RIGHT_CENTER,
      position: 7,
    },
    zoomControl: true,
    zoomControlOptions: {
      // position: google.maps.ControlPosition.TOP_RIGHT,
      position: 11,
    },
    isFractionalZoomEnabled: true,
    mapTypeId: 'roadmap',
    mapTypeControl: true,
    mapTypeControlOptions: {
      mapTypeIds: ['satellite', 'roadmap', 'hybrid', 'terrain'],
      // style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      // position: google.maps.ControlPosition.TOP_LEFT,
      style: 2,
      position: 10,
    },
    styles: [
      {
        featureType: 'transit',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };

  public static readonly mapOptionsEditor: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    scrollwheel: null,
    gestureHandling: 'cooperative',
    zoom: 15,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: true,
    zoomControlOptions: {
      // position: google.maps.ControlPosition.TOP_RIGHT,
      position: 11,
    },
    mapTypeControlOptions: {
      mapTypeIds: ['satellite', 'roadmap', 'hybrid', 'terrain'],
      // style: google.maps.MapTypeControlStyle.DEFAULT,
      style: 0,
    },
  };

  constructor(
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
  ) {}

  public pageEvent($event: any): void {
    localStorage.setItem('pageSize', $event.pageSize);
    this.pageSize = $event.pageSize;
  }

  public static getQueryParams(queryParams?: IQueryParam) {
    let params = new HttpParams();
    if (queryParams) {
      const keysIgnorar = [
        'page',
        'limit',
        'sort',
        'populate',
        'select',
        'filter',
      ];
      if (queryParams?.page || queryParams?.page === 0) {
        params = params.set('page', queryParams.page.toString());
      }
      if (queryParams?.limit || queryParams?.limit === 0) {
        params = params.set('limit', queryParams.limit.toString());
      }
      if (queryParams?.sort) {
        params = params.set('sort', queryParams.sort);
      }
      if (queryParams?.populate) {
        params = params.set('populate', queryParams.populate);
      }
      if (queryParams?.select) {
        params = params.set('select', queryParams.select);
      }
      if (queryParams?.filter) {
        params = params.set('filter', queryParams.filter);
      }

      for (const key in queryParams) {
        if (!keysIgnorar.includes(key)) {
          params = params.set(key, queryParams[key]);
        }
      }
    }
    return params;
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////// Usuarios ////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  // Roles Nivel Global

  public esAdminGlobal() {
    const user = LoginService.getUsuario();
    const permiso = user.permisos?.find(
      (x) => x.nivel === 'Global' && x.rol === 'Administrador',
    );
    return !!permiso;
  }

  static nivelMasAlto(): Nivel {
    const user = LoginService.getUsuario();
    const global = user.permisos?.find((x) => x.nivel === 'Global');
    if (global) {
      return 'Global';
    }
    const unidadNegocio = user.permisos?.find(
      (x) => x.nivel === 'Unidad de Negocio',
    );
    if (unidadNegocio) {
      return 'Unidad de Negocio';
    }
    return 'Centro Operativo';
  }

  static nivelMasAltoPorDivision(division: Division): Nivel {
    const user = LoginService.getUsuario();
    const global = user.permisos?.find((x) => x.nivel === 'Global');
    if (global) {
      return 'Global';
    }
    const unidadNegocio = user.permisos?.find(
      (x) => x.division === division && x.nivel === 'Unidad de Negocio',
    );
    if (unidadNegocio) {
      return 'Unidad de Negocio';
    }
    return 'Centro Operativo';
  }

  static rolesGlobales(roles: Rol[]) {
    const user = LoginService.getUsuario();
    const permiso = user.permisos?.find(
      (x) => x.nivel === 'Global' && roles.includes(x.rol!),
    );
    return !!permiso;
  }

  static tieneCuencaPorRolCroma(idCuenca: string) {
    const user = LoginService.getUsuario();
    const permiso = user.permisos?.find(
      (x) => x.rol === 'Croma' && x.idsCuencas?.includes(idCuenca),
    );
    return !!permiso;
  }

  static cuencasPorRolCroma() {
    const user = LoginService.getUsuario();
    const permisos = user.permisos?.filter((x) => x.rol === 'Croma');
    return permisos?.map((x) => x.idsCuencas).flat();
  }

  // Roles Nivel Unidad de Negocio
  static adminGlobal() {
    const user = LoginService.getUsuario();
    const permiso = user.permisos?.find(
      (x) => x.nivel === 'Global' && x.rol === 'Administrador',
    );
    return !!permiso;
  }
  static rolesEnCualquierUnidadNegocio(roles: Rol[]) {
    const user = LoginService.getUsuario();
    const permiso = user.permisos?.find(
      (x) => x.nivel === 'Unidad de Negocio' && roles.includes(x.rol!),
    );
    return !!permiso;
  }
  static rolesPorUnidadNegocio(roles: Rol[], idUnidadNegocio: string) {
    const user = LoginService.getUsuario();
    const permiso = user.permisos?.find(
      (x) =>
        x.nivel === 'Unidad de Negocio' &&
        roles.includes(x.rol!) &&
        x.idsUnidadNegocios?.includes(idUnidadNegocio),
    );
    return !!permiso;
  }

  // Roles Nivel Operativo
  static rolesEnCualquierCentroOperativo(roles: Rol[]) {
    const user = LoginService.getUsuario();
    const permiso = user.permisos?.find(
      (x) => x.nivel === 'Centro Operativo' && roles.includes(x.rol!),
    );
    return !!permiso;
  }
  static rolesEnCualquierLocalidad(roles: Rol[]) {
    const user = LoginService.getUsuario();
    const permiso = user.permisos?.find(
      (x) => x.nivel === 'Localidad' && roles.includes(x.rol!),
    );
    return !!permiso;
  }
  static rolesPorCentroOperativo(roles: Rol[], idCentroOperativo: string) {
    const user = LoginService.getUsuario();
    const permiso = user.permisos?.find(
      (x) =>
        x.nivel === 'Centro Operativo' &&
        roles.includes(x.rol!) &&
        x.idsCentroOperativos?.includes(idCentroOperativo),
    );
    return !!permiso;
  }
  static rolesEnCualquierAgrupacion(roles: Rol[]) {
    const user = LoginService.getUsuario();
    const permiso = user.permisos?.find(
      (x) => x.nivel === 'Agrupación' && roles.includes(x.rol!),
    );
    return !!permiso;
  }

  // Roles Nivel Unidad negocio y Division
  static rolesEnCualquierUnidadNegocioYDivision(
    roles: Rol[],
    divisiones: Division[],
  ) {
    const user = LoginService.getUsuario();
    const permiso = user.permisos?.find(
      (x) =>
        x.nivel === 'Unidad de Negocio' &&
        roles.includes(x.rol!) &&
        divisiones.includes(x.division!),
    );
    return !!permiso;
  }
  static rolesPorUnidadNegocioYDivision(
    roles: Rol[],
    idUnidadNegocio: string,
    divisiones: Division[],
  ) {
    const user = LoginService.getUsuario();
    const permiso = user.permisos?.find(
      (x) =>
        x.nivel === 'Unidad de Negocio' &&
        roles.includes(x.rol!) &&
        x.idsUnidadNegocios?.includes(idUnidadNegocio) &&
        divisiones.includes(x.division!),
    );
    return !!permiso;
  }

  // Roles Nivel Operativo y Division
  static rolesEnCualquierCentroOperativoYDivision(
    roles: Rol[],
    divisiones: Division[],
  ) {
    const user = LoginService.getUsuario();
    const permiso = user.permisos?.find(
      (x) =>
        x.nivel === 'Centro Operativo' &&
        roles.includes(x.rol!) &&
        divisiones.includes(x.division!),
    );
    return !!permiso;
  }
  static rolesPorCentroOperativoYDivision(
    roles: Rol[],
    idCentroOperativo: string,
    divisiones: Division[],
  ) {
    const user = LoginService.getUsuario();
    const permiso = user.permisos?.find(
      (x) =>
        x.nivel === 'Centro Operativo' &&
        roles.includes(x.rol!) &&
        x.idsCentroOperativos?.includes(idCentroOperativo) &&
        divisiones.includes(x.division!),
    );
    return !!permiso;
  }

  // Permisos por entidades
  public puedeVerCorrectoras() {
    const user = LoginService.getUsuario();
    // Tipo de dispositivo por Cliente
    if (user.cliente?.tiposDispositivo?.includes('NUC')) {
      // Roles Globales
      if (
        HelperService.rolesGlobales(['Administrador', 'Usuario', 'Visualizar'])
      ) {
        return true;
      }
      // Roles por unidad de negocio
      if (
        HelperService.rolesEnCualquierUnidadNegocioYDivision(
          ['Administrador', 'Usuario', 'Visualizar'],
          ['Correctoras'],
        )
      ) {
        return true;
      }
      // Roles por centro operativo
      if (
        HelperService.rolesEnCualquierCentroOperativoYDivision(
          ['Administrador', 'Usuario', 'Visualizar'],
          ['Correctoras'],
        )
      ) {
        return true;
      }
    }
    return false;
  }
  public puedeEditarCorrectora(data?: ICorrectora) {
    if (!data) {
      return false;
    }

    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador', 'Usuario'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      data.idUnidadNegocio &&
      HelperService.rolesPorUnidadNegocioYDivision(
        ['Administrador', 'Usuario'],
        data.idUnidadNegocio,
        ['Correctoras'],
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      data.idCentroOperativo &&
      HelperService.rolesPorCentroOperativoYDivision(
        ['Administrador', 'Usuario'],
        data.idCentroOperativo,
        ['Correctoras'],
      )
    ) {
      return true;
    }
    return false;
  }
  public puedeEliminarCorrectora(data: ICorrectora) {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      data.idUnidadNegocio &&
      HelperService.rolesPorUnidadNegocioYDivision(
        ['Administrador'],
        data.idUnidadNegocio,
        ['Correctoras'],
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      data.idCentroOperativo &&
      HelperService.rolesPorCentroOperativoYDivision(
        ['Administrador'],
        data.idCentroOperativo,
        ['Correctoras'],
      )
    ) {
      return true;
    }
    return false;
  }

  public puedeVerUnidadPresion() {
    const user = LoginService.getUsuario();
    // Tipo de dispositivo por Cliente
    if (user.cliente?.tiposDispositivo?.includes('NSP')) {
      // Roles Globales
      if (
        HelperService.rolesGlobales(['Administrador', 'Usuario', 'Visualizar'])
      ) {
        return true;
      }
      // Roles por unidad de negocio
      if (
        HelperService.rolesEnCualquierUnidadNegocioYDivision(
          ['Administrador', 'Usuario', 'Visualizar'],
          ['Presión'],
        )
      ) {
        return true;
      }
      // Roles por centro operativo
      if (
        HelperService.rolesEnCualquierCentroOperativoYDivision(
          ['Administrador', 'Usuario', 'Visualizar'],
          ['Presión'],
        )
      ) {
        return true;
      }
    }
    return false;
  }
  public puedeEditarUnidadPresion(data?: IUnidadPresion) {
    if (!data) {
      return false;
    }

    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador', 'Usuario'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      data.idUnidadNegocio &&
      HelperService.rolesPorUnidadNegocioYDivision(
        ['Administrador', 'Usuario'],
        data.idUnidadNegocio,
        ['Presión'],
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      data.idCentroOperativo &&
      HelperService.rolesPorCentroOperativoYDivision(
        ['Administrador', 'Usuario'],
        data.idCentroOperativo,
        ['Presión'],
      )
    ) {
      return true;
    }
    return false;
  }
  public puedeEliminarUnidadPresion(data: IUnidadPresion) {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      data.idUnidadNegocio &&
      HelperService.rolesPorUnidadNegocioYDivision(
        ['Administrador'],
        data.idUnidadNegocio,
        ['Presión'],
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      data.idCentroOperativo &&
      HelperService.rolesPorCentroOperativoYDivision(
        ['Administrador'],
        data.idCentroOperativo,
        ['Presión'],
      )
    ) {
      return true;
    }
    return false;
  }

  public puedeVerScadaMediciones() {
    const user = LoginService.getUsuario();
    // Tipo de dispositivo por Cliente
    if (user.cliente?.tiposDispositivo?.includes('SCADA Mediciones')) {
      // Roles Globales
      if (
        HelperService.rolesGlobales(['Administrador', 'Usuario', 'Visualizar'])
      ) {
        return true;
      }
      // Roles por unidad de negocio
      if (
        HelperService.rolesEnCualquierUnidadNegocioYDivision(
          ['Administrador', 'Usuario', 'Visualizar'],
          ['SCADA Mediciones'],
        )
      ) {
        return true;
      }
      // Roles por centro operativo
      if (
        HelperService.rolesEnCualquierCentroOperativoYDivision(
          ['Administrador', 'Usuario', 'Visualizar'],
          ['SCADA Mediciones'],
        )
      ) {
        return true;
      }
    }
    return false;
  }
  public puedeEditarScadaMediciones(data?: IScada) {
    if (!data) {
      return false;
    }

    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador', 'Usuario'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      data.idUnidadNegocio &&
      HelperService.rolesPorUnidadNegocioYDivision(
        ['Administrador', 'Usuario'],
        data.idUnidadNegocio,
        ['SCADA Mediciones'],
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      data.idCentroOperativo &&
      HelperService.rolesPorCentroOperativoYDivision(
        ['Administrador', 'Usuario'],
        data.idCentroOperativo,
        ['SCADA Mediciones'],
      )
    ) {
      return true;
    }
    return false;
  }
  public puedeEliminarScadaMediciones(data: IScada) {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      data.idUnidadNegocio &&
      HelperService.rolesPorUnidadNegocioYDivision(
        ['Administrador'],
        data.idUnidadNegocio,
        ['SCADA Mediciones'],
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      data.idCentroOperativo &&
      HelperService.rolesPorCentroOperativoYDivision(
        ['Administrador'],
        data.idCentroOperativo,
        ['SCADA Mediciones'],
      )
    ) {
      return true;
    }
    return false;
  }

  public puedeVerScadaUnifilares() {
    const user = LoginService.getUsuario();
    // Tipo de dispositivo por Cliente
    if (user.cliente?.tiposDispositivo?.includes('SCADA Unifilares')) {
      // Roles Globales
      if (
        HelperService.rolesGlobales(['Administrador', 'Usuario', 'Visualizar'])
      ) {
        return true;
      }
      // Roles por unidad de negocio
      if (
        HelperService.rolesEnCualquierUnidadNegocioYDivision(
          ['Administrador', 'Usuario', 'Visualizar'],
          ['SCADA Unifilares'],
        )
      ) {
        return true;
      }
      // Roles por centro operativo
      if (
        HelperService.rolesEnCualquierCentroOperativoYDivision(
          ['Administrador', 'Usuario', 'Visualizar'],
          ['SCADA Unifilares'],
        )
      ) {
        return true;
      }
    }
    return false;
  }
  public puedeEditarScadaUnifilares(data?: IScada) {
    if (!data) {
      return false;
    }

    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador', 'Usuario'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      data.idUnidadNegocio &&
      HelperService.rolesPorUnidadNegocioYDivision(
        ['Administrador', 'Usuario'],
        data.idUnidadNegocio,
        ['SCADA Unifilares'],
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      data.idCentroOperativo &&
      HelperService.rolesPorCentroOperativoYDivision(
        ['Administrador', 'Usuario'],
        data.idCentroOperativo,
        ['SCADA Unifilares'],
      )
    ) {
      return true;
    }
    return false;
  }
  public puedeEliminarScadaUnifilares(data: IScada) {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      data.idUnidadNegocio &&
      HelperService.rolesPorUnidadNegocioYDivision(
        ['Administrador'],
        data.idUnidadNegocio,
        ['SCADA Unifilares'],
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      data.idCentroOperativo &&
      HelperService.rolesPorCentroOperativoYDivision(
        ['Administrador'],
        data.idCentroOperativo,
        ['SCADA Unifilares'],
      )
    ) {
      return true;
    }
    return false;
  }

  public puedeVerResidencial() {
    const user = LoginService.getUsuario();
    const medidores: TipoDispositivo[] = ['SML', 'WRC'];
    // Tipo de dispositivo por Cliente
    if (user.cliente?.tiposDispositivo?.some((x) => medidores.includes(x))) {
      // Roles Globales
      if (
        HelperService.rolesGlobales(['Administrador', 'Usuario', 'Visualizar'])
      ) {
        return true;
      }
      // Roles por unidad de negocio
      if (
        HelperService.rolesEnCualquierUnidadNegocioYDivision(
          ['Administrador', 'Usuario', 'Visualizar'],
          ['Residencial'],
        )
      ) {
        return true;
      }
      // Roles por centro operativo
      if (
        HelperService.rolesEnCualquierCentroOperativoYDivision(
          ['Administrador', 'Usuario', 'Visualizar'],
          ['Residencial'],
        )
      ) {
        return true;
      }
    }
    return false;
  }
  public puedeEditarResidencial(data?: IUnidadPresion) {
    if (!data) {
      return false;
    }

    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador', 'Usuario'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      data.idUnidadNegocio &&
      HelperService.rolesPorUnidadNegocioYDivision(
        ['Administrador', 'Usuario'],
        data.idUnidadNegocio,
        ['Residencial'],
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      data.idCentroOperativo &&
      HelperService.rolesPorCentroOperativoYDivision(
        ['Administrador', 'Usuario'],
        data.idCentroOperativo,
        ['Residencial'],
      )
    ) {
      return true;
    }
    return false;
  }
  public puedeEliminarResidencial(data: IUnidadPresion) {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      data.idUnidadNegocio &&
      HelperService.rolesPorUnidadNegocioYDivision(
        ['Administrador'],
        data.idUnidadNegocio,
        ['Residencial'],
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      data.idCentroOperativo &&
      HelperService.rolesPorCentroOperativoYDivision(
        ['Administrador'],
        data.idCentroOperativo,
        ['Residencial'],
      )
    ) {
      return true;
    }
    return false;
  }

  public puedeVerMedidores() {
    const user = LoginService.getUsuario();
    // Tipo de dispositivo por Cliente
    if (user.cliente?.tiposDispositivo?.includes('SML')) {
      // Roles Globales
      if (
        HelperService.rolesGlobales(['Administrador', 'Usuario', 'Visualizar'])
      ) {
        return true;
      }
      // Roles por unidad de negocio
      if (
        HelperService.rolesEnCualquierUnidadNegocio([
          'Administrador',
          'Usuario',
          'Visualizar',
        ])
      ) {
        return true;
      }
      // Roles por centro operativo
      if (
        HelperService.rolesEnCualquierCentroOperativo([
          'Administrador',
          'Usuario',
          'Visualizar',
        ])
      ) {
        return true;
      }
    }

    return false;
  }
  public puedeEditarMedidor(data?: IMedidorResidencial) {
    if (!data) return false;
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador', 'Usuario'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      data.idUnidadNegocio &&
      HelperService.rolesPorUnidadNegocio(
        ['Administrador', 'Usuario'],
        data.idUnidadNegocio,
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      data.idCentroOperativo &&
      HelperService.rolesPorCentroOperativo(
        ['Administrador', 'Usuario'],
        data.idCentroOperativo,
      )
    ) {
      return true;
    }
    return false;
  }
  public puedeEliminarMedidor(data?: IMedidorResidencial) {
    if (!data) return false;
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      data.idUnidadNegocio &&
      HelperService.rolesPorUnidadNegocio(
        ['Administrador'],
        data.idUnidadNegocio,
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      data.idCentroOperativo &&
      HelperService.rolesPorCentroOperativo(
        ['Administrador'],
        data.idCentroOperativo,
      )
    ) {
      return true;
    }
    return false;
  }

  public puedeVerMra() {
    const user = LoginService.getUsuario();
    // Tipo de dispositivo por Cliente
    if (user.cliente?.tiposDispositivo?.includes('MRA')) {
      // Roles Globales
      if (
        HelperService.rolesGlobales(['Administrador', 'Usuario', 'Visualizar'])
      ) {
        return true;
      }
      // Roles por unidad de negocio
      if (
        HelperService.rolesEnCualquierUnidadNegocio([
          'Administrador',
          'Usuario',
          'Visualizar',
        ])
      ) {
        return true;
      }
      // Roles por centro operativo
      if (
        HelperService.rolesEnCualquierCentroOperativo([
          'Administrador',
          'Usuario',
          'Visualizar',
        ])
      ) {
        return true;
      }
    }

    return false;
  }
  public puedeEditarMra(data: IMedidorResidencialAgua) {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador', 'Usuario'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      data.idUnidadNegocio &&
      HelperService.rolesPorUnidadNegocio(
        ['Administrador', 'Usuario'],
        data.idUnidadNegocio,
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      data.idCentroOperativo &&
      HelperService.rolesPorCentroOperativo(
        ['Administrador', 'Usuario'],
        data.idCentroOperativo,
      )
    ) {
      return true;
    }
    return false;
  }
  public puedeEliminarMra(data: IMedidorResidencialAgua) {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      data.idUnidadNegocio &&
      HelperService.rolesPorUnidadNegocio(
        ['Administrador'],
        data.idUnidadNegocio,
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      data.idCentroOperativo &&
      HelperService.rolesPorCentroOperativo(
        ['Administrador'],
        data.idCentroOperativo,
      )
    ) {
      return true;
    }
    return false;
  }

  public puedeVerUsuarios() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    return false;
  }

  public puedeVerPuntosDeMedicion() {
    const user = LoginService.getUsuario();
    // Tipo de dispositivo por Cliente
    if (
      user.cliente?.tiposDispositivo?.includes('NUC') ||
      user.cliente?.tiposDispositivo?.includes('NSP') ||
      user.cliente?.tiposDispositivo?.includes('SML') ||
      user.cliente?.tiposDispositivo?.includes('WRC') ||
      user.cliente?.tiposDispositivo?.includes('SCADA Mediciones') ||
      user.cliente?.tiposDispositivo?.includes('SCADA Unifilares')
    ) {
      // Roles Globales
      if (
        HelperService.rolesGlobales(['Administrador', 'Usuario', 'Visualizar'])
      ) {
        return true;
      }
      // Roles por unidad de negocio
      if (
        HelperService.rolesEnCualquierUnidadNegocio([
          'Administrador',
          'Usuario',
          'Visualizar',
        ])
      ) {
        return true;
      }
      // Roles por centro operativo
      if (
        HelperService.rolesEnCualquierCentroOperativo([
          'Administrador',
          'Usuario',
          'Visualizar',
        ])
      ) {
        return true;
      }
      // Roles por localidad
      if (
        HelperService.rolesEnCualquierLocalidad([
          'Administrador',
          'Usuario',
          'Visualizar',
        ])
      ) {
        return true;
      }
      // Roles por agrupacion
      if (
        HelperService.rolesEnCualquierAgrupacion([
          'Administrador',
          'Usuario',
          'Visualizar',
        ])
      ) {
        return true;
      }
    }
    return false;
  }
  public puedeCrearPuntosDeMedicion() {
    const user = LoginService.getUsuario();
    const tipos: TipoDispositivo[] = [
      'NUC',
      'NSP',
      'SML',
      'WRC',
      'VERIBOX MICRO',
      'SCADA Mediciones',
      'SCADA Unifilares',
    ];
    // Tipo de dispositivo por Cliente
    if (user.cliente?.tiposDispositivo?.some((x) => tipos.includes(x))) {
      // Roles Globales
      if (HelperService.rolesGlobales(['Administrador', 'Usuario'])) {
        return true;
      }
      // Roles por unidad de negocio
      if (
        HelperService.rolesEnCualquierUnidadNegocio([
          'Administrador',
          'Usuario',
        ])
      ) {
        return true;
      }
      // Roles por centro operativo
      if (
        HelperService.rolesEnCualquierCentroOperativo([
          'Administrador',
          'Usuario',
        ])
      ) {
        return true;
      }
    }
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public puedeEditarPuntosDeMedicion(data: IPuntoMedicion) {
    const user = LoginService.getUsuario();
    const tipos: TipoDispositivo[] = [
      'NUC',
      'NSP',
      'SML',
      'WRC',
      'VERIBOX MICRO',
      'SCADA Mediciones',
      'SCADA Unifilares',
    ];
    // Tipo de dispositivo por Cliente
    if (user.cliente?.tiposDispositivo?.some((x) => tipos.includes(x))) {
      // Roles Globales
      if (HelperService.rolesGlobales(['Administrador', 'Usuario'])) {
        return true;
      }
      // Roles por unidad de negocio
      if (
        HelperService.rolesEnCualquierUnidadNegocio([
          'Administrador',
          'Usuario',
        ])
      ) {
        return true;
      }
      // Roles por centro operativo
      if (
        HelperService.rolesEnCualquierCentroOperativo([
          'Administrador',
          'Usuario',
        ])
      ) {
        return true;
      }
    }
    return false;
  }
  public puedeEliminarPuntosDeMedicion(data: IPuntoMedicion) {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      data.idUnidadNegocio &&
      HelperService.rolesPorUnidadNegocio(
        ['Administrador'],
        data.idUnidadNegocio,
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      data.idCentroOperativo &&
      HelperService.rolesPorCentroOperativo(
        ['Administrador'],
        data.idCentroOperativo,
      )
    ) {
      return true;
    }
    return false;
  }

  public puedeEditarDispositivos() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }
    return false;
  }

  public puedeConfigurarNSP() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }
    // Roles por unidad de negocio
    if (HelperService.rolesEnCualquierUnidadNegocio(['Administrador'])) {
      return true;
    }
    // Roles por centro operativo
    if (HelperService.rolesEnCualquierCentroOperativo(['Administrador'])) {
      return true;
    }
    return false;
  }

  public puedeConfigurarVeribox(dispositivo: IDispositivo) {
    // Si no reportó nunca
    const ultimoReporte = dispositivo?.fechaUltimaComunicacion;
    if (!ultimoReporte) {
      return false;
    }

    // Si no reportó en los últimos 2 días
    const fechaMinima = new Date();
    fechaMinima.setDate(fechaMinima.getDate() - 2);
    const fechaReporte = new Date(ultimoReporte);
    if (fechaReporte < fechaMinima) {
      return false;
    }

    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }
    // Roles por unidad de negocio
    if (HelperService.rolesEnCualquierUnidadNegocio(['Administrador'])) {
      return true;
    }
    // Roles por centro operativo
    if (HelperService.rolesEnCualquierCentroOperativo(['Administrador'])) {
      return true;
    }
    return false;
  }

  public puedeListarDispositivos() {
    // Roles Globales
    if (
      HelperService.rolesGlobales(['Administrador', 'Usuario', 'Visualizar'])
    ) {
      return true;
    }
    // Roles por unidad de negocio
    if (
      HelperService.rolesEnCualquierUnidadNegocio([
        'Administrador',
        'Usuario',
        'Visualizar',
      ])
    ) {
      return true;
    }
    // Roles por centro operativo
    if (
      HelperService.rolesEnCualquierCentroOperativo([
        'Administrador',
        'Usuario',
        'Visualizar',
      ])
    ) {
      return true;
    }
    return false;
  }
  public puedeVerDispositivos() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador', 'Usuario'])) {
      return true;
    }
    // Roles por unidad de negocio
    if (
      HelperService.rolesEnCualquierUnidadNegocio(['Administrador', 'Usuario'])
    ) {
      return true;
    }
    // Roles por centro operativo
    if (
      HelperService.rolesEnCualquierCentroOperativo([
        'Administrador',
        'Usuario',
      ])
    ) {
      return true;
    }
    return false;
  }

  public puedeVerUnidadesNegocio() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }
    return false;
  }

  public puedeVerAuditorias() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }
    return false;
  }

  public puedeVerCentrosOperativos() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }
    return false;
  }

  public puedeVerLocalidades() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }
    return false;
  }

  public puedeListarCuencas() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (HelperService.rolesEnCualquierUnidadNegocio(['Administrador'])) {
      return true;
    }

    // Roles por centro operativo
    if (HelperService.rolesEnCualquierCentroOperativo(['Administrador'])) {
      return true;
    }

    return false;
  }
  public puedeVerCuencas() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }
    return false;
  }

  public puedeListarGrupos() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (HelperService.rolesEnCualquierUnidadNegocio(['Administrador'])) {
      return true;
    }

    // Roles por centro operativo
    if (HelperService.rolesEnCualquierCentroOperativo(['Administrador'])) {
      return true;
    }

    return false;
  }
  public puedeVerGrupos() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (HelperService.rolesEnCualquierUnidadNegocio(['Administrador'])) {
      return true;
    }

    // Roles por centro operativo
    if (HelperService.rolesEnCualquierCentroOperativo(['Administrador'])) {
      return true;
    }

    return false;
  }

  public puedeVerAgrupaciones() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    return false;
  }

  public puedeVerSms() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      HelperService.rolesEnCualquierUnidadNegocioYDivision(
        ['Administrador'],
        ['Presión'],
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      HelperService.rolesEnCualquierCentroOperativoYDivision(
        ['Administrador'],
        ['Presión'],
      )
    ) {
      return true;
    }
    return false;
  }

  public puedeVerCromatografias() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (
      HelperService.rolesEnCualquierUnidadNegocioYDivision(
        ['Administrador', 'Croma'],
        ['Correctoras'],
      )
    ) {
      return true;
    }

    // Roles por centro operativo
    if (
      HelperService.rolesEnCualquierCentroOperativoYDivision(
        ['Administrador', 'Croma'],
        ['Correctoras'],
      )
    ) {
      return true;
    }

    return false;
  }

  public puedeVerApns() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }
    return false;
  }

  public puedeCrearCromatografias() {
    // Roles Globales
    if (HelperService.rolesGlobales(['Croma'])) {
      return true;
    }

    // Roles por unidad de negocio
    if (HelperService.rolesEnCualquierUnidadNegocio(['Croma'])) {
      return true;
    }

    // Roles por centro operativo
    if (HelperService.rolesEnCualquierCentroOperativo(['Croma'])) {
      return true;
    }

    return false;
  }
  public puedeEditarCromatografia(data: ICromatografia) {
    // Roles Globales
    if (HelperService.rolesGlobales(['Croma'])) {
      return true;
    }

    // // Roles Nivel unidad de negocio
    // if (
    //   data.idUnidadNegocio &&
    //   HelperService.rolesPorUnidadNegocio(
    //     ['Administrador'],
    //     data.idUnidadNegocio
    //   )
    // ) {
    //   return true;
    // }

    // Rol Croma Nivel
    if (data.idCuenca && HelperService.tieneCuencaPorRolCroma(data.idCuenca)) {
      return true;
    }

    return false;
  }
  public puedeEliminarCromatografia(data: ICromatografia) {
    // Roles Globales
    if (HelperService.rolesGlobales(['Croma'])) {
      return true;
    }

    // Roles Nivel unidad de negocio
    // if (
    //   data.idUnidadNegocio &&
    //   HelperService.rolesPorUnidadNegocio(
    //     ['Administrador'],
    //     data.idUnidadNegocio
    //   )
    // ) {
    //   return true;
    // }

    // Rol Croma Nivel
    if (data.idCuenca && HelperService.tieneCuencaPorRolCroma(data.idCuenca)) {
      return true;
    }

    return false;
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////// Usuarios ////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  // Notificaciones
  public notifError(error: any): void {
    console.log(error);
    const mensaje =
      error?.error?.mensaje ||
      error?.mensaje ||
      error?.error?.message ||
      error?.message ||
      'Error desconocido, ver logs';
    console.error(mensaje);
    this.snackBar.open(mensaje, 'Cerrar', { panelClass: ['red-snackbar'] });
  }
  public notifSuccess(mensaje: string): void {
    console.log(mensaje);
    this.snackBar.open(mensaje, 'Cerrar', {
      duration: 3000,
      panelClass: ['green-snackbar'],
    });
  }
  public notifWarn(mensaje: string): void {
    console.log(mensaje);
    this.snackBar.open(mensaje, 'Cerrar', { panelClass: ['yellow-snackbar'] });
  }

  public puedeEditarMantenimiento(data?: IMantenimiento) {
    if (!data) {
      return false;
    }

    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    return false;
  }
  public puedeEliminarMantenimiento(data: IMantenimiento) {
    if (!data) {
      return false;
    }

    // Roles Globales
    if (HelperService.rolesGlobales(['Administrador'])) {
      return true;
    }

    return false;
  }

  // Dispositivos

  public bateriaDispositivoNuc(dispositivo?: IDispositivo): string {
    const config = dispositivo?.config as IConfigDispositivoNUC4G & {
      voltajeBateria?: number;
      firmwareNuc?: string;
    };
    const firmware = config?.firmwareNuc;
    const bateria = config?.voltajeBateria;

    if (bateria === undefined || bateria === null) {
      return 'Sin Reportar';
    }

    if (firmware === 'v18') {
      return '3.61v';
    }

    return `${bateria.toFixed(2)}v`;
  }

  // Punto de medicion

  public estadoPuntoMedicion(punto: IPuntoMedicion) {
    switch (punto.division) {
      case 'Correctoras': {
        return this.estadoCorrectora(punto.correctora);
      }
      case 'Presión': {
        return this.estadoUnidadPresion(punto.unidadPresion);
      }
      default: {
        return 'Ok';
      }
    }
  }

  // Correctoras

  public getUltimaComunicacion(correctora?: ICorrectora): string {
    const ultimoRegistro = correctora?.ultimoRegistro;
    const ultimaAlerta = correctora?.ultimaAlerta;

    const fechaUltimoRegistro = ultimoRegistro?.fechaCreacion;
    const fechaUltimaAlerta = ultimaAlerta?.fechaCreacion;

    if (fechaUltimoRegistro && fechaUltimaAlerta) {
      return fechaUltimoRegistro > fechaUltimaAlerta
        ? fechaUltimoRegistro
        : fechaUltimaAlerta;
    }

    if (fechaUltimoRegistro) {
      return fechaUltimoRegistro;
    }

    if (fechaUltimaAlerta) {
      return fechaUltimaAlerta;
    }

    return '';
  }

  private getFrecReporteNUC(config: IConfigDispositivoNUC4G) {
    const frec = config?.modoOperacion;
    switch (frec) {
      case 'REG8_8HORAS':
        return 8;
      case 'REG1_DIARIO':
        return 24;
      case 'REG24_DIARIO':
        return 24;
      default:
        return 24;
    }
  }

  public estadoCorrectora(correctora?: ICorrectora) {
    if (!correctora?.dispositivo) return 'Sin dispositivo';

    if (correctora?.estadoActual === 'En Mantenimiento') {
      return 'En Mantenimiento';
    }

    if (correctora?.estadoActual === 'Resolver') {
      return 'Resolver';
    }

    const config = correctora?.dispositivo?.config as IConfigDispositivoNUC4G;
    const frecComunicacionHs = this.getFrecReporteNUC(config);
    // const timestampMaximo = frecComunicacionHs * 60 * 60 * 1000;
    const fechaLimite = this.fechaHaceXHoras(frecComunicacionHs);

    const f1 = correctora?.ultimoRegistro?.fechaCreacion
      ? new Date(correctora?.ultimoRegistro.fechaCreacion)
      : undefined;
    const f2 = correctora?.ultimoRegistro?.timestamp
      ? new Date(correctora?.ultimoRegistro.timestamp)
      : undefined;

    const ultimoReporte = this.fechaMasReciente(f1, f2);
    const ultimaAlerta = correctora?.ultimaAlerta?.fechaCreacion
      ? new Date(correctora?.ultimaAlerta.fechaCreacion)
      : null;

    // Hay ultima alerta y es mas reciente que la fecha limite
    if (ultimaAlerta && ultimaAlerta > fechaLimite) {
      // No hay ultimo reporte o la ultima alerta es mas reciente que el ultimo reporte
      if (!ultimoReporte || ultimaAlerta > ultimoReporte) {
        return 'Alerta';
      }
    }

    // No hay ultimo reporte o la fecha limite es mas reciente que el ultimo reporte
    if (!ultimoReporte || fechaLimite > ultimoReporte) {
      return 'Sin reportar';
    }

    return 'Ok';
  }

  public bateriaCorrectora(correctora?: ICorrectora): string {
    const bateria = correctora?.bateria;

    if (bateria || bateria === 0) {
      const modelo = correctora?.modelo;
      if (
        modelo === 'Mercury' ||
        modelo === 'Proser' ||
        modelo === 'Dresser2' ||
        modelo === 'AmericanMeter'
      ) {
        const calculada = bateria / 100;
        return `${calculada}v`;
      }
      if (modelo === 'Minicor' || modelo === 'Corus') {
        return `${bateria} días`;
      }
    }
    return 'Sin Reportar';
  }

  // Unidad Presion

  private getFrecReporteNSP(config: IConfigDispositivoNSP4G) {
    const frec = config?.modoOperacion;
    switch (frec) {
      case 'REG1_1HORA':
        return 1;
      case 'REG2_2HORAS':
        return 2;
      case 'REG3_3HORAS':
        return 3;
      case 'REG4_4HORAS':
        return 4;
      case 'REG6_6HORAS':
        return 6;
      case 'REG8_8HORAS':
        return 8;
      case 'REG12_12HORAS':
        return 12;
      case 'REG1_DIARIO':
        return 24;
      case 'REG24_DIARIO':
        return 24;
      default:
        return 24;
    }
  }

  public estadoUnidadPresion(unidadPresion?: IUnidadPresion) {
    if (!unidadPresion?.dispositivo) return 'Sin dispositivo';

    if (unidadPresion.estadoActual === 'En Mantenimiento') {
      return 'En Mantenimiento';
    }

    if (unidadPresion.estadoActual === 'Resolver') {
      return 'Resolver';
    }

    const config = unidadPresion.dispositivo?.config as IConfigDispositivoNSP4G;
    // const valores = unidadPresion.ultimoRegistro?.valores as IReporteNSP;
    const frecComunicacionHs = this.getFrecReporteNSP(config);

    const fechaLimite = this.fechaHaceXHoras(frecComunicacionHs);

    const ultimoReporte = unidadPresion.ultimoRegistro?.fechaCreacion
      ? new Date(unidadPresion.ultimoRegistro.fechaCreacion)
      : null;
    const ultimaAlerta = unidadPresion.ultimaAlerta?.fechaCreacion
      ? new Date(unidadPresion.ultimaAlerta.fechaCreacion)
      : null;

    // Hay ultima alerta y es mas reciente que la fecha limite
    if (ultimaAlerta && ultimaAlerta > fechaLimite) {
      // No hay ultimo reporte o la ultima alerta es mas reciente que el ultimo reporte
      if (!ultimoReporte || ultimaAlerta > ultimoReporte) {
        return 'Alerta';
      }
    }

    // No hay ultimo reporte o la fecha limite es mas reciente que el ultimo reporte
    if (!ultimoReporte || fechaLimite > ultimoReporte) {
      return 'Sin reportar';
    }

    return 'Ok';
  }

  public bateriaUnidadPresion(bateria?: number): string {
    if (bateria) {
      return (bateria / 10).toFixed(3) + 'V.';
    }

    return 'Sin Reportar';
  }

  // Medidores

  public estadoMedidor(medidor: IMedidorResidencial) {
    const ayer = this.fechaAyer();
    const fechaUltimoReporte = medidor.dispositivo?.fechaUltimaComunicacion;
    if (!fechaUltimoReporte || ayer > new Date(fechaUltimoReporte)) {
      return 'Sin reportar';
    }
    return 'Ok';
  }

  public estadoMedidorDeAgua(medidor: IMedidorResidencial) {
    const ayer = this.fechaAyer();
    const fechaUltimoReporte = medidor.dispositivo?.fechaUltimaComunicacion;
    if (!fechaUltimoReporte || ayer > new Date(fechaUltimoReporte)) {
      return 'Sin reportar';
    }
    return 'Ok';
  }

  // Mapa
  public static async getCurrentPosition(): Promise<ICoordenadas> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const ubicacionBase: ICoordenadas = {
        lat: -35.5836812,
        lng: -58.0128784,
      };
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const ubicacion: ICoordenadas = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            resolve(ubicacion);
          },
          () => {
            console.error('Ubicacion no aceptada');
            resolve(ubicacionBase);
          },
        );
      } else {
        resolve(ubicacionBase);
      }
    });
  }

  // Fechas
  private fechaAyer() {
    const fecha = new Date();
    fecha.setDate(fecha.getDate() - 1);
    return fecha;
  }

  private fechaHaceXHoras(horas: number) {
    const fecha = new Date();
    fecha.setHours(fecha.getHours() - horas);
    return fecha;
  }

  public fechaHaceXDias(dias = 1): Date {
    const fecha = new Date();
    fecha.setDate(fecha.getDate() - dias);
    return fecha;
  }

  public fechaHaceXAnios(anios = 1): Date {
    const fecha = new Date();
    fecha.setFullYear(fecha.getFullYear() - anios);
    return fecha;
  }

  private fechaMasReciente(f1?: Date, f2?: Date): Date | null {
    if (f1 && f2) {
      return f1 > f2 ? f1 : f2;
    }
    if (f1) {
      return f1;
    }
    if (f2) {
      return f2;
    }
    return null;
  }

  public fechaHaceUnAnio(): Date {
    const fecha = new Date();
    fecha.setFullYear(fecha.getFullYear() - 1);
    return fecha;
  }
  // Estados

  public estadoIconoTabla(estado?: string): IRowIcon {
    switch (estado?.toLowerCase()) {
      case 'alerta':
        return {
          icon: 'assets/estados/alerta.png',
          tooltip: 'Alerta',
          tipo: 'img',
        };

      case 'en mantenimiento':
        return {
          icon: 'assets/estados/mantenimiento.png',
          tooltip: 'En Mantenimiento',
          tipo: 'img',
        };

      case 'operativa':
        return {
          icon: 'assets/estados/ok.png',
          tooltip: 'Operativa',
          tipo: 'img',
        };

      case 'sin reportar':
        return {
          icon: 'assets/estados/sin-reportar.png',
          tooltip: 'Sin reportar',
          tipo: 'img',
        };

      case 'resolver':
        return {
          icon: 'assets/estados/resolver.png',
          tooltip: 'Resolver',
          tipo: 'img',
        };

      case 'sin dispositivo':
      case 'sin asignar':
        return {
          icon: 'assets/estados/alerta.png',
          tooltip: 'Sin dispositivo',
          tipo: 'img',
        };

      default:
        return {
          icon: 'info',
          color: 'yellow',
          tooltip: 'Sin Información',
          tipo: 'material',
        };
    }
  }

  public iconEstadoCorrectora(c: ICorrectora) {
    const estado = c.estadoActual;
    return this.estadoIconoTabla(estado);
  }

  public iconEstadoMedidor(c: IMedidorResidencial) {
    const estado = c.estadoActual;
    return this.estadoIconoTabla(estado);
  }

  public iconEstadoUnidadPresion(p: IUnidadPresion) {
    const estado = p.estadoActual;
    return this.estadoIconoTabla(estado);
  }

  public iconEstadoPuntoMedicion(p: IPuntoMedicion) {
    const estado = p.estado;
    return this.estadoIconoTabla(estado);
  }

  static parseFecha(fecha?: string): string {
    if (fecha) {
      return `<div>${new Date(fecha).toLocaleDateString()} - ${new Date(
        fecha,
      ).toLocaleTimeString()}</div>`;
    } else {
      return `<div class="no-info">Sin reportar</div>`;
    }
  }

  // Fechas

  static inicioMesAnteriorALaHora(hora: number) {
    const d = new Date();
    d.setUTCMonth(d.getUTCMonth() - 1);
    d.setUTCDate(1);
    d.setUTCHours(hora + 3, 0, 0, 0);
    return d.toISOString();
  }

  static inicioMesActualALaHora(hora: number) {
    const d = new Date();
    d.setUTCDate(1);
    d.setUTCHours(hora + 3, 0, 0, 0);
    return d.toISOString();
  }

  static fechaHaceDiasALaHora(dias: number, hora: number) {
    const d = new Date();
    d.setUTCDate(d.getUTCDate() - dias);
    d.setUTCHours(hora + 3, 0, 0, 0);
    return d.toISOString();
  }

  static mesAnteriorALasSeis(fecha: Date) {
    const fechaAux = new Date(fecha);
    fechaAux.setHours(6, 0, 0, 0);
    fechaAux.setMonth(fecha.getMonth() - 1);
    return fechaAux;
  }

  static mesAnteriorALasSiete(fecha: Date) {
    const fechaAux = new Date(fecha);
    fechaAux.setHours(7, 0, 0, 0);
    fechaAux.setMonth(fecha.getMonth() - 1);
    return fechaAux;
  }

  static dateAtSixHours(fecha: Date) {
    const fechaAux = new Date(fecha);
    fechaAux.setHours(6, 0, 0, 0);
    return fechaAux;
  }

  static dateAtSevenHours(fecha: Date) {
    const fechaAux = new Date(fecha);
    fechaAux.setHours(7, 0, 0, 0);
    return fechaAux;
  }

  static fechaGas(dias: number): string {
    const fecha = new Date();
    fecha.setHours(6, 0, 0, 0);
    fecha.setDate(fecha.getDate() - dias);
    return fecha.toISOString();
  }

  static mesAnterior(fecha: Date) {
    const fechaAux = new Date(fecha);
    fechaAux.setMonth(fecha.getMonth() - 1);
    return fechaAux;
  }

  static fechaAnterior(dias: number) {
    const fecha = new Date();
    fecha.setDate(fecha.getDate() - dias);
    return fecha;
  }

  static tomorrow(fecha: Date) {
    const fechaAux = new Date(fecha);
    fechaAux.setDate(fecha.getDate() + 1);
    return fechaAux;
  }

  static roundTo(n: number, digits: number) {
    let negative = false;
    if (digits === undefined) {
      digits = 0;
    }
    if (n < 0) {
      negative = true;
      n = n * -1;
    }
    const multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = +(Math.round(n) / multiplicator).toFixed(digits);
    if (negative) {
      n = +(n * -1).toFixed(digits);
    }
    return n;
  }

  // Presets de Rango de fechas

  static presetCorrectoras = [
    {
      label: 'Mes Anterior',
      from: HelperService.inicioMesAnteriorALaHora(7),
      to: HelperService.inicioMesActualALaHora(6),
    },
    {
      label: 'Últimas 24 horas',
      from: HelperService.fechaHaceDiasALaHora(1, 7),
      to: HelperService.fechaHaceDiasALaHora(0, 6),
    },
    {
      label: 'Últimas 48 horas',
      from: HelperService.fechaHaceDiasALaHora(2, 7),
      to: HelperService.fechaHaceDiasALaHora(0, 6),
    },
    {
      label: 'Últimos 7 días',
      from: HelperService.fechaHaceDiasALaHora(7, 7),
      to: HelperService.fechaHaceDiasALaHora(0, 6),
    },
    {
      label: 'Últimos 30 días',
      from: HelperService.fechaHaceDiasALaHora(30, 7),
      to: HelperService.fechaHaceDiasALaHora(0, 6),
    },
  ];

  static presetUnidadesPresion = [
    {
      label: 'Mes Anterior',
      from: HelperService.inicioMesAnteriorALaHora(7),
      to: HelperService.inicioMesActualALaHora(6),
    },
    {
      label: 'Últimas 24 horas',
      from: HelperService.fechaHaceDiasALaHora(1, 7),
      to: HelperService.fechaHaceDiasALaHora(-1, 6),
    },
    {
      label: 'Últimas 48 horas',
      from: HelperService.fechaHaceDiasALaHora(2, 7),
      to: HelperService.fechaHaceDiasALaHora(-1, 6),
    },
    {
      label: 'Últimos 7 días',
      from: HelperService.fechaHaceDiasALaHora(7, 7),
      to: HelperService.fechaHaceDiasALaHora(-1, 6),
    },
    {
      label: 'Últimos 30 días',
      from: HelperService.fechaHaceDiasALaHora(30, 7),
      to: HelperService.fechaHaceDiasALaHora(-1, 6),
    },
  ];

  static presetResidencial = [
    {
      label: 'Mes Anterior',
      from: HelperService.inicioMesAnteriorALaHora(7),
      to: HelperService.inicioMesActualALaHora(6),
    },
    {
      label: 'Últimas 24 horas',
      from: HelperService.fechaHaceDiasALaHora(1, 7),
      to: HelperService.fechaHaceDiasALaHora(-1, 6),
    },
    {
      label: 'Últimas 48 horas',
      from: HelperService.fechaHaceDiasALaHora(2, 7),
      to: HelperService.fechaHaceDiasALaHora(-1, 6),
    },
    {
      label: 'Últimos 7 días',
      from: HelperService.fechaHaceDiasALaHora(7, 7),
      to: HelperService.fechaHaceDiasALaHora(-1, 6),
    },
    {
      label: 'Últimos 30 días',
      from: HelperService.fechaHaceDiasALaHora(30, 7),
      to: HelperService.fechaHaceDiasALaHora(-1, 6),
    },
  ];

  public parsearDevEui(d?: string) {
    // Quitar ceros del principio
    if (d) {
      return d.replace(/^0+/, '');
    } else {
      return '';
    }
  }

  static telValido(telefono?: string) {
    const regTel = /^(?!0|15)\d{10}$/;
    return regTel.test(telefono || '');
  }

  // Medidores Redidenciales
  public calcularConsumoWRCValor(valores: IReporteWRC, inicial = 0): number {
    if (!valores) return 0;
    const pulseConstant = valores.meter_info?.pulse_constant || 0;
    const meter_reading = valores.meter_info?.meter_reading || 0;
    if (!pulseConstant) return 0;
    switch (pulseConstant) {
      case 0 || 1:
        return +(meter_reading / 10000 + inicial).toFixed(2);
      case 2:
        return +((meter_reading * 10) / 10000 + inicial).toFixed(2);
      case 3:
        return +((meter_reading * 100) / 10000 + inicial).toFixed(2);
      case 4:
        return +((meter_reading * 1000) / 10000 + inicial).toFixed(2);
      case 5:
        return +((meter_reading * 0.5) / 10000 + inicial).toFixed(2);
      case 6:
        return +((meter_reading * 5) / 10000 + inicial).toFixed(2);
      case 7:
        return +((meter_reading * 10000) / 10000 + inicial).toFixed(2);
      default:
        return 0;
    }
  }

  public generateColorsFromString(input: string): {
    backgroundColor: string;
    textColor: string;
  } {
    // Hash the input string to generate a number
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      hash = input.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert hash to a color in HEX format
    const backgroundColor = `#${((hash >> 24) & 0xff)
      .toString(16)
      .padStart(2, '0')}${((hash >> 16) & 0xff)
      .toString(16)
      .padStart(2, '0')}${((hash >> 8) & 0xff).toString(16).padStart(2, '0')}`;

    // Function to calculate brightness of a color
    const getBrightness = (hexColor: string): number => {
      const r = parseInt(hexColor.slice(1, 3), 16);
      const g = parseInt(hexColor.slice(3, 5), 16);
      const b = parseInt(hexColor.slice(5, 7), 16);
      return (r * 299 + g * 587 + b * 114) / 1000;
    };

    // Decide the text color based on brightness
    const brightness = getBrightness(backgroundColor);
    const textColor = brightness > 125 ? '#000000' : '#FFFFFF';

    return { backgroundColor, textColor };
  }
}
