import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { PuntosMedicionScadaRoutingModule } from './routing';
import { GeocodeService } from 'src/app/auxiliares/geocode.service';
import { IconoEstadoComponent } from '../../standalone/icono-estado/icono-estado.component';
import { ResumenPuntoMedicionComponent } from '../../standalone/resumen-punto-medicion/resumen-punto-medicion.component';
import { ListadoAlertasComponent } from '../../standalone/listado-alertas/listado-alertas.component';
import { GraficoAlertasComponent } from '../../standalone/grafico-alertas/grafico-alertas.component';
import { DetallesScadaComponent } from 'src/app/standalone/detalles-scada/detalles-scada.component';
import { UltimoReporteScadaComponent } from 'src/app/standalone/ultimo-reporte-scada/ultimo-reporte-scada.component';
import { GraficoValorActualScadaComponent } from 'src/app/standalone/grafico-valor-actual-scada/grafico-valor-actual-scada.component';
import { ListadoReportesScadaComponent } from 'src/app/standalone/listado-reportes-scada/listado-reportes-scada.component';
import { GraficoScadaComponent } from 'src/app/standalone/grafico-scada/grafico-scada.component';
import { ScadaSimpleComponent } from 'src/app/standalone/scada-simple/scada-simple.component';
import { PaginatorComponent } from 'src/app/auxiliares/tabla/paginator/paginator.component';
import { FiltroComponent } from 'src/app/auxiliares/tabla/filtro/filtro.component';
import { MultiScadaComponent } from '../../standalone/grafico-valor-actual-scada/multi-scada/multi-scada.component';
import { MultiScadaUltimoReporteComponent } from '../../standalone/ultimo-reporte-scada/multi-scada-ultimo-reporte/multi-scada-ultimo-reporte.component';
import { MultiScadaGraficoComponent } from 'src/app/standalone/grafico-scada/multi-scada-grafico/multi-scada-grafico.component';
import { PuntosScadaComponent } from './puntos-scada/puntos-scada.component';
import { EditarPosicionComponent } from './editar-posicion/editar-posicion.component';
import { PosicionComponent } from './posicion/posicion.component';
import { DetallesPuntosScadaComponent } from './detalles-puntos-scada/detalles-puntos-scada.component';
import { LandingGruposComponent } from './landing-grupos/landing-grupos.component';
import { ListadoNotificacionesPuntoComponent } from './listado-notificaciones-punto/listado-notificaciones-punto.component';

@NgModule({
  declarations: [
    PuntosScadaComponent,
    PosicionComponent,
    EditarPosicionComponent,
    DetallesPuntosScadaComponent,
    LandingGruposComponent,
    ListadoNotificacionesPuntoComponent,
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    PuntosMedicionScadaRoutingModule,
    IconoEstadoComponent,
    ResumenPuntoMedicionComponent,
    IconoEstadoComponent,
    ListadoAlertasComponent,
    GraficoAlertasComponent,
    DetallesScadaComponent,
    UltimoReporteScadaComponent,
    GraficoValorActualScadaComponent,
    ListadoReportesScadaComponent,
    GraficoScadaComponent,
    ScadaSimpleComponent,
    //
    PaginatorComponent,
    FiltroComponent,
    MultiScadaComponent,
    MultiScadaUltimoReporteComponent,
    MultiScadaGraficoComponent,
  ],
  providers: [GeocodeService],
})
export class PuntosMedicionScadaModule {}
