import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IScada } from 'modelos/src';
import { AuxiliaresModule } from 'src/app/auxiliares/auxiliares.module';
import { GraficoValorActualScadaComponent } from '../grafico-valor-actual-scada.component';
import { GraficoValorActualScadaBooleanComponent } from '../grafico-valor-actual-scada-boolean/grafico-valor-actual-scada-boolean.component';

@Component({
  selector: 'app-multi-scada',
  standalone: true,
  imports: [
    CommonModule,
    AuxiliaresModule,
    GraficoValorActualScadaComponent,
    GraficoValorActualScadaBooleanComponent,
  ],
  templateUrl: './multi-scada.component.html',
  styleUrl: './multi-scada.component.scss',
})
export class MultiScadaComponent {
  @Input() scadas?: IScada[];
}
