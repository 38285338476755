import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {
  IConfigDispositivo,
  IConfigDispositivoScada,
  IReporteScada,
  IScada,
} from 'modelos/src';
import { ConfigScadaComponent } from '../config-scada/config-scada.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { ScadasService } from 'src/app/modulos/scadas/service';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';
import { firstValueFrom } from 'rxjs';
import { ListadoNotificacionesPuntoComponent } from '../../modulos/puntos-medicion-scada/listado-notificaciones-punto/listado-notificaciones-punto.component';
import { LoginService } from '../../modulos/login/login.service';
import { ConfigDispositivosService } from 'src/app/modulos/dispositivos/config-dispositivos.service';

@Component({
  selector: 'app-scada-simple',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule],
  templateUrl: './scada-simple.component.html',
  styleUrl: './scada-simple.component.scss',
})
export class ScadaSimpleComponent implements OnInit, OnChanges {
  @Input() scada?: IScada;
  @Input() boleano: boolean = false;
  public reporte?: IReporteScada;
  public puedeConfigurarLimites = false;
  public puedeConfigurarNotificaciones = false;
  public esAdminGlobal = false;

  public configAplicada = false;

  @Input() configDispositivo?: IConfigDispositivo;

  constructor(
    public matDialog: MatDialog,
    private helper: HelperService,
    private service: ScadasService,
    private dialgoService: DialogService,
    private configDispositivoService: ConfigDispositivosService,
  ) {}

  public async config(data: IScada) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(ConfigScadaComponent, config);
  }

  public async notificaciones(scada: IScada) {
    const config: MatDialogConfig = {
      data: scada,
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(ListadoNotificacionesPuntoComponent, config);
  }

  public async habilitar(scada: IScada, habilitar: boolean) {
    if (
      !this.helper.puedeEditarScadaMediciones(scada) &&
      !this.helper.puedeEditarScadaUnifilares(scada)
    ) {
      this.helper.notifWarn(
        'No tienes permisos para habilitar o deshabilitar este tag',
      );
      return;
    }
    const res = await this.dialgoService.confirm(
      'Confirme la acción',
      `¿Desea cambiar el estado del tag ${scada.tag}?`,
    );
    if (res) {
      try {
        await firstValueFrom(this.service.habilitar(scada._id!, habilitar));
        this.helper.notifSuccess('Cambio de estado correcto');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  public async reaplicarLimites(scada: IScada) {
    if (
      !this.helper.puedeEditarScadaMediciones(scada) &&
      !this.helper.puedeEditarScadaUnifilares(scada)
    ) {
      this.helper.notifWarn('No tienes permisos para modificar este tag');
      return;
    }
    const res = await this.dialgoService.confirm(
      'Confirme la acción',
      `¿Desea reaplicar los límites del tag ${scada.tag}?`,
    );
    if (res) {
      try {
        await firstValueFrom(
          this.configDispositivoService.reaplicarConfig(scada.tag!),
        );
        this.helper.notifSuccess('Reaplicación de límites enviada');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  public async setConfigAplicada() {
    const configScada = this.scada?.config;
    const configDispositivo = this.configDispositivo
      ?.config as IConfigDispositivoScada;
    if (
      configScada?.limiteH === configDispositivo?.limiteH &&
      configScada?.limiteL === configDispositivo?.limiteL &&
      configScada?.limiteHH === configDispositivo?.limiteHH &&
      configScada?.limiteLL === configDispositivo?.limiteLL
    ) {
      this.configAplicada = true;
    } else {
      this.configAplicada = false;
    }
  }

  private setPermisos() {
    const user = LoginService.getUsuario();
    const permiso = user?.permisos?.find(
      (p) =>
        (p.nivel === 'Global' && p.rol === 'Administrador') ||
        ((p.division === 'SCADA Mediciones' ||
          p.division === 'SCADA Unifilares') &&
          (p.rol === 'Administrador' || p.rol === 'Usuario')),
    );
    this.puedeConfigurarLimites = !!permiso;
    this.puedeConfigurarNotificaciones = !!permiso;
    this.esAdminGlobal = !!user?.permisos?.find(
      (p) => p.nivel === 'Global' && p.rol === 'Administrador',
    );
  }

  async ngOnInit(): Promise<void> {
    this.setPermisos();
    this.reporte = this.scada?.ultimoRegistro?.valores as IReporteScada;
    await this.setConfigAplicada();
  }

  async ngOnChanges() {
    await this.setConfigAplicada();
    // console.log('configDispositivo', this.configDispositivo);
    // console.log('scada', this.scada);
  }
}
