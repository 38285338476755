<div class="column-center" style="justify-content: center; height: 100%">
  <!-- Ultimo registro presion -->
  <table style="width: 100%" *ngIf="ultimoRegistro">
    <!-- Fecha -->
    <tr>
      <th>Fecha</th>
      <td>
        <span style="white-space: nowrap">
          {{ (ultimoRegistro.timestamp | date: "short") || "..." }}
        </span>
      </td>
    </tr>
    <!-- Valor Actual -->
    @if (ultimoRegistro.valorActual) {
      @if (scada?.tipo === "Presión") {
        <tr>
          <th>Valor Actual</th>
          <td>
            {{ ultimoRegistro.valorActual | number }}
            @if (!scada?.booleano) {
              {{ scada?.unidad || "BAR" }}
            }
          </td>
        </tr>
      }

      @if (scada?.tipo === "Temperatura") {}
    }

    @if (!scada?.booleano) {
      <!-- CV -->
      <tr>
        <th>Valor Actual</th>
        <td>
          {{ ultimoRegistro.valorActual | number: "1.0-2" }}
          {{ scada?.unidad }}
        </td>
      </tr>
      <!-- HI -->
      @if (ultimoRegistro.limiteH) {
        <tr>
          <th>HI</th>
          <td>
            {{ ultimoRegistro.limiteH | number }}
          </td>
        </tr>
      }
      <!-- HIHI -->
      @if (ultimoRegistro.limiteHH) {
        <tr>
          <th>HIHI</th>
          <td>
            {{ ultimoRegistro.limiteHH | number }}
          </td>
        </tr>
      }
      <!-- LO -->
      @if (ultimoRegistro.limiteL) {
        <tr>
          <th>LO</th>
          <td>
            {{ ultimoRegistro.limiteL | number }}
          </td>
        </tr>
      }
      <!-- LOLO -->
      @if (ultimoRegistro.limiteLL) {
        <tr>
          <th>LOLO</th>
          <td>
            {{ ultimoRegistro.limiteLL | number }}
          </td>
        </tr>
      }
    } @else {
      @if (scada?.booleanoValorAlarma) {
        <tr>
          <th>Valor de Alarma</th>
          <td>
            {{ scada?.booleanoValorAlarma ? "True" : "False" }}
          </td>
        </tr>
      }
    }
  </table>

  <!-- NO hay ultimo registro -->
  <div
    *ngIf="!ultimoRegistro"
    style="
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <h3>
      <strong> No hay registros </strong>
    </h3>
  </div>

  <mat-divider
    class="divider"
    *ngIf="ultimaAlerta"
    style="margin: 1em 1em 1em 1em"
  ></mat-divider>

  <!-- Ultima Alerta -->
  <table *ngIf="ultimaAlerta" style="width: 100%">
    <!-- Fecha -->
    <tr>
      <th>Fecha</th>
      <td>
        {{ (ultimaAlerta.timestamp | date: "short") || "..." }}
      </td>
    </tr>
    <!-- Alerta -->
    <tr>
      <th>Alerta</th>
      <td>
        {{ ultimaAlerta.mensaje || "..." }}
      </td>
    </tr>
  </table>
</div>
