<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      *ngIf="form"
      id="form"
      [formGroup]="form"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- Estado Actual -->
      <mat-form-field>
        <mat-label>Estado Actual</mat-label>
        <mat-select formControlName="estadoActual">
          <mat-option value="Operativa"> Operativa </mat-option>
          <mat-option value="En Mantenimiento"> En Mantenimiento </mat-option>
          <!-- <mat-option value="Resolver"> Resolver </mat-option> -->
        </mat-select>
      </mat-form-field>

      <div fxLayout="row" fxLayoutGap="15px">
        <!-- Tipo -->
        <mat-form-field>
          <mat-label>Tipo</mat-label>
          <mat-select formControlName="tipo" (valueChange)="cambioTipo($event)">
            @for (t of tipos; track $index) {
              <mat-option [value]="t">
                {{ t | titlecase }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <!-- División -->
        <mat-form-field>
          <mat-label>División</mat-label>
          <mat-select formControlName="division">
            @for (d of divisiones; track d) {
              <mat-option [value]="d">{{ d }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="15px">
        <!-- Nombre -->
        <mat-form-field>
          <mat-label>Nombre</mat-label>
          <input matInput type="search" formControlName="nombre" />
        </mat-form-field>

        <!-- Tag OPC  -->
        <mat-form-field>
          <mat-label>Tag OPC</mat-label>
          <input matInput type="search" formControlName="tag" />
        </mat-form-field>
      </div>

      @if (this.form.get("booleano")?.value === false) {
        <div class="row-center" style="gap: 5px; align-items: center">
          <!-- Unidad -->
          <mat-form-field class="no-hint">
            <mat-label>Unidad</mat-label>
            <input matInput type="text" formControlName="unidad" readonly />
          </mat-form-field>

          <!-- Mínimo -->
          <mat-form-field class="no-hint" [formGroup]="config">
            <mat-label>Mínimo</mat-label>
            <input matInput type="number" formControlName="minimo" />
          </mat-form-field>

          <!-- Máximo -->
          <mat-form-field class="no-hint" [formGroup]="config">
            <mat-label>Máximo</mat-label>
            <input matInput type="number" formControlName="maximo" />
          </mat-form-field>
        </div>
      }

      <div
        class="column-center"
        style="gap: 5px; align-items: center; width: 100%"
      >
        <!-- Booleano  -->
        @if (this.form.get("booleano")?.value === true) {
          <mat-expansion-panel
            hideToggle
            expanded="true"
            style="gap: 5px; align-items: center; width: 100%"
          >
            <mat-expansion-panel-header>
              <mat-panel-title> Booleano</mat-panel-title>
              <mat-panel-description>
                Configuraciones para el SCADA booleano
              </mat-panel-description>
            </mat-expansion-panel-header>

            <!-- Valor Alarma -->
            <mat-form-field>
              <mat-label>Valor Alarma</mat-label>
              <mat-select formControlName="booleanoValorAlarma">
                <mat-option [value]="true">Verdadero (1)</mat-option>
                <mat-option [value]="false">Falso (0)</mat-option>
              </mat-select>
              <mat-hint>Valor que se considera como alarma</mat-hint>
            </mat-form-field>
            <br />
            <div
              class="row-center"
              style="gap: 5px; align-items: center; width: 100%"
            >
              <!-- Texto para Verdadero -->
              <mat-form-field class="no-hint" [formGroup]="config">
                <mat-label>Texto para Verdadero</mat-label>
                <input matInput type="text" formControlName="textoTrue" />
              </mat-form-field>
              <!-- Texto para Falso -->
              <mat-form-field class="no-hint" [formGroup]="config">
                <mat-label>Texto para Falso</mat-label>
                <input matInput type="text" formControlName="textoFalse" />
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        }
      </div>

      <!-- Unidad de negocio y centro operativo -->
      <div fxLayout="row" fxLayoutGap="15px" style="margin-top: 1em">
        <!-- Unidad de negocio -->
        <mat-form-field>
          <mat-label>Unidad de Negocio</mat-label>
          <mat-select
            formControlName="idUnidadNegocio"
            [disabled]="form.value?.deveui"
            (selectionChange)="cambioUnidadNegocio()"
          >
            <mat-option *ngFor="let dato of unidadNegocios" [value]="dato._id">
              {{ dato.nombre }}
            </mat-option>
          </mat-select>
          <button
            [disabled]="form.value?.deveui"
            *ngIf="form.value?.idUnidadNegocio"
            matSuffix
            mat-icon-button
            type="button"
            aria-label="Clear"
            (click)="
              $event.stopPropagation();
              form.patchValue({ idUnidadNegocio: null });
              form.patchValue({ idCentroOperativo: null })
            "
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!-- Centro operativo -->
        <mat-form-field>
          <mat-label>Centro Operativo</mat-label>
          <mat-select formControlName="idCentroOperativo">
            <ng-container *ngFor="let dato of centrosUnidadNegocio">
              <mat-option [value]="dato._id">
                {{ dato.nombre }}
              </mat-option>
            </ng-container>
          </mat-select>
          <button
            *ngIf="form.value?.idCentroOperativo"
            matSuffix
            mat-icon-button
            type="button"
            aria-label="Clear"
            (click)="
              $event.stopPropagation();
              form.patchValue({ idCentroOperativo: null })
            "
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!-- Localidad -->
        <mat-form-field>
          <mat-label>Localidad</mat-label>
          <mat-select formControlName="idLocalidad">
            <ng-container *ngFor="let dato of localidades">
              <mat-option [value]="dato._id">
                {{ dato.nombre }}
              </mat-option>
            </ng-container>
          </mat-select>
          <button
            *ngIf="form.value?.idLocalidad"
            matSuffix
            mat-icon-button
            type="button"
            aria-label="Clear"
            (click)="
              $event.stopPropagation(); form.patchValue({ idLocalidad: null })
            "
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="form"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button mat-raised-button type="button" fxFlex (click)="close()">
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="this.loading.getLoading() ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
