import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICentroOperativo,
  IListado,
  IScada,
  IQueryParam,
  IUnidadNegocio,
  ILocalidad,
  IUpdateScada,
  ICreateScada,
  DivisionScada,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';
import { ScadasService } from '../service';

@Component({
  selector: 'app-crear-editar-scada',
  templateUrl: './crear-editar-scada.component.html',
  styleUrls: ['./crear-editar-scada.component.scss'],
})
export class CrearEditarScadaComponent implements OnInit, OnDestroy {
  public form?: FormGroup;
  public title?: string;
  public enviando = false;
  public hide = true;

  public divisiones: DivisionScada[] = ['Unifilar', 'Medición'];
  //   * Presión en bar
  //   * Presión en mbar
  //   * Temperatura en C
  //   * Porcentaje
  //   * Booleano ( la alerta puede ser por 0 o por 1)
  //   * mg sobre m3
  public tipos: string[] = [
    'Presión en bar',
    'Presión en mbar',
    'Temperatura en C',
    'Porcentaje',
    'Booleano',
    'mg sobre m3',
  ];

  public unidadNegocios: IUnidadNegocio[] = [];
  public centroOperativos: ICentroOperativo[] = [];
  public centrosUnidadNegocio: ICentroOperativo[] = [];
  public localidades: ILocalidad[] = [];

  public mapOptions: google.maps.MapOptions = JSON.parse(
    JSON.stringify(HelperService.mapOptionsEditor),
  );
  public mapCenter?: google.maps.LatLngLiteral;
  public markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };

  public get config() {
    return this.form?.get('config') as FormGroup;
  }

  // Listado Continuo
  public unidadNegocios$?: Subscription;
  public centroOperativos$?: Subscription;
  public localidades$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IScada,
    private dialogRef: MatDialogRef<CrearEditarScadaComponent>,
    private service: ScadasService,
    private listadosService: ListadosService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id ? 'Editar SCADA' : 'Crear SCADA';

    this.form = new FormGroup({
      nombre: new FormControl(this.data?.nombre, [Validators.required]), /// Input
      tag: new FormControl(this.data?.tag, [Validators.required]), /// Esto configura el tag para el OPC
      estadoActual: new FormControl(this.data?.estadoActual || 'Operativa'),
      tipo: new FormControl(this.data?.tipo), // Select
      division: new FormControl(this.data?.division, [Validators.required]),
      //
      // unidad?: string; // Input, para saber que reporta (además del tipo)
      // // Booleano
      // booleano?: boolean; // Input, para saber si es un valor booleano (Reporta 1 o 0 y andá a saber cuál es cuál)
      // booleanoValorAlarma?: boolean; // Input, para saber si el valor de alarma es 1 o 0
      unidad: new FormControl(this.data?.unidad),
      booleano: new FormControl(this.data?.booleano || false),
      booleanoValorAlarma: new FormControl(
        this.data?.booleanoValorAlarma || false,
      ),
      config: new FormGroup({
        minimo: new FormControl(this.data?.config?.minimo),
        maximo: new FormControl(this.data?.config?.maximo),
        textoTrue: new FormControl(this.data?.config?.textoTrue),
        textoFalse: new FormControl(this.data?.config?.textoFalse),
      }),
      // Selects
      idUnidadNegocio: new FormControl(this.data?.idUnidadNegocio),
      idCentroOperativo: new FormControl(this.data?.idCentroOperativo),
      idLocalidad: new FormControl(this.data?.idLocalidad),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  private setDatos() {
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;
    const centrosUnidadNegocio = this.centroOperativos.filter(
      (c) => c.idUnidadNegocio === idUnidadNegocio,
    );

    this.centrosUnidadNegocio = centrosUnidadNegocio;
  }

  public cambioUnidadNegocio() {
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;

    this.setDatos();

    if (this.centrosUnidadNegocio.length === 1) {
      this.form?.patchValue({
        idCentroOperativo: this.centrosUnidadNegocio[0]._id,
      });
    } else {
      const idCentroSeleccionado = this.form?.value.idCentroOperativo;
      const centroSeleccionado = this.centrosUnidadNegocio.find(
        (c) => c._id === idCentroSeleccionado,
      );
      if (centroSeleccionado?.idUnidadNegocio !== idUnidadNegocio) {
        this.form?.patchValue({
          idCentroOperativo: null,
        });
      }
    }
  }

  //

  private getUpdateData() {
    const data: IUpdateScada = this.form?.value;
    if (data.booleano) {
      data.config!.maximo = undefined;
      data.config!.minimo = undefined;
      data.unidad = undefined;
    } else {
      data.booleanoValorAlarma = undefined;
      data.config!.textoTrue = undefined;
      data.config!.textoFalse = undefined;
    }
    return data;
  }

  private getCreateData() {
    const data: ICreateScada = this.form?.value;
    if (data.booleano) {
      data.config!.maximo = undefined;
      data.config!.minimo = undefined;
      data.unidad = undefined;
    } else {
      data.booleanoValorAlarma = undefined;
      data.config!.textoTrue = undefined;
      data.config!.textoFalse = undefined;
    }
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  public cambioTipo(tipo: string) {
    console.log(`tipo`, tipo);
    if (tipo === 'Booleano') {
      this.form?.get('booleano')?.setValue(true);
      return;
    }
    this.form?.get('booleano')?.setValue(false);
    this.form?.get('unidad')?.setValue(this.tipoAUnidad(tipo));
  }

  private tipoAUnidad(tipo: string): string {
    switch (tipo) {
      case 'Presión en bar':
        return 'bar';
      case 'Presión en mbar':
        return 'mbar';
      case 'Temperatura en C':
        return '°C';
      case 'Porcentaje':
        return '%';
      case 'mg sobre m3':
        return 'mg/m3';
      default:
        return '';
    }
  }

  // Listados

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;

        if (this.unidadNegocios.length === 1) {
          this.form?.patchValue({
            idUnidadNegocio: this.unidadNegocios[0]._id,
          });
          this.setDatos();
        }

        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarCentroOperativos(): Promise<void> {
    // Filtro
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
    };

    // Listado
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
      .subscribe((data) => {
        this.centroOperativos = data.datos;
        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  private async listarLocalidades(): Promise<void> {
    // Filtro
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio idCentroOperativo',
      sort: 'nombre',
    };

    // Listado
    this.localidades$?.unsubscribe();
    this.localidades$ = this.listadosService
      .subscribe<IListado<ILocalidad>>('localidads', query)
      .subscribe((data) => {
        this.localidades = data.datos;
        console.log(`listado de localidads`, data);
      });
    await this.listadosService.getLastValue('localidads', query);
  }

  //

  async ngOnInit(): Promise<void> {
    this.createForm();
    await Promise.all([
      this.listarUnidadNegocios(),
      this.listarCentroOperativos(),
      this.listarLocalidades(),
    ]);
    this.setDatos();
  }

  ngOnDestroy(): void {
    this.unidadNegocios$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
    this.localidades$?.unsubscribe();
  }
}
