import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IReporteScada, IScada } from 'modelos/src';
import { AuxiliaresModule } from 'src/app/auxiliares/auxiliares.module';

@Component({
  selector: 'app-grafico-valor-actual-scada-boolean',
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  templateUrl: './grafico-valor-actual-scada-boolean.component.html',
  styleUrl: './grafico-valor-actual-scada-boolean.component.scss',
})
export class GraficoValorActualScadaBooleanComponent implements OnInit {
  @Input() scada?: IScada;

  public valores?: IReporteScada;

  constructor() {}

  ngOnInit() {
    this.valores = this.scada?.ultimoRegistro?.valores as IReporteScada;
  }
}
