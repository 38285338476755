import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import {
  IConfigNotificacion,
  IFilter,
  IListado,
  IQueryParam,
  IScada,
} from 'modelos/src';
import { firstValueFrom, Subscription } from 'rxjs';
import { ListadosService } from '../../../auxiliares/listados.service';
import { LoadingService } from '../../../auxiliares/loading.service';
import { HelperService } from '../../../auxiliares/helper.service';
import {
  CrearEditarNotificacionScadaComponent,
  DataCreateEditNotificacionScada,
} from '../../notificaciones-scada/crear-editar-notificaciones-scada/crear-editar-notificaciones-scada.component';
import { IColumnas } from '../../../auxiliares/tabla/tabla.component';
import { ConfigNotificacion } from '../../notificaciones-scada/notificaciones-scada/notificaciones-scada.component';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { ConfigNotificacionsService } from '../../notificaciones-scada/service';
import { LoginService } from '../../login/login.service';

@Component({
  selector: 'app-listado-notificaciones-punto',
  templateUrl: './listado-notificaciones-punto.component.html',
  styleUrls: ['./listado-notificaciones-punto.component.scss'],
})
export class ListadoNotificacionesPuntoComponent implements OnInit, OnDestroy {
  public name = ListadoNotificacionesPuntoComponent.name;
  public columnas?: IColumnas<ConfigNotificacion>[];
  // Listado Continuo
  public configNotificaciones: IConfigNotificacion[] = [];
  public configNotificaciones$?: Subscription;

  public puedeConfigurarNotificaciones = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IScada,
    public dialogRef: MatDialogRef<ListadoNotificacionesPuntoComponent>,
    private listadosService: ListadosService,
    public loading: LoadingService,
    public helper: HelperService,
    private matDialog: MatDialog,
    private dialogService: DialogService,
    private service: ConfigNotificacionsService,
  ) {}

  public async crear() {
    if (!this.puedeConfigurarNotificaciones) {
      this.helper.notifError(
        'No tienes permisos para editar este punto de medición',
      );
      return;
    }
    const scada = this.data;

    const data: DataCreateEditNotificacionScada = {
      scada: scada,
      nombre: `${scada.nombre} - ${scada.tag}`,
    };
    const config: MatDialogConfig = {
      data,
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarNotificacionScadaComponent, config);
  }

  private setPermisos() {
    const user = LoginService.getUsuario();
    const permiso = user?.permisos?.find(
      (p) =>
        (p.nivel === 'Global' && p.rol === 'Administrador') ||
        ((p.division === 'SCADA Mediciones' ||
          p.division === 'SCADA Unifilares') &&
          (p.rol === 'Administrador' || p.rol === 'Usuario')),
    );
    this.puedeConfigurarNotificaciones = !!permiso;
  }

  public async editar(c: ConfigNotificacion) {
    const data: DataCreateEditNotificacionScada = {
      config: c,
      scada: this.data,
      nombre: c.scada?.nombre,
    };
    const config: MatDialogConfig = {
      data,
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarNotificacionScadaComponent, config);
  }

  public async eliminar(dato: ConfigNotificacion): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar la notificación?`,
    );
    if (confirm) {
      try {
        await firstValueFrom(this.service.eliminar(dato._id!));
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  private async listarNotificaciones() {
    console.log(`listarNotificaciones para scada`, this.data);
    const tag = this.data.tag;
    const filter: IFilter<IConfigNotificacion> = {
      tag,
    };
    const populate = [
      {
        path: 'cliente',
        select: 'nombre',
      },
      {
        path: 'scada',
        select: 'nombre',
      },
    ];
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      sort: JSON.stringify({ nombre: 1 }),
    };
    //
    this.configNotificaciones$?.unsubscribe();
    this.configNotificaciones$ = this.listadosService
      .subscribe<IListado<IConfigNotificacion>>('confignotificacions', query)
      .subscribe((data) => {
        this.configNotificaciones = data.datos;
        console.log(`listado de confignotificacions`, data);
      });
    await this.listadosService.getLastValue('confignotificacions', query);
  }

  private setColumnas() {
    this.columnas = [
      // Tipos de Alerta
      {
        header: {
          label: 'Tipo de Alerta',
          sortable: true,
        },
        row: {
          field: 'tipoAlerta',
        },
      },
      // Punto
      // {
      //   header: {
      //     label: 'Punto de Medición',
      //   },
      //   row: {
      //     parse(dato) {
      //       return dato.punto;
      //     },
      //     noData: 'Sin Punto',
      //   },
      // },
      // Variable (Nombre del scada)
      {
        header: {
          label: 'Variable',
        },
        row: {
          parse(dato) {
            return dato.scada?.nombre;
          },
        },
      },
      // Medio
      {
        header: {
          label: 'Medio',
        },
        row: {
          field: 'tipoEnvio',
        },
      },
      // Acciones
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/editar.png',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
            },
            {
              tipo: 'img',
              color: 'warn',
              icon: 'assets/iconos/eliminar.png',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
            },
          ],
        },
      },
    ];
  }

  async ngOnInit(): Promise<void> {
    this.loading.setLoading(true);
    this.setPermisos();
    this.setColumnas();
    await this.listarNotificaciones();
    this.loading.setLoading(false);
  }

  ngOnDestroy(): void {
    this.configNotificaciones$?.unsubscribe();
  }
}
