<mat-tab-group>
  @for (s of scadas; track $index) {
    @if (!s.booleano) {
      <mat-tab [label]="s.tag!">
        <app-grafico-scada
          [puntoMedicion]="punto"
          [scada]="s"
          [range]="range"
        ></app-grafico-scada>
      </mat-tab>
    } @else {
      <mat-tab [label]="s.tag!">
        <div class="row-center" style="padding: 0.5em 0 0.5em 0">
          <span class="no-info">Booleano</span>
        </div>
      </mat-tab>
    }
  }
</mat-tab-group>
