<div style="height: 100%; display: flex; flex-direction: column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <section class="titulo layout">
      <h1
        class="grow1"
        style="
          font-size: 18px;
          font-weight: 900;
          padding: 0.5em 0 0.5em 0.5em;
          margin: 0;
        "
      >
        Correctora
      </h1>
      @if (mostrarSyncHora) {
        <img
          src="assets/iconos/config.png"
          alt=""
          *ngIf="helper.puedeEditarCorrectora(correctora)"
          matTooltip="Sincronizar Hora"
          style="width: 30px; height: 30px; cursor: pointer"
          (click)="syncHora()"
        />
      }
      <img
        src="assets/iconos/editar.png"
        alt=""
        *ngIf="helper.puedeEditarCorrectora(correctora)"
        matTooltip="Editar"
        style="width: 30px; height: 30px; cursor: pointer"
        (click)="editarCorrectora(correctora)"
      />
      <img
        src="assets/estados/mantenimiento.png"
        alt=""
        *ngIf="helper.puedeEditarCorrectora(correctora)"
        matTooltip="Crear Mantenimiento"
        style="width: 30px; height: 30px; margin-right: 0.5em; cursor: pointer"
        (click)="crearMantenimiento(correctora)"
      />
    </section>
  </div>
  <div
    class="column-center"
    style="justify-content: center; height: 100%; padding: 0.5em 0 0.5em 0"
  >
    <table style="width: 100%">
      <!-- Modelo -->
      <tr>
        <th>Modelo</th>
        <td style="text-align: end">
          {{ correctora?.modelo || "..." }}
        </td>
      </tr>
      <!-- Número de Serie -->
      <tr>
        <th>Número de Serie</th>
        <td style="text-align: end">
          {{ correctora?.numeroSerie || "..." }}
        </td>
      </tr>
      <!-- Firmware -->
      <tr>
        <th>Firmware</th>
        <td style="text-align: end" [class.no-info]="!correctora?.firmware">
          {{ correctora?.firmware || "Sin informar" }}
        </td>
      </tr>
      <!-- Batería -->
      <tr>
        <th>Batería</th>
        <td style="text-align: end">
          {{ helper.bateriaCorrectora(correctora) }}
        </td>
      </tr>
      <!-- Fecha de Asignación -->
      <tr>
        <th>Fecha de Asignación</th>
        <td style="text-align: end">
          {{
            (puntoMedicion?.fechaAsignacionCorrectora | date: "short") || "..."
          }}
        </td>
      </tr>
      <tr>
        <th>Desface Horario</th>
        <td style="text-align: end">
          {{
            dispositivo?.config?.desfaceHorario ||
            dispositivo?.config?.desfaceHorario === 0
              ? dispositivo?.config?.desfaceHorario + " min."
              : "No calculado"
          }}
        </td>
      </tr>
      <!-- Separador -->
      <tr *ngIf="mantenimiento">
        <td colspan="100%">
          <mat-divider class="divider" style="margin: 1em 0"></mat-divider>
        </td>
      </tr>

      <tr *ngIf="mantenimiento">
        <th>Tipo</th>
        <td style="text-align: end">
          {{ mantenimiento.tipo || "..." }}
        </td>
      </tr>

      <tr *ngIf="mantenimiento">
        <th>Último Mantenimiento</th>
        <td style="text-align: end">
          {{ (mantenimiento.fechaCreacion | date: "short") || "..." }}
        </td>
      </tr>

      <tr *ngIf="mantenimiento">
        <th>Descripción</th>
        <td style="text-align: end">
          {{ mantenimiento.descripcion || "..." }}
        </td>
      </tr>
    </table>
  </div>
</div>
