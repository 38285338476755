<div fxLayout="column" fxLayoutGap="1em">
  <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <!-- Resumen Punto -->
    <div fxFlex.gt-sm="66">
      <app-resumen-punto-medicion
        *ngIf="puntoMedicion"
        [puntoMedicion]="puntoMedicion"
      ></app-resumen-punto-medicion>
    </div>

    <!-- Detalles Scada -->
    @if (scadas) {
      <div
        class="fondo-85"
        style="border-bottom-left-radius: 30px"
        fxFlex.gt-sm="34"
      >
        @if (scadas) {
          <app-detalles-scada
            [puntoMedicion]="puntoMedicion"
            [scadas]="scadas"
          ></app-detalles-scada>
        }
      </div>
    } @else {
      <div
        class="fondo-85"
        style="border-bottom-left-radius: 30px"
        fxFlex.gt-sm="34"
      >
        <div
          style="
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <h3>
            <strong> Sin Dispositivo </strong>
          </h3>
        </div>
      </div>
    }
  </div>

  <!-- Ultimo Registro - NUC/NSP - Croma/Grafico Presion/Consumo -->
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <!-- Último Registro  -->
    @if (scadas) {
      <div
        class="fondo-85"
        style="border-bottom-left-radius: 30px"
        fxFlex.gt-sm="33"
      >
        @if (scadas) {
          <app-multi-scada-ultimo-reporte
            [scadas]="scadas"
          ></app-multi-scada-ultimo-reporte>
        }
      </div>
    }

    <!-- NUC / NSP / VERIBOX (CONFIGS) -->
    @if (scadas) {
      <div
        class="fondo-85"
        style="border-bottom-left-radius: 30px"
        fxFlex.gt-sm="34"
      >
        <!-- SCADA / Configuracion -->
        @if (
          puntoMedicion?.division === "SCADA Unifilares" ||
          puntoMedicion?.division === "SCADA Mediciones"
        ) {
          <app-detalles-scada
            [puntoMedicion]="puntoMedicion"
            [scadas]="scadas"
          ></app-detalles-scada>
        }
      </div>
    }

    <!-- Ultima Croma - Grafico Presion Actual - Consumo ultimas 24hs -->
    @if (scadas) {
      <!-- SCADA - Grafico presion actual -->
      @if (
        puntoMedicion?.division === "SCADA Unifilares" ||
        puntoMedicion?.division === "SCADA Mediciones"
      ) {
        <div
          class="fondo-85"
          style="border-bottom-left-radius: 30px"
          fxFlex.gt-sm="33"
        >
          <app-multi-scada [scadas]="scadas"></app-multi-scada>
        </div>
      }
    }
  </div>

  <!-- Time Picker y Grafico Max y Min / 1 Año -->
  <div
    style="
      height: 100%;
      display: flex;
      flex-direction: column;
      border-bottom-left-radius: 30px;
      margin: 1em 0;
    "
    class="fondo-85"
  >
    <!-- Titulo -->
    <div class="titulo" fxLayout="row" fxLayoutAlign="space-between center">
      <h1
        style="
          font-size: 18px;
          font-weight: 900;
          padding: 0.5em 0 0.5em 0.5em;
          margin: 0;
        "
      >
        Registros Históricos
      </h1>
    </div>

    <!-- Time Picker -->
    <div style="margin: 1em 0 1em 0" *ngIf="range && presets">
      <app-datetime-range-picker
        [(range)]="range"
        [presets]="presets"
      ></app-datetime-range-picker>
    </div>

    <!-- Grafico Max y Min / 1 Año-->
    <div class="fondo-85" style="border-bottom-left-radius: 30px">
      @if (
        puntoMedicion?.division === "SCADA Unifilares" ||
        puntoMedicion?.division === "SCADA Mediciones"
      ) {
        <app-multi-scada-grafico
          [punto]="puntoMedicion"
          [scadas]="scadas"
          [range]="range"
        ></app-multi-scada-grafico>
      }
    </div>
  </div>
</div>

<!-- Tablas -->
<div style="margin: 1em 0 1em 0">
  <mat-tab-group>
    <!-- SCADA -->
    @if (
      puntoMedicion?.division === "SCADA Unifilares" ||
      puntoMedicion?.division === "SCADA Mediciones"
    ) {
      <!-- Reportes -->
      @for (s of scadas; track $index) {
        <mat-tab label="Registros {{ s.nombre }}">
          <ng-template matTabContent>
            <div style="overflow: hidden">
              <app-listado-reportes-scada
                [scada]="s"
                [booleano]="s.booleano"
                [range]="range"
              ></app-listado-reportes-scada>
            </div>
          </ng-template>
        </mat-tab>
      }
      <!-- Alertas -->
      <mat-tab label="Alertas">
        <ng-template matTabContent>
          <div style="overflow: hidden">
            <app-listado-alertas
              [puntoMedicion]="puntoMedicion"
            ></app-listado-alertas>
          </div>
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
</div>
