import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ConfifNotificacionesRoutingModule } from './routing';
import { NotificacionesScadaComponent } from './notificaciones-scada/notificaciones-scada.component';

@NgModule({
  declarations: [NotificacionesScadaComponent],
  imports: [CommonModule, AuxiliaresModule, ConfifNotificacionesRoutingModule],
})
export class ConfigNotificacionesModule {}
