<div style="height: 100%; display: flex; flex-direction: column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <section class="titulo layout">
      <h1
        style="
          font-size: 18px;
          font-weight: 900;
          padding: 0.5em 0 0.5em 0.5em;
          margin: 0;
        "
        class="grow1"
      >
        NUC
      </h1>
      <img
        src="assets/iconos/config.png"
        alt=""
        *ngIf="
          helper.puedeEditarDispositivos() &&
          correctora?.dispositivo &&
          correctora?.dispositivo?.config?.modoOperacion !== 'consumos'
        "
        matTooltip="Configurar"
        style="width: 30px; margin-right: 0.5em; cursor: pointer"
        (click)="configNuc(correctora!.dispositivo!)"
      />
      <img
        src="assets/estados/mantenimiento.png"
        alt=""
        *ngIf="helper.puedeEditarCorrectora(correctora)"
        matTooltip="Crear Mantenimiento"
        style="width: 30px; height: 30px; margin-right: 0.5em; cursor: pointer"
        (click)="crearMantenimiento(correctora?.dispositivo)"
      />
    </section>
  </div>
  <div
    class="column-center"
    style="justify-content: center; height: 100%; padding: 0.5em 0 0.5em 0"
  >
    <table style="width: 100%" *ngIf="correctora?.dispositivo">
      <!-- Device Name -->
      <tr>
        <th>Device</th>
        <td style="text-align: end">
          {{ correctora?.dispositivo?.deviceName || "..." }}
        </td>
      </tr>
      <!-- Deveui -->
      <tr>
        <th>DevEUI</th>
        <td style="text-align: end">
          {{ correctora?.dispositivo?.deveui || "..." }}
        </td>
      </tr>
      <!-- Firmware -->
      <tr>
        <th>Firmware</th>
        <td style="text-align: end">
          {{ configActual?.firmwareNuc || "..." }}
        </td>
      </tr>
      <!-- Batería -->
      <tr>
        <th>Batería</th>
        <td style="text-align: end">
          <span
            *ngIf="
              helper.bateriaDispositivoNuc(correctora?.dispositivo) as bateria
            "
            [class.no-info]="bateria === 'Sin Reportar'"
          >
            {{ bateria }}
          </span>
        </td>
      </tr>
      <!-- Ultima Comunicacion -->
      <tr>
        <th>Última Comunicación</th>
        <td style="text-align: end">
          {{
            (correctora?.dispositivo?.fechaUltimaComunicacion
              | date: "short") || "..."
          }}
        </td>
      </tr>

      <!-- Separador -->
      <tr *ngIf="mantenimiento">
        <td colspan="100%">
          <mat-divider class="divider" style="margin: 1em 0"></mat-divider>
        </td>
      </tr>

      <tr *ngIf="mantenimiento">
        <th>Tipo</th>
        <td style="text-align: end">
          {{ mantenimiento.tipo || "..." }}
        </td>
      </tr>

      <tr *ngIf="mantenimiento">
        <th>Último Mantenimiento</th>
        <td style="text-align: end">
          {{ (mantenimiento.fechaCreacion | date: "short") || "..." }}
        </td>
      </tr>

      <tr *ngIf="mantenimiento">
        <th>Descripción</th>
        <td style="text-align: end">
          {{ mantenimiento.descripcion || "..." }}
        </td>
      </tr>

      <!-- Separador -->
      <tr>
        <td colspan="100%">
          <mat-divider class="divider" style="margin: 1em 0"></mat-divider>
        </td>
      </tr>

      <!-- Config -->
      <!-- Modo Operacion -->
      <tr>
        <th>Modo de Operación</th>
        <td style="text-align: end">
          {{ textoModoOperacion(configActual?.modoOperacion) }}
        </td>
      </tr>
      <!-- Hora Inicio -->
      <tr>
        <th>Hora de Inicio</th>
        <td style="text-align: end">
          {{
            configActual?.horaInicio
              ? "0" + configActual?.horaInicio + ":00 Hs."
              : "..."
          }}
        </td>
      </tr>
      <tr *ngIf="correctora?.modelo === 'Mercury'">
        <th>Clave Mercury</th>
        <td style="text-align: end">
          {{ configActual?.claveMercury || "..." }}
        </td>
      </tr>
    </table>

    <!-- Si no hay croma -->
    <div
      *ngIf="!correctora?.dispositivo"
      style="
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <h3>
        <strong> Sin asignar </strong>
      </h3>
    </div>
  </div>

  <!-- Config no aplicada -->
  <div
    *ngIf="configAplicada !== true"
    class="bg-accent"
    style="border-bottom-left-radius: 30px"
  >
    <div
      class="column-center"
      style="justify-content: center; padding: 0 0 0.5em 0"
    >
      <h3 style="padding-left: 0.5em">
        <strong> Configuración aún no aplicada</strong>
      </h3>
      <!-- Config -->
      <table style="width: 100%">
        <tr>
          <th>Modo de Operación</th>
          <td style="text-align: end">
            {{ textoModoOperacion(configDeseada?.modoOperacion) }}
          </td>
        </tr>
        <tr>
          <th>Hora de Inicio</th>
          <td style="text-align: end">
            {{
              configDeseada?.horaInicio
                ? "0" + configDeseada?.horaInicio + ":00 Hs."
                : "..."
            }}
          </td>
        </tr>
        <tr *ngIf="correctora?.modelo === 'Mercury'">
          <th>Clave Mercury</th>
          <td style="text-align: end">
            {{ configDeseada?.claveMercury || "..." }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
