<div class="transparente row-center" style="height: 100%">
  @if (this.valores) {
    @if (this.valores.valorActual === 1) {
      <div class="column-center" style="gap: 5px">
        <mat-icon color="accent" inline="true" style="font-size: 70px">
          radio_button_checked
        </mat-icon>
        <span style="text-align: center; font-weight: bold">
          {{ scada?.config?.textoTrue }}
        </span>
      </div>
    } @else {
      <div class="column-center" style="gap: 5px">
        <mat-icon color="warn" inline="true" style="font-size: 70px">
          radio_button_unchecked
        </mat-icon>
        <span style="text-align: center; font-weight: bold">
          {{ scada?.config?.textoFalse }}
        </span>
      </div>
    }
  }
</div>
