import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IPuntoMedicion, IScada } from 'modelos/src';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { CrearEditarScadaComponent } from 'src/app/modulos/scadas/crear-editar-scada/crear-editar-scada.component';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-detalles-scada',
  templateUrl: './detalles-scada.component.html',
  styleUrls: ['./detalles-scada.component.scss'],
})
export class DetallesScadaComponent implements OnInit {
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() scadas?: IScada[];

  public scadaSeleccionado?: IScada;

  constructor(
    public helper: HelperService,
    private matDialog: MatDialog,
  ) {}

  public async editar(data?: IScada) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarScadaComponent, config);
  }

  public seleccionar(scada: IScada) {
    this.scadaSeleccionado = scada;
  }

  public parseTag(t: string) {
    if (!this.scadas) {
      return t;
    }
    if (this.scadas.length >= 2) {
      // Devuelve el tag truncado
      return t.slice(0, 7) + '...';
    } else {
      return t;
    }
  }

  ngOnInit(): void {
    if (this.scadas && this.scadas.length) {
      this.scadaSeleccionado = this.scadas[0];
    }
  }
}
