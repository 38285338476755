import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  IEstado,
  IFilter,
  IListado,
  IPopulate,
  IPuntoMedicion,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import {
  IFiltroGlobal,
  NavigationService,
} from '../../navigation/navigation.service';
import { MarkerClustererOptions } from '@angular/google-maps';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss'],
})
export class MapaComponent implements OnInit, OnDestroy {
  public filtroGlobal: IFiltroGlobal = {};
  private modoOscuro$?: Subscription;

  public clustererOptions: MarkerClustererOptions = {
    maxZoom: 13,
    calculator: (markers) => {
      return this.calculator(markers);
    },
    imageSizes: [56, 56, 56, 56, 56],
  };

  public loading: boolean = false;
  public mapOptions: google.maps.MapOptions = JSON.parse(
    JSON.stringify(HelperService.mapOptions),
  );
  @ViewChild('googleMap') googleMap?: google.maps.Map;
  public mapCenter?: google.maps.LatLngLiteral;

  public clusterer = 'assets/images/marker-cluster/originales-2/m';

  public puntos?: (IPuntoMedicion & { icono?: google.maps.Icon })[] = [];

  // Listado Continuo
  public filtroGlobal$?: Subscription;
  public puntos$?: Subscription;

  constructor(
    private navigationService: NavigationService,
    private listadosService: ListadosService,
    public helper: HelperService,
  ) {}

  private calculator(markers: google.maps.Marker[]) {
    let ok = 0;
    let alerta = 0;
    let sinReportar = 0;
    let grises = 0;
    for (const m of markers) {
      const icon = m.getIcon() as any;
      const estado = icon?.estado as
        | 'Sin Asignar'
        | 'En Mantenimiento'
        | 'Resolver'
        | 'Sin Reportar'
        | 'Operativa'
        | 'Alerta';
      if (estado === 'Operativa' || estado === 'En Mantenimiento') {
        ok++;
      }
      if (estado === 'Alerta') {
        alerta++;
      }
      if (estado === 'Sin Reportar' || estado === 'Resolver') {
        sinReportar++;
      }
      if (estado === 'Sin Asignar') {
        grises++;
      }
    }

    if (alerta > 0) {
      return {
        // Lo que aparece en el cluster
        text: `${alerta} / ${markers.length}`,
        // Imagen del cluster
        index: 1,
        // Tooltip
        title: 'Alerta',
      };
    }

    if (sinReportar > 0) {
      return {
        // Lo que aparece en el cluster
        text: `${sinReportar} / ${markers.length}`,
        // Imagen del cluster
        index: 2,
        // Tooltip
        title: 'Sin Reportar',
      };
    }

    if (grises > 0) {
      return {
        // Lo que aparece en el cluster
        text: `${grises} / ${markers.length}`,
        // Imagen del cluster
        index: 3,
        // Tooltip
        title: 'Sin Asignar',
      };
    }

    return {
      // Lo que aparece en el cluster
      text: `${ok} / ${markers.length}`,
      // Imagen del cluster
      index: 4,
      // Tooltip
      title: 'Operativa',
    };
  }

  private async initMap() {
    const zoom = localStorage.getItem(`ultimoZoom`);
    if (zoom) {
      this.mapOptions.zoom = +zoom;
    }

    const pos = localStorage.getItem(`ultimaPos`);
    if (pos) {
      const posJson = JSON.parse(pos);
      this.mapOptions.center = posJson;
      this.mapCenter = {
        lat: posJson.lat,
        lng: posJson.lng,
      };
    } else {
      const ubicacion = this.puntos?.find((punto) => punto.ubicacion)
        ?.ubicacion;
      if (ubicacion) {
        this.mapOptions.center = ubicacion;
        this.mapCenter = {
          lat: ubicacion.lat,
          lng: ubicacion.lng,
        };
      } else {
        const currPos = await HelperService.getCurrentPosition();
        this.mapOptions.center = currPos;
        this.mapCenter = {
          lat: currPos.lat,
          lng: currPos.lng,
        };
      }
    }
  }

  private async listarPuntos(): Promise<void> {
    if (this.helper.puedeVerPuntosDeMedicion()) {
      // Filtro
      const filter: IFilter<any> = {
        'ubicacion.lat': { $exists: true },
      };
      const populate: IPopulate = [
        {
          path: 'correctora',
          select:
            'numeroSerie modelo deveui bateria ultimaAlerta.fechaCreacion ultimaAlerta.mensaje ultimoRegistro.fechaCreacion ultimoRegistro.timestamp',
          populate: {
            path: 'dispositivo',
            select: 'deviceName fechaUltimaComunicacion',
          },
        },
        {
          path: 'unidadPresion',
          select:
            'modelo numeroSerie deveui ultimaAlerta.timestamp ultimaAlerta.mensaje ultimoRegistro.fechaCreacion ultimoRegistro.valores',
          populate: {
            path: 'dispositivo',
            select:
              'deviceName fechaUltimaComunicacion config.voltajeBateria tipoDispositivo',
          },
        },
        {
          path: 'medidorResidencial',
          select:
            'nombre deviceMeterNumber deveui ultimaAlerta.fechaCreacion ultimaAlerta.mensaje ultimoReporte.fechaCreacion ultimoReporte.valores',
          populate: {
            path: 'dispositivo',
            select: 'deviceName fechaUltimaComunicacion',
          },
        },
      ];
      if (this.filtroGlobal?.unidadNegocio) {
        filter.idUnidadNegocio = this.filtroGlobal?.unidadNegocio._id;
      } else {
        delete filter.idUnidadNegocio;
      }

      if (this.filtroGlobal.division) {
        filter.division = this.filtroGlobal.division;
      } else {
        delete filter.division;
      }
      const query: IQueryParam = {
        select:
          'nombre ubicacion division idCorrectora idUnidadPresion idMedidorResidencial estado',
        sort: 'nombre',
        filter: JSON.stringify(filter),
        populate: JSON.stringify(populate),
      };

      // Listado
      this.puntos$?.unsubscribe();
      this.puntos$ = this.listadosService
        .subscribe<IListado<IPuntoMedicion>>('puntosMedicion', query)
        .subscribe((data) => {
          this.puntos = data.datos;
          console.log(`listado de puntosMedicion`, data);
          this.crearMarcadoresPuntos();
        });
      await this.listadosService.getLastValue('puntosMedicion', query);
    }
  }

  public crearMarcadoresPuntos() {
    this.puntos?.forEach((p) => {
      const division = p.division?.toLowerCase();

      p.icono = {
        url: this.urlPunto(p.estado!, division),
        scaledSize: new google.maps.Size(35, 35),
        anchor: new google.maps.Point(16, 16),
        estado: p.estado,
      } as any;
    });
  }

  private urlPunto(estado: IEstado, tipo?: string) {
    if (!tipo) {
      return `assets/pins/nada/sinAsignar.png`;
    }

    switch (estado) {
      case 'Operativa':
      case 'En Mantenimiento':
        return `assets/pins/${tipo}/operativa.png`;
      case 'Alerta':
        return `assets/pins/${tipo}/alerta.png`;
      case 'Sin Reportar':
      case 'Resolver':
      case 'Sin Comunicación':
        return `assets/pins/${tipo}/sinReportar.png`;
      case 'Sin Asignar':
        return `assets/pins/${tipo}/sinAsignar.png`;
      default:
        return `assets/pins/${tipo}/operativa.png`;
    }
  }

  private suscribeFiltroGlobal() {
    this.filtroGlobal$ = this.navigationService
      .subscribeFiltroGlobal()
      .subscribe((filtro) => {
        this.filtroGlobal = filtro;
        this.listarPuntos();
      });
    this.filtroGlobal = this.navigationService.getFiltroGlobal();
  }

  public guardarPos() {
    const zoom = this.googleMap?.getZoom();
    localStorage.setItem(`ultimoZoom`, `${zoom}`);
    const pos = this.googleMap?.getCenter()?.toJSON();
    localStorage.setItem(`ultimaPos`, JSON.stringify(pos));
  }

  private suscribeCambioTema() {
    this.navigationService.modoOscuro$.subscribe({
      next: (v: boolean) => this.changeTheme(v),
    });
  }

  private changeTheme(b: boolean) {
    const options = b
      ? HelperService.mapOptionsDark
      : HelperService.mapOptionsLight;
    this.mapOptions = JSON.parse(JSON.stringify(options));
  }

  async ngOnInit(): Promise<void> {
    this.suscribeFiltroGlobal();
    this.suscribeCambioTema();
    await this.listarPuntos();
    await this.initMap();
  }

  ngOnDestroy(): void {
    this.filtroGlobal$?.unsubscribe();
    this.puntos$?.unsubscribe();
    this.modoOscuro$?.unsubscribe();
  }
}
