<div fxLayout="column" fxLayoutGap="1em">
  <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <!-- Resumen Punto -->
    <div fxFlex.gt-sm="66">
      <app-resumen-punto-medicion
        *ngIf="puntoMedicion"
        [puntoMedicion]="puntoMedicion"
      ></app-resumen-punto-medicion>
    </div>

    <!-- Detalles Correctora / Unidad Presion / Medidor -->
    @if (correctora || unidadPresion || medidor) {
      <div
        class="fondo-85"
        style="border-bottom-left-radius: 30px"
        fxFlex.gt-sm="34"
      >
        @if (correctora) {
          <app-detalles-correctora
            [puntoMedicion]="puntoMedicion"
            [correctora]="correctora"
            [dispositivo]="correctora.dispositivo"
          ></app-detalles-correctora>
        }
        @if (unidadPresion) {
          <app-detalles-unidad-presion
            [puntoMedicion]="puntoMedicion"
            [unidadPresion]="unidadPresion"
          ></app-detalles-unidad-presion>
        }
        @if (medidor) {
          <app-detalles-medidor-residencial
            [puntoMedicion]="puntoMedicion"
            [medidorResidencial]="medidor"
          ></app-detalles-medidor-residencial>
        }
      </div>
    } @else {
      <div
        class="fondo-85"
        style="border-bottom-left-radius: 30px"
        fxFlex.gt-sm="34"
      >
        <div
          style="
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <h3>
            <strong> Sin Dispositivo </strong>
          </h3>
        </div>
      </div>
    }
  </div>

  <!-- Ultimo Registro - NUC/NSP - Croma/Grafico Presion/Consumo -->
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <!-- Último Registro  -->
    @if (correctora || unidadPresion || medidor) {
      <div
        class="fondo-85"
        style="border-bottom-left-radius: 30px"
        fxFlex.gt-sm="33"
      >
        @if (correctora) {
          <app-ultimo-registro-nuc
            [correctora]="puntoMedicion!.correctora"
          ></app-ultimo-registro-nuc>
        }
        @if (unidadPresion) {
          <app-ultimo-reporte-unidad-presion
            [unidadPresion]="puntoMedicion!.unidadPresion"
          ></app-ultimo-reporte-unidad-presion>
        }
        @if (medidor) {
          <app-ultimo-reporte-medidor-residencial
            [punto]="puntoMedicion"
          ></app-ultimo-reporte-medidor-residencial>
        }
      </div>
    }

    <!-- NUC / NSP / VERIBOX (CONFIGS) -->
    @if (
      puntoMedicion?.correctora?.dispositivo ||
      puntoMedicion?.unidadPresion?.dispositivo ||
      puntoMedicion?.medidorResidencial?.dispositivo
    ) {
      <div
        class="fondo-85"
        style="border-bottom-left-radius: 30px"
        fxFlex.gt-sm="34"
      >
        <!-- NUC / Configuracion -->
        @if (
          puntoMedicion?.correctora?.dispositivo?.tipoDispositivo === "NUC"
        ) {
          <app-detalles-nuc
            [correctora]="correctora"
            [configActual]="configActualNuc"
            [configDeseada]="configDeseadaNuc"
          ></app-detalles-nuc>
        }

        <!-- NSP / Configuracion -->
        @if (
          puntoMedicion?.unidadPresion?.dispositivo?.tipoDispositivo === "NSP"
        ) {
          <app-detalles-nsp
            [unidadPresion]="unidadPresion"
            [configActual]="configActualNsp"
            [configDeseada]="configDeseadaNsp"
          ></app-detalles-nsp>
        }

        <!-- Veribox / Configuracion -->
        @if (
          puntoMedicion?.unidadPresion?.dispositivo?.tipoDispositivo ===
          "VERIBOX MICRO"
        ) {
          <app-detalles-veribox
            [unidadPresion]="unidadPresion"
            [configActual]="configActualVeribox"
            [configDeseada]="configDeseadaVeribox"
            [fechaCreacion]="fechaCreacion"
          ></app-detalles-veribox>
        }

        <!-- SML / Configuracion -->
        @if (
          puntoMedicion?.medidorResidencial?.dispositivo?.tipoDispositivo ===
            "SML" ||
          puntoMedicion?.medidorResidencial?.dispositivo?.tipoDispositivo ===
            "WRC"
        ) {
          <app-detalles-sml [punto]="puntoMedicion"></app-detalles-sml>
        }
      </div>
    }

    <!-- Ultima Croma - Grafico Presion Actual - Consumo ultimas 24hs -->
    @if (correctora || unidadPresion || medidor) {
      <!-- NUC - Última Cromatografía -->
      @if (puntoMedicion?.division === "Correctoras") {
        <div
          class="fondo-85"
          style="border-bottom-left-radius: 30px"
          fxFlex.gt-sm="33"
        >
          <app-ultima-cromatografia-correctora
            [correctora]="correctora"
          ></app-ultima-cromatografia-correctora>
        </div>
      }

      <!-- Unidad Presion - Grafico presion actual -->
      @if (puntoMedicion?.division === "Presión") {
        <div
          class="fondo-85"
          style="border-bottom-left-radius: 30px"
          fxFlex.gt-sm="33"
        >
          <app-grafico-presion-actual
            [unidadPresion]="unidadPresion"
          ></app-grafico-presion-actual>
        </div>
      }

      <!-- Medidor Residencial - Consumo ultimas 24hs -->
      @if (puntoMedicion?.division === "Residencial") {
        <div
          class="fondo-85"
          style="border-bottom-left-radius: 30px"
          fxFlex.gt-sm="33"
        >
          <app-consumo-residencial
            [punto]="puntoMedicion"
          ></app-consumo-residencial>
        </div>
      }
    }
  </div>

  <!-- Grafico Max, Min y Agv / 15 dias y Grafico Alertas -->
  @if (puntoMedicion?.division === "Presión") {
    <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutGap="1em">
      <!-- Grafico Max, Min y Agv / 15 dias -->
      <div
        class="fondo-85"
        style="border-bottom-left-radius: 30px"
        fxFlex.gt-sm="65"
      >
        <app-grafico-min-max-prom
          [puntoMedicion]="puntoMedicion"
          [range]="range15Dias"
        >
        </app-grafico-min-max-prom>
      </div>

      <!-- Grafico Alertas -->
      <div
        class="fondo-85"
        style="border-bottom-left-radius: 30px"
        fxFlex.gt-sm="35"
      >
        <app-grafico-alertas
          [puntoMedicion]="puntoMedicion"
        ></app-grafico-alertas>
      </div>
    </div>
  }

  <!-- Time Picker y Grafico Max y Min / 1 Año -->
  <div
    style="
      height: 100%;
      display: flex;
      flex-direction: column;
      border-bottom-left-radius: 30px;
      margin: 1em 0;
    "
    class="fondo-85"
  >
    <!-- Titulo -->
    <div class="titulo" fxLayout="row" fxLayoutAlign="space-between center">
      <h1
        style="
          font-size: 18px;
          font-weight: 900;
          padding: 0.5em 0 0.5em 0.5em;
          margin: 0;
        "
      >
        Registros Históricos
      </h1>
    </div>

    <!-- Time Picker -->
    <div style="margin: 1em 0 1em 0" *ngIf="range && presets">
      <app-datetime-range-picker
        [(range)]="range"
        [presets]="presets"
      ></app-datetime-range-picker>
    </div>

    <!-- Grafico Max y Min / 1 Año-->
    <div class="fondo-85" style="border-bottom-left-radius: 30px">
      @if (puntoMedicion?.division === "Presión") {
        <app-grafico-min-max [puntoMedicion]="puntoMedicion" [range]="range">
        </app-grafico-min-max>
      }

      @if (puntoMedicion?.division === "Correctoras") {
        <app-grafico-correctora [puntoMedicion]="puntoMedicion" [range]="range">
        </app-grafico-correctora>
      }

      @if (puntoMedicion?.division === "Residencial") {
        <app-grafico-medidor-residencial
          [puntoMedicion]="puntoMedicion"
          [range]="range"
        >
        </app-grafico-medidor-residencial>
      }
    </div>
  </div>
</div>

<!-- Tablas -->
<div style="margin: 1em 0 1em 0">
  <mat-tab-group>
    <!-- Correctoras -->
    @if (puntoMedicion?.division === "Correctoras") {
      <!-- Registros -->
      <mat-tab label="Registros">
        <ng-template matTabContent>
          <div style="overflow: hidden">
            <app-listado-registros
              [puntoMedicion]="puntoMedicion"
              [range]="range"
            ></app-listado-registros>
          </div>
        </ng-template>
      </mat-tab>
      <!-- Registros Faltantes -->
      <mat-tab label="Registros Faltantes">
        <ng-template matTabContent>
          <div style="overflow: hidden">
            <app-listado-registros-faltantes
              [puntoMedicion]="puntoMedicion"
            ></app-listado-registros-faltantes>
          </div>
        </ng-template>
      </mat-tab>
      <!-- Alertas -->
      <mat-tab label="Alertas">
        <ng-template matTabContent>
          <div style="overflow: hidden">
            <app-listado-alertas
              [puntoMedicion]="puntoMedicion"
            ></app-listado-alertas>
          </div>
        </ng-template>
      </mat-tab>
      <!-- Mantenimientos -->
      <mat-tab label="Mantenimientos">
        <ng-template matTabContent>
          <div style="overflow: hidden">
            <app-listado-mantenimientos
              [puntoMedicion]="puntoMedicion"
            ></app-listado-mantenimientos>
          </div>
        </ng-template>
      </mat-tab>
    }
    <!-- Presion -->
    @if (puntoMedicion?.division === "Presión") {
      <!-- Reportes -->
      <mat-tab label="Registros">
        <ng-template matTabContent>
          <div style="overflow: hidden">
            <app-listado-reportes
              [puntoMedicion]="puntoMedicion"
              [range]="range"
            ></app-listado-reportes>
          </div>
        </ng-template>
      </mat-tab>
      <!-- NSP / Reportes Faltantes -->
      @if (unidadPresion?.dispositivo?.tipoDispositivo === "NSP") {
        <mat-tab label="Registros Faltantes ">
          <ng-template matTabContent>
            <div style="overflow: hidden">
              <app-listado-reportes-faltantes
                [puntoMedicion]="puntoMedicion"
              ></app-listado-reportes-faltantes>
            </div>
          </ng-template>
        </mat-tab>
      }
      <!-- Alertas -->
      <mat-tab label="Alertas">
        <ng-template matTabContent>
          <div style="overflow: hidden">
            <app-listado-alertas
              [puntoMedicion]="puntoMedicion"
            ></app-listado-alertas>
          </div>
        </ng-template>
      </mat-tab>
      <!-- Veribox / Auditorias -->
      @if (unidadPresion?.dispositivo?.tipoDispositivo === "VERIBOX MICRO") {
        <mat-tab label="Auditorias ">
          <ng-template matTabContent>
            <div style="overflow: hidden">
              <app-listado-auditorias-veribox
                [unidadPresion]="unidadPresion"
              ></app-listado-auditorias-veribox>
            </div>
          </ng-template>
        </mat-tab>
      }
      <!-- Veribox / Log Reportes -->
      @if (unidadPresion?.dispositivo?.tipoDispositivo === "VERIBOX MICRO") {
        <mat-tab label="Log Reportes">
          <ng-template matTabContent>
            <div style="overflow: hidden">
              <app-listado-log-reportes-veribox
                [puntoMedicion]="puntoMedicion"
              ></app-listado-log-reportes-veribox>
            </div>
          </ng-template>
        </mat-tab>
      }
      <!-- Veribox / Log Twilio -->
      @if (unidadPresion?.dispositivo?.tipoDispositivo === "VERIBOX MICRO") {
        <mat-tab label="Log SMS">
          <ng-template matTabContent>
            <div style="overflow: hidden">
              <app-listado-log-twilio
                [puntoMedicion]="puntoMedicion"
              ></app-listado-log-twilio>
            </div>
          </ng-template>
        </mat-tab>
      }
    }
    <!-- Medidores Residenciales -->
    @if (puntoMedicion?.division === "Residencial") {
      <!-- Reportes -->
      <mat-tab label="Registros">
        <ng-template matTabContent>
          <div style="overflow: hidden">
            <app-listado-reportes-medidor-residencial
              [punto]="puntoMedicion"
            ></app-listado-reportes-medidor-residencial>
          </div>
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
</div>
