<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      *ngIf="form"
      id="loginForm"
      [formGroup]="form"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      @if (
        this.maximo === null ||
        this.maximo === undefined ||
        this.minimo === null ||
        this.minimo === undefined
      ) {
        <div class="row-center" style="width: 100%">
          <mat-chip style="background-color: red; color: white"
            >Límites del SCADA no definidos.</mat-chip
          >
        </div>
        <br />
      }

      <!-- Límite LOLO  -->
      <mat-form-field>
        <mat-label>Límite Muy Bajo</mat-label>
        <input
          matInput
          type="number"
          formControlName="limiteLL"
          required
          (keypress)="logForm()"
        />
        <mat-error *ngIf="form.get('limiteLL')?.hasError('limiteLL')">
          El límite muy bajo no puede ser mayor al límite bajo
        </mat-error>
        <mat-error *ngIf="form.get('limiteLL')?.hasError('min')">
          El límite muy bajo no puede ser menor al límite del dispositivo
        </mat-error>
      </mat-form-field>

      <!-- Límite LO  -->
      <mat-form-field>
        <mat-label>Límite Bajo</mat-label>
        <input matInput type="number" formControlName="limiteL" required />
        <mat-error *ngIf="form.get('limiteL')?.hasError('limiteL')">
          El límite bajo no puede ser mayor al límite muy bajo ni menor al
          límite alto
        </mat-error>
      </mat-form-field>

      <!-- Límite HI  -->
      <mat-form-field>
        <mat-label>Límite Alto</mat-label>
        <input matInput type="number" formControlName="limiteH" required />
        <mat-error *ngIf="form.get('limiteH')?.hasError('limiteH')">
          El límite alto no puede ser mayor al límite muy alto ni menor al
          límite bajo
        </mat-error>
      </mat-form-field>

      <!-- Límite HIHI  -->
      <mat-form-field>
        <mat-label>Límite Muy Alto</mat-label>
        <input matInput type="number" formControlName="limiteHH" required />
        <mat-error *ngIf="form.get('limiteHH')?.hasError('limiteHH')">
          El límite muy alto no puede ser menor al límite alto
        </mat-error>
        <mat-error *ngIf="form.get('limiteHH')?.hasError('max')">
          El límite muy alto no puede ser mayor al límite del dispositivo
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || this.enviando"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
