<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      *ngIf="form"
      id="form"
      [formGroup]="form"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <ng-select
        [items]="tiposEnvio"
        placeholder="Tipo de Notificación"
        [appendTo]="'body'"
        [multiple]="true"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="tiposSeleccionados"
      >
      </ng-select>

      <ng-select
        [items]="tiposAlertas"
        placeholder="Tipo de Alerta"
        formControlName="tipoAlerta"
        [appendTo]="'body'"
      >
      </ng-select>

      @if (usuarios) {
        <ng-select
          [items]="usuarios"
          placeholder="Usuarios"
          loadingText="Cargando..."
          typeToSearchText="Buscar..."
          notFoundText="No se encontraron resultados"
          formControlName="idsUsuarios"
          [multiple]="true"
          [searchFn]="searchUsuario"
          [appendTo]="'body'"
          [required]="true"
          [closeOnSelect]="false"
          [bindValue]="'_id'"
          (change)="onSelectUsuario($event)"
        >
          <ng-template ng-label-tmp let-item="item">
            <div class="row-flex-start" style="gap: 5px">
              <span class="column-center" (click)="removeUsuario(item._id)">
                <mat-icon style="transform: scale(0.5); cursor: pointer"
                  >close
                </mat-icon>
              </span>
              <span>{{ item.username }} </span>
              <!-- <span>{{ item?.datosPersonales?.nombre }}</span> -->
              <!-- <span class="no-info">{{ item?.datosPersonales?.telefono }}</span> -->
              <!-- <span>{{ item?.localidad }}</span> -->
              <!-- <span>{{ item?.centroOperativo }}</span> -->
              <!-- <span>{{ item?.unidadNegocio }}</span> -->
            </div>
          </ng-template>

          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          >
            <div class="row-flex-start" style="gap: 5px">
              <span>{{ item.username }} </span>
              <!-- <span>{{ item?.datosPersonales?.nombre }}</span> -->
              <span class="no-info" style="font-size: 12px">{{
                item?.datosPersonales?.telefono
              }}</span>
              <!-- <span>{{ item?.localidad }}</span> -->
              <!-- <span>{{ item?.centroOperativo }}</span> -->
              <!-- <span>{{ item?.unidadNegocio }}</span> -->
            </div>
          </ng-template>
        </ng-select>
      }
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      class="boton-guardar"
      type="submit"
      form="form"
      color="accent"
      style="width: 50%"
      [disabled]="!form?.valid"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="form"
      style="width: 50%"
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>
</mat-card>
