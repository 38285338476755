<div>
  <div style="display: flex; flex-direction: row; width: 100%; gap: 8px">
    <!-- Unifilares -->
    @if (helper.puedeVerScadaUnifilares()) {
      <div class="column-flex-start" style="width: 50%; gap: 5px">
        <span>Unifilares</span>
        @for (g of gruposU; track $index) {
          <button
            mat-raised-button
            style="width: 90%"
            (click)="navegar(g._id!)"
          >
            {{ g.nombre }}
            @if (hayDistintasUN) {
              <span class="no-info"> - {{ g.unidadNegocio?.nombre }}</span>
            }
          </button>
        }
      </div>
    }
    @if (helper.puedeVerScadaMediciones()) {
      <!-- Mediciones -->
      <div class="column-flex-start" style="width: 50%; gap: 5px">
        <span>Mediciones</span>
        @for (g of gruposM; track $index) {
          <button
            mat-raised-button
            style="width: 90%"
            (click)="navegar(g._id!)"
          >
            {{ g.nombre }}
            @if (hayDistintasUN) {
              <span class="no-info"> - {{ g.unidadNegocio?.nombre }}</span>
            }
          </button>
        }
      </div>
    }
  </div>
</div>
