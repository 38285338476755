import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPuntoMedicion, IUpdatePuntoMedicion } from 'modelos/src';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { firstValueFrom } from 'rxjs';
import { PuntosMedicionService } from '../../puntos-medicion/service';

@Component({
  selector: 'app-editar-posicion',
  standalone: false,
  templateUrl: './editar-posicion.component.html',
  styleUrl: './editar-posicion.component.scss',
})
export class EditarPosicionComponent implements OnInit {
  public index = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPuntoMedicion,
    private dialogRef: MatDialogRef<EditarPosicionComponent>,
    private service: PuntosMedicionService,
    private dialogService: DialogService,
    private helper: HelperService,
  ) {}

  public close(): void {
    this.dialogRef.close();
  }

  public async guardar() {
    const res = await this.dialogService.confirm(
      'Guardar Posición',
      '¿Está seguro que desea guardar la posición?',
    );
    if (!res) {
      return;
    }
    try {
      const update: IUpdatePuntoMedicion = {
        posicion: this.index,
      };
      await firstValueFrom(this.service.editar(this.data._id!, update));
      this.dialogRef.close();
    } catch (error) {
      this.helper.notifError('Error al guardar la posición');
      console.error(error);
    }
  }

  ngOnInit(): void {
    if (this.data.posicion) {
      this.index = this.data.posicion;
    }
  }
}
