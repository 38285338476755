@if (scada) {
  <section class="layout" style="margin-bottom: 0.3em">
    <!-- NOMBRE -->
    <div
      class="tag"
      [matTooltip]="scada.tag || ''"
      [matTooltipPosition]="'above'"
    >
      {{ scada.nombre }}
    </div>
    <!-- CV -->
    @if (reporte?.valorActual || reporte?.valorActual === 0) {
      @if (!scada.booleano) {
        <div class="valor">
          {{ reporte?.valorActual | number: "1.2-2" }} {{ scada.unidad }}
        </div>
      } @else {
        <div class="valor">
          {{
            reporte?.valorActual
              ? scada.config?.textoTrue || "Verdadero"
              : scada.config?.textoFalse || "Falso"
          }}
        </div>
      }
    } @else {
      <div class="valor no-info">Sin Reportar</div>
    }

    <!-- LOLO -->
    @if (scada.config?.limiteLL !== null) {
      <div class="valor">
        {{ scada.config?.limiteLL || 0 | number: "1.0-1" }}
        @if (scada.config?.limiteLL !== configDispositivo?.config?.limiteLL) {
          <mat-icon
            style="color: yellow; margin-left: 0.5em"
            [matTooltip]="configDispositivo?.config?.limiteLL"
          >
            warning
          </mat-icon>
        }
      </div>
    } @else {
      <div class="valor no-info">No Aplica</div>
    }
    <!-- LO -->
    @if (scada.config?.limiteL !== null) {
      <div class="valor">
        {{ scada.config?.limiteL || 0 | number: "1.0-1" }}
        @if (scada.config?.limiteL !== configDispositivo?.config?.limiteL) {
          <mat-icon
            style="color: yellow; margin-left: 0.5em"
            [matTooltip]="configDispositivo?.config?.limiteL"
          >
            warning
          </mat-icon>
        }
      </div>
    } @else {
      <div class="valor no-info">No Aplica</div>
    }
    <!-- HI -->
    @if (scada.config?.limiteH !== null) {
      <div class="valor">
        {{ scada.config?.limiteH || 0 | number: "1.0-1" }}
        @if (scada.config?.limiteH !== configDispositivo?.config?.limiteH) {
          <mat-icon
            style="color: yellow; margin-left: 0.5em"
            [matTooltip]="configDispositivo?.config?.limiteH"
          >
            warning
          </mat-icon>
        }
      </div>
    } @else {
      <div class="valor no-info">No Aplica</div>
    }
    <!-- HIHI -->
    @if (scada.config?.limiteHH !== null) {
      <div class="valor">
        {{ scada.config?.limiteHH || 0 | number: "1.0-1" }}
        @if (scada.config?.limiteHH !== configDispositivo?.config?.limiteHH) {
          <mat-icon
            style="color: yellow; margin-left: 0.5em"
            [matTooltip]="configDispositivo?.config?.limiteHH"
          >
            warning
          </mat-icon>
        }
      </div>
    } @else {
      <div class="valor no-info">No Aplica</div>
    }
    <!-- Habilitado -->
    @if (scada.habilitado) {
      <div class="valor">
        <mat-icon
          color="accent"
          style="height: 25px; cursor: pointer"
          (click)="habilitar(scada, false)"
        >
          done
        </mat-icon>
      </div>
    } @else {
      <div class="valor">
        <mat-icon
          color="warn"
          style="height: 25px; cursor: pointer"
          (click)="habilitar(scada, true)"
        >
          close
        </mat-icon>
      </div>
    }
    <!-- ACCIONES -->
    <div class="valor" style="gap: 5px">
      @if (puedeConfigurarLimites && !scada.booleano) {
        <img
          src="assets/iconos/config.png"
          (click)="configAplicada ? config(scada) : config(scada)"
          style="cursor: pointer; height: 25px"
          matTooltip="Configurar"
        />
      }
      @if (puedeConfigurarNotificaciones) {
        <img
          src="assets/iconos/notificaciones2.png"
          (click)="notificaciones(scada)"
          style="cursor: pointer; height: 25px"
          matTooltip="Notificaciones"
        />
      }
      @if (esAdminGlobal && !scada.booleano) {
        <img
          src="assets/iconos/configWarn.png"
          (click)="reaplicarLimites(scada)"
          style="cursor: pointer; height: 25px"
          matTooltip="Reaplicar Configuración"
        />
      }
    </div>
  </section>
} @else {
  <!-- EJEMPLO PARA USAR DE ENCABEZADO -->
  <section class="layout">
    <!-- NOMBRE -->
    <div class="tag-ejemplo no-info">Variables</div>
    <!-- CV -->
    <div class="valor-ejemplo no-info">Valor Actual</div>
    <!-- LOLO -->
    <div class="valor-ejemplo no-info">Muy Bajo</div>
    <!-- LO -->
    <div class="valor-ejemplo no-info">Bajo</div>
    <!-- HI -->
    <div class="valor-ejemplo no-info">Alto</div>
    <!-- HIHI -->
    <div class="valor-ejemplo no-info">Muy Alto</div>
    <!-- Habilitado -->
    <div class="valor-ejemplo no-info">Habilitado</div>
    <!-- ACCIONES -->
    <div class="valor-ejemplo no-info">ACCIONES</div>
  </section>
}
