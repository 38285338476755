import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IConfigNotificacion,
  IEnvioSms,
  IFilter,
  IListado,
  IPuntoMedicion,
  IQueryParam,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import {
  IColumnas,
  ICrearBoton,
} from 'src/app/auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/tabla/filtro/filtro.component';
import {
  CrearEditarNotificacionScadaComponent,
  DataCreateEditNotificacionScada,
} from '../crear-editar-notificaciones-scada/crear-editar-notificaciones-scada.component';
import { ConfigNotificacionsService } from '../service';

export interface ConfigNotificacion extends IConfigNotificacion {
  punto?: string;
}

@Component({
  selector: 'app-notificaciones-scada',
  templateUrl: './notificaciones-scada.component.html',
  styleUrls: ['./notificaciones-scada.component.scss'],
})
export class NotificacionesScadaComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = NotificacionesScadaComponent.name;
  public datos?: ConfigNotificacion[] = [];
  public columnas?: IColumnas<ConfigNotificacion>[];
  public totalCount = 0;
  public botonCrear: ICrearBoton = {
    mostrar: true,
    tooltip: 'Crear Notificación',
    color: 'accent',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };

  public tipoEnvio: IFiltroTabla = {
    elementos: [
      { label: 'SMS', value: 'SMS' },
      { label: 'WhatsApp', value: 'WhatsApp' },
      { label: 'Llamada', value: 'Llamada' },
      { label: 'Notificación Push', value: 'Notificación Push' },
    ],
    filter: {
      field: 'tipoEnvio',
    },
    label: 'Tipo de envío',
    selectLabel: 'label',
    selectValue: 'value',
    tipo: 'select',
  };
  public tipoAlerta: IFiltroTabla = {
    elementos: [
      {
        label: 'Unidades Presión - Fuera de límite',
        value: 'Unidades Presión - Fuera de límite',
      },
      {
        label: 'Unidades Presión - Error en reporte de alarma',
        value: 'Unidades Presión - Error en reporte de alarma',
      },
    ],
    filter: {
      field: 'tiposAlerta',
    },
    label: 'Tipo de alerta',
    selectLabel: 'label',
    selectValue: 'value',
    tipo: 'select',
  };
  public filtros: IFiltroTabla[] = [this.tipoEnvio, this.tipoAlerta];

  // QUERY+
  private populate = [
    {
      path: 'cliente',
      select: 'nombre',
    },
    {
      path: 'scada',
      select: 'nombre idUnidadNegocio',
    },
  ];
  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    populate: JSON.stringify(this.populate),
  };
  private puntos?: IPuntoMedicion[];

  // Listado Continuo
  public datos$?: Subscription;
  public puntos$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: ConfigNotificacionsService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) {}

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarNotificacionScadaComponent, config);
  }

  public async editar(c: ConfigNotificacion) {
    const data: DataCreateEditNotificacionScada = {
      config: c,
      nombre: c.scada?.nombre,
    };
    const config: MatDialogConfig = {
      data,
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarNotificacionScadaComponent, config);
  }

  public async eliminar(dato: ConfigNotificacion): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar la notificación?`,
    );
    if (confirm) {
      try {
        await firstValueFrom(this.service.eliminar(dato._id!));
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  public async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IConfigNotificacion>>(
        'confignotificacions',
        this.query,
      )
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.log(`listado de confignotificacions`, data);
      });
    await this.listadosService.getLastValue('confignotificacions', this.query);
  }

  public async listarPuntos(): Promise<void> {
    const filter: IFilter<IPuntoMedicion> = {
      $or: [
        {
          division: 'SCADA Unifilares',
        },
        {
          division: 'SCADA Mediciones',
        },
      ],
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      select: 'nombre idsScada',
      sort: 'nombre',
    };
    this.puntos$?.unsubscribe();
    this.puntos$ = this.listadosService
      .subscribe<IListado<IPuntoMedicion>>('puntosMedicion', query)
      .subscribe((data) => {
        this.puntos = data.datos;
        console.log(`listado de puntosMedicion`, data);
      });
    await this.listadosService.getLastValue('puntosMedicion', query);
  }

  private populateDatos() {
    if (!this.datos || !this.puntos) {
      return;
    }
    for (const s of this.datos) {
      const punto = this.puntos.find(
        (p) => p.idsScada?.includes(s.scada!._id!),
      );
      s.punto = punto?.nombre;
    }
  }

  private setColumnas() {
    this.columnas = [
      // Tipos de Alerta
      {
        header: {
          label: 'Tipo de Alerta',
          sortable: true,
        },
        row: {
          field: 'tipoAlerta',
        },
      },
      // Punto
      {
        header: {
          label: 'Punto de Medición',
        },
        row: {
          parse(dato) {
            return dato.punto;
          },
          noData: 'Sin Punto',
        },
      },
      // Variable (Nombre del scada)
      {
        header: {
          label: 'Variable',
        },
        row: {
          parse(dato) {
            return dato.scada?.nombre;
          },
        },
      },
      // Usuarios
      // {
      //   header: {
      //     label: 'Usuarios',
      //   },
      //   row: {
      //     // parse(dato) {
      //     //   return dato.usuarios?.map((u) => u.username).join(', ');
      //     // },
      //     html(dato) {
      //       return parseUsuarios(dato.usuarios!);
      //     },
      //   },
      // },
      // Medio
      {
        header: {
          label: 'Medio',
        },
        row: {
          field: 'tipoEnvio',
        },
      },
      // Acciones
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'img',
              color: 'accent',
              icon: 'assets/iconos/editar.png',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
            },
            {
              tipo: 'img',
              color: 'warn',
              icon: 'assets/iconos/eliminar.png',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
            },
          ],
        },
      },
    ];
  }

  private parseNombre(dato: IEnvioSms) {
    const unidadNegocio = dato.unidadNegocio?.nombre;
    const centroOperativo = dato.centroOperativo?.nombre;
    const grupo = dato.grupo?.nombre;
    const agrupacion = dato.agrupacion2?.nombre;
    const localidad = dato.localidad?.nombre;

    switch (dato.agrupacion) {
      case 'Global':
        return 'Global';
      case 'Unidad de Negocio':
        return `${unidadNegocio}`;
      case 'Centro Operativo':
        return `${centroOperativo} (${unidadNegocio})`;
      case 'Grupo':
        return `${grupo} (${unidadNegocio} - ${
          centroOperativo
            ? centroOperativo
            : '<span class="no-info">Sin CO</span>'
        })`;
      case 'Agrupacion':
        return `${agrupacion}`;
      case 'Localidad':
        return `${localidad} (${unidadNegocio} - ${centroOperativo})`;
      default:
        return '';
    }
  }

  async ngOnInit(): Promise<void> {
    this.setColumnas();
    await Promise.all([this.listar(), this.listarPuntos()]);
    this.populateDatos();
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
