import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IUsuario,
  ICreateUsuario,
  IUpdateUsuario,
  Rol,
  IQueryParam,
  IListado,
  IUnidadNegocio,
  ICentroOperativo,
  ICuenca,
  Nivel,
  Division,
  IAgrupacion,
  ILocalidad,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { UsuariosService } from '../usuarios.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-crear-editar-usuarios',
  templateUrl: './crear-editar-usuarios.component.html',
  styleUrls: ['./crear-editar-usuarios.component.scss'],
})
export class CrearEditarUsuariosComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public esAdminGlobal?: boolean;
  public enviando = false;

  // Listado Continuo
  public unidadNegocios$?: Subscription;
  public centroOperativos$?: Subscription;
  public localidads$?: Subscription;
  public cuencas$?: Subscription;
  public agrupaciones$?: Subscription;

  public roles: Rol[] = ['Administrador', 'Usuario', 'Croma', 'Visualizar'];
  public niveles: Nivel[] = [
    'Global',
    'Unidad de Negocio',
    'Centro Operativo',
    'Localidad',
    'Agrupación',
  ];
  public divisiones: Division[] = [];
  public unidadNegocios: IUnidadNegocio[] = [];
  public centroOperativos: ICentroOperativo[] = [];
  public localidads: ILocalidad[] = [];
  public cuencas: ICuenca[] = [];
  public agrupaciones: IAgrupacion[] = [];

  get formArrayPermisos() {
    return this.form?.get('permisos') as UntypedFormArray;
  }

  get formGroupDatosPersonales() {
    return this.form?.get('datosPersonales') as UntypedFormGroup;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUsuario,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarUsuariosComponent>,
    private service: UsuariosService,
    private listadosService: ListadosService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  //

  public cambioUnidadNegocio(i: number) {
    const permiso = this.formArrayPermisos.at(i);
    permiso.patchValue({
      idsCentroOperativos: [],
      idsLocalidades: [],
      idsCuencas: [],
      idsAgrupaciones: [],
    });
  }

  public cambioCentroOperativo(i: number) {
    const permiso = this.formArrayPermisos.at(i);
    permiso.patchValue({
      idsLocalidades: [],
    });
  }

  //

  public mostrarCentroOperativo(i: number, centro: ICentroOperativo) {
    const permiso = this.formArrayPermisos.get(`${i}`) as UntypedFormGroup;
    const idsUnidadNegocios = permiso.get('idsUnidadNegocios')?.value;
    return idsUnidadNegocios?.includes(centro.idUnidadNegocio);
  }

  public mostrarLocalidad(i: number, localidad: ILocalidad) {
    const permiso = this.formArrayPermisos.get(`${i}`) as UntypedFormGroup;
    const idsCentroOperativos = permiso.get('idsCentroOperativos')?.value;
    return idsCentroOperativos?.includes(localidad.idCentroOperativo);
  }

  public mostrarCuenca(i: number, cuenca: ICuenca) {
    const permiso = this.formArrayPermisos.get(`${i}`) as UntypedFormGroup;
    const idsUnidadNegocios = permiso.get('idsUnidadNegocios')?.value;
    return idsUnidadNegocios?.includes(cuenca.idUnidadNegocio);
  }

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Usuario' : 'Crear Usuario';

    const formPermisos: UntypedFormGroup[] = [];

    if (this.data?.permisos?.length) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const permiso of this.data?.permisos) {
        formPermisos.push(
          this.fb.group(
            {
              nivel: [permiso.nivel],
              division: [permiso.division],
              rol: [permiso.rol, Validators.required],
              idsUnidadNegocios: [permiso.idsUnidadNegocios],
              idsCentroOperativos: [permiso.idsCentroOperativos],
              idsLocalidades: [permiso.idsLocalidades],
              idsCuencas: [permiso.idsCuencas],
              idsAgrupaciones: [permiso.idsAgrupaciones],
            },
            { validators: this.validarPermiso() },
          ),
        );
      }
    }

    this.form = this.fb.group(
      {
        username: [this.data?.username, Validators.required],
        clave: [null],
        permisos: this.fb.array(formPermisos),
        datosPersonales: this.fb.group({
          telefono: [this.data?.datosPersonales?.telefono, this.ValidatePhone],
          email: [this.data?.datosPersonales?.email, this.customEmailValidator],
        }),
      },
      { validators: this.validateForm },
    );
  }

  private validateForm: ValidatorFn = (control: AbstractControl) => {
    const input = control.value;

    const clave = input.clave;
    const email = input.datosPersonales?.email;

    if (!clave && this.data?._id) {
      return null;
    }

    if (clave || email) {
      return null;
    } else {
      return {
        error:
          'Si no ingresa una clave, debe ingresar un email para enviar la clave autogenerada por correo',
      };
    }
  };

  private ValidatePhone(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    if (control.value && !HelperService.telValido(control.value)) {
      return { phoneNumberInvalid: true };
    }
    return null;
  }

  private customEmailValidator(
    control: AbstractControl,
  ): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    return Validators.email(control);
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getCreateData() {
    const data: ICreateUsuario = this.form?.value;
    return data;
  }

  private getUpdateData() {
    const data: IUpdateUsuario = this.form?.value;
    delete data.clave;
    const clave = this.form?.get('clave')?.value;
    if (clave) {
      data.clave = clave;
    }
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.enviando = true;
    try {
      if (this.data?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.enviando = false;
  }

  //

  public agregarPermiso() {
    this.formArrayPermisos.push(
      this.fb.group(
        {
          nivel: [],
          division: [],
          rol: [null, Validators.required],
          idsUnidadNegocios: [[]],
          idsCentroOperativos: [[]],
          idsLocalidades: [[]],
          idsCuencas: [[]],
          idsAgrupaciones: [[]],
        },
        { validators: this.validarPermiso() },
      ),
    );
  }

  public eliminarPermiso(i: number) {
    this.formArrayPermisos.removeAt(i);
  }

  public cambioNivel(index: number) {
    // Patch value para blank
    const permiso = this.formArrayPermisos.at(index);
    permiso.patchValue({
      division: null,
      rol: null,
      idsUnidadNegocios: [],
      idsCentroOperativos: [],
      idsCuencas: [],
      idsAgrupaciones: [],
    });
  }

  //

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarCentroOperativos(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
    };
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
      .subscribe((data) => {
        this.centroOperativos = data.datos;
        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  private async listarLocalidads(): Promise<void> {
    // Filtro
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio idCentroOperativo',
      sort: 'nombre',
    };

    // Listado
    this.localidads$?.unsubscribe();
    this.localidads$ = this.listadosService
      .subscribe<IListado<ILocalidad>>('localidads', query)
      .subscribe((data) => {
        this.localidads = data.datos;
        console.log(`listado de localidads`, data);
      });
    await this.listadosService.getLastValue('localidads', query);
  }

  private async listarCuencas(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
      populate: 'unidadNegocio',
    };
    this.cuencas$?.unsubscribe();
    this.cuencas$ = this.listadosService
      .subscribe<IListado<ICuenca>>('cuencas', query)
      .subscribe((data) => {
        this.cuencas = data.datos;
        console.log(`listado de cuencas`, data);
      });
    await this.listadosService.getLastValue('cuencas', query);
  }

  private async listarAgrupaciones(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.agrupaciones$?.unsubscribe();
    this.agrupaciones$ = this.listadosService
      .subscribe<IListado<ICuenca>>('agrupacions', query)
      .subscribe((data) => {
        this.agrupaciones = data.datos;
        console.log(`listado de agrupaciones`, data);
      });
    await this.listadosService.getLastValue('agrupacions', query);
  }

  private validarPermiso(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const input = control.value;
      const nivel: Nivel = input.nivel;
      const idsUnidadNegocios = input.idsUnidadNegocios;
      const idsCentroOperativos = input.idsCentroOperativos;
      const idsLocalidades = input.idsLocalidades;
      const idsAgrupaciones = input.idsAgrupaciones;

      if (
        idsUnidadNegocios?.length ||
        idsCentroOperativos?.length ||
        idsLocalidades?.length ||
        idsAgrupaciones?.length ||
        nivel === 'Global'
      ) {
        return null;
      } else {
        return {
          error:
            'Debe seleccionar una Unidad de Negocio, Centro Operativo o Agrupación',
        };
      }
    };
  }

  private setDivisiones() {
    if (this.helper.puedeVerCorrectoras()) this.divisiones.push('Correctoras');
    if (this.helper.puedeVerUnidadPresion()) this.divisiones.push('Presión');
    if (this.helper.puedeVerResidencial()) this.divisiones.push('Residencial');
    if (this.helper.puedeVerScadaUnifilares())
      this.divisiones.push('SCADA Unifilares');
    if (this.helper.puedeVerScadaMediciones())
      this.divisiones.push('SCADA Mediciones');
  }

  async ngOnInit(): Promise<void> {
    this.esAdminGlobal = HelperService.rolesGlobales(['Administrador']);
    this.setDivisiones();
    this.createForm();
    await Promise.all([
      this.listarUnidadNegocios(),
      this.listarCentroOperativos(),
      this.listarLocalidads(),
      this.listarCuencas(),
      this.listarAgrupaciones(),
    ]);
  }

  ngOnDestroy(): void {
    this.unidadNegocios$?.unsubscribe();
    this.centroOperativos$?.unsubscribe();
    this.cuencas$?.unsubscribe();
    this.agrupaciones$?.unsubscribe();
  }
}
