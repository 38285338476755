<div
  *ngIf="scada"
  class="fondo-85"
  style="
    margin: 1em 0;
    padding: 0.5em;
    border-bottom-right-radius: 30px;
    overflow: auto;
  "
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <table>
    <tr class="border">
      <th>Estado</th>
      <th>Habilitado</th>
      <th>SCADA</th>
      <th>Unidad de Negocio</th>
    </tr>

    <tr>
      <th style="padding-bottom: 1em"></th>
    </tr>

    <tr>
      <!-- Estado -->
      <td *ngIf="scada" class="no-vertical">
        <app-icono-estado [scada]="scada"></app-icono-estado>
      </td>

      <!-- Habilitado -->
      <td class="no-vertical">
        @if (scada.habilitado) {
          <mat-icon color="primary" style="height: 25px"> done </mat-icon>
        } @else {
          <mat-icon color="primary" style="height: 25px"> close </mat-icon>
        }
      </td>

      <!-- Unidad de Presión -->
      <td>
        <p>{{ scada.nombre || "..." }}</p>
        <p>{{ scada.tag || "..." }}</p>
      </td>

      <!-- Unidad de Negocio -->
      <td>
        <p>
          {{ scada.unidadNegocio?.nombre || "..." }}
        </p>
        <p>
          {{ scada.centroOperativo?.nombre || "..." }}
        </p>
      </td>
    </tr>
  </table>
</div>
