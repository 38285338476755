import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HelperService } from 'src/app/auxiliares/helper.service';
import {
  IConfigDispositivoNUC4G,
  ICorrectora,
  ICreateConfigDispositivo,
} from 'modelos/src';
import { CrearEditarCorrectorasComponent } from '../../modulos/correctoras/crear-editar-correctoras/crear-editar-correctoras.component';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { IconoEstadoComponent } from '../icono-estado/icono-estado.component';
import { CrearEditarMantenimientoComponent } from '../mantenimientos/crear-editar-mantenimiento/crear-editar-mantenimiento.component';
import { firstValueFrom } from 'rxjs';
import { DialogService } from '../../auxiliares/dialog/dialog.service';
import { ConfigDispositivosService } from '../../modulos/dispositivos/config-dispositivos.service';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule, IconoEstadoComponent],
  selector: 'app-resumen-correctora',
  templateUrl: './resumen-correctora.component.html',
  styleUrls: ['./resumen-correctora.component.scss'],
})
export class ResumenCorrectoraComponent implements OnInit, OnChanges {
  @Input() correctora?: ICorrectora;
  public mostrarSyncHora?: boolean;

  constructor(
    public helper: HelperService,
    public matDialog: MatDialog,
    private dialogService: DialogService,
    private configDispositivosService: ConfigDispositivosService,
  ) {}

  public async editar(data?: ICorrectora) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarCorrectorasComponent, config);
  }

  public async crearMantenimiento(data?: ICorrectora) {
    const config: MatDialogConfig = {
      data: { correctora: data },
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarMantenimientoComponent, config);
  }

  public async syncHora() {
    const confirm = await this.dialogService.confirm(
      'Sincronizar hora',
      '¿Activar sincronización horaria? En el siguiente reporte de la correctora sincronizara la hora.',
    );

    if (confirm) {
      const config: IConfigDispositivoNUC4G = {
        syncHora: true,
      };
      const configDisp: ICreateConfigDispositivo = {
        deveui: this.correctora!.deveui!,
        config,
      };
      await firstValueFrom(this.configDispositivosService.upsert(configDisp));
    }
  }

  ngOnChanges(): void {
    const modelosConSync: string[] = [
      'Corus',
      'Dresser1',
      'Dresser2',
      'Proser',
      'Minicor',
      'AmericanMeter',
      'Elcor',
      'Instromet',
    ];

    this.mostrarSyncHora = modelosConSync.includes(
      this.correctora?.modelo || '',
    );
    console.log('mostrarSyncHora', this.mostrarSyncHora);
    console.log('correctora', this.correctora);
  }

  ngOnInit(): void {}
}
