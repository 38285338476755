import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IPuntoMedicion } from 'modelos/src';
import { EditarPosicionComponent } from '../editar-posicion/editar-posicion.component';
import { HelperService } from 'src/app/auxiliares/helper.service';

@Component({
  selector: 'app-posicion',
  standalone: false,
  templateUrl: './posicion.component.html',
  styleUrl: './posicion.component.scss',
})
export class PosicionComponent {
  @Input() public punto?: IPuntoMedicion;
  @Input() public index?: number;

  constructor(
    private matDialog: MatDialog,
    private helper: HelperService,
  ) {}

  public async editar(data?: IPuntoMedicion) {
    if (!this.helper.esAdminGlobal()) {
      this.helper.notifWarn(
        'No tienes permisos para editar este punto de medición',
      );
      return;
    }
    const config: MatDialogConfig = {
      data,
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(EditarPosicionComponent, config);
  }
}
