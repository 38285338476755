import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IConfigDispositivo,
  IFilter,
  IListado,
  IPopulate,
  IPuntoMedicion,
  IQueryParam,
  IScada,
} from 'modelos/src';
import { firstValueFrom, Subscription } from 'rxjs';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from 'src/app/auxiliares/tabla/filtro/filtro.component';
import { PuntosMedicionService } from '../../puntos-medicion/service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CrearEditarPuntosMedicionComponent } from '../../puntos-medicion/crear-editar-puntos-medicion/crear-editar-puntos-medicion.component';
import {
  IFiltroGlobal,
  NavigationService,
} from '../../navigation/navigation.service';
import { LoginService } from '../../login/login.service';

@Component({
  selector: 'app-puntos-scada',
  standalone: false,
  templateUrl: './puntos-scada.component.html',
  styleUrl: './puntos-scada.component.scss',
})
export class PuntosScadaComponent implements OnInit, OnDestroy {
  public idGrupo?: string | null;
  public nombreGrupo?: string;
  public puntos?: IPuntoMedicion[] = [];
  public configs?: IConfigDispositivo[] = [];

  private filtroGlobal: IFiltroGlobal = {};
  private filtroGlobal$?: Subscription;

  public puedeCrear = false;
  public puedeEditar = false;
  public puedeEliminar = false;

  // Filtros Tabla
  private centroOperativo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCentroOperativo',
    },
    label: 'Centro Operativo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  private localidad: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idLocalidad',
    },
    label: 'Localidad',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  private grupo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idsGrupos',
    },
    label: 'Grupo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  private agrupacion: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idsAgrupaciones',
    },
    label: 'Agrupación',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  private scadas: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idsScada',
    },
    label: 'SCADAS',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  private estado: IFiltroTabla = {
    elementos: [
      {
        label: 'Operativa',
      },
      {
        label: 'Sin Reportar',
      },
      {
        label: 'Resolver',
      },
      {
        label: 'En Mantenimiento',
      },
      {
        label: 'Alerta',
      },
      {
        label: 'Sin Asignar',
      },
    ],
    filter: {
      field: 'estado',
    },
    label: 'Estado',
    selectLabel: 'label',
    selectValue: 'label',
    tipo: 'select',
  };
  public filtros: IFiltroTabla[] = [
    this.estado,
    this.centroOperativo,
    this.localidad,
    this.grupo,
    this.agrupacion,
    this.scadas,
  ];
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };

  public totalCount = 0;

  private filter: IFilter<IPuntoMedicion> = {
    idsGrupos: this.idGrupo,
  };
  private populate: IPopulate[] = [
    {
      path: 'scadas',
    },
    {
      path: 'cliente',
      select: 'nombre',
    },
    {
      path: 'unidadNegocio',
      select: 'nombre',
    },
    {
      path: 'centroOperativo',
      select: 'nombre',
    },
    {
      path: 'localidad2',
      select: 'nombre',
    },
    {
      path: 'grupos',
      select: 'nombre',
    },
    {
      path: 'agrupaciones',
      select: 'nombre',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'posicion nombre',
  };
  ////
  private puntos$?: Subscription;
  private configDispositivos$?: Subscription;

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private matDialog: MatDialog,
    private service: PuntosMedicionService,
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
  ) {}

  public getConfig(scada: IScada) {
    return this.configs?.find((c) => c.tag === scada.tag);
  }

  private async listar(): Promise<void> {
    // Agrega el filtro de unidad de negocio seleccionada
    const filter: IFilter<IPuntoMedicion> = this.query.filter
      ? JSON.parse(this.query.filter)
      : {};
    if (this.filtroGlobal?.unidadNegocio) {
      filter.idUnidadNegocio = this.filtroGlobal?.unidadNegocio._id;
    } else {
      delete filter.idUnidadNegocio;
    }

    if (this.filtroGlobal.division) {
      filter.division = this.filtroGlobal.division;
    } else {
      delete filter.division;
    }

    if (this.query.filter) {
      const filter = JSON.parse(this.query.filter);
      if (this.idGrupo) {
        filter.idsGrupos = this.idGrupo;
      } else {
        filter.division = 'SCADA Mediciones';
      }
      this.query.filter = JSON.stringify(filter);
    } else {
      this.query.filter = JSON.stringify(this.filter);
    }
    // Listado
    this.puntos$?.unsubscribe();
    this.puntos$ = this.listados
      .subscribe<IListado<IPuntoMedicion>>('puntosMedicion', this.query)
      .subscribe(async (data) => {
        this.totalCount = data.totalCount;
        this.puntos = data.datos;
        this.nombreGrupo = this.puntos?.[0]?.grupos?.[0]?.nombre;
        console.log(`listado de puntosMedicion`, data);
        await this.listarConfigDispositivo();
      });
    await this.listados.getLastValue('puntosMedicion', this.query);
  }

  private async listarConfigDispositivo() {
    const scadas =
      this.puntos
        ?.filter((p) => p.scadas?.length)
        .map((p) => p.scadas!)
        ?.flat() || [];
    const tags = scadas?.map((s) => s.tag!);

    const filter: IFilter<IConfigDispositivo> = {
      tag: { $in: tags },
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
    };

    // Listado
    this.configDispositivos$?.unsubscribe();
    this.configDispositivos$ = this.listados
      .subscribe<IListado<IConfigDispositivo>>('configDispositivos', query)
      .subscribe((data) => {
        this.configs = data.datos;
        console.log(`listado de configDispositivo`, data);
      });
    await this.listados.getLastValue('configDispositivos', query);
  }

  public async paginationChange() {
    await this.listar();
  }

  // ACCIONES
  public async detalles(d: IPuntoMedicion) {
    await this.router.navigate([
      'main',
      'puntosMedicionScada',
      `${d.idsGrupos?.[0]}`,
      `${d._id}`,
    ]);
  }

  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarPuntosMedicionComponent, config);
  }

  public async editar(data: IPuntoMedicion) {
    if (!this.helper.puedeEditarPuntosDeMedicion(data)) {
      this.helper.notifError(
        'No tienes permisos para editar este punto de medición',
      );
      return;
    }
    const config: MatDialogConfig = {
      data,
      width: '1200px',
      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarPuntosMedicionComponent, config);
  }

  public async eliminar(dato: IPuntoMedicion): Promise<void> {
    if (!this.helper.puedeEditarPuntosDeMedicion(dato)) {
      this.helper.notifError(
        'No tienes permisos para editar este punto de medición',
      );
      return;
    }
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el grupo ${dato.nombre}?`,
    );
    if (confirm) {
      try {
        await firstValueFrom(this.service.eliminar(dato._id!));
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  public abrirEnBrowser(punto: IPuntoMedicion) {
    const c = punto.ubicacion;
    if (!c) return;
    const url = `https://www.google.com/maps/search/?api=1&query=${c.lat},${c.lng}`;
    window.open(url, '_blank');
  }

  public volver() {
    this.router.navigate(['main', 'puntosMedicionScada']);
  }

  private suscribeFiltroGlobal() {
    this.filtroGlobal$ = this.navigationService
      .subscribeFiltroGlobal()
      .subscribe((filtro) => {
        this.filtroGlobal = filtro;
        this.listar();
      });
    this.filtroGlobal = this.navigationService.getFiltroGlobal();
  }

  private setPermisos() {
    const user = LoginService.getUsuario();
    const permiso = user?.permisos?.find(
      (p) =>
        (p.nivel === 'Global' && p.rol === 'Administrador') ||
        ((p.division === 'SCADA Mediciones' ||
          p.division === 'SCADA Unifilares') &&
          p.rol === 'Administrador'),
    );
    this.puedeCrear = !!permiso;
    this.puedeEditar = !!permiso;
    this.puedeEliminar = !!permiso;
  }

  /// HOOKS

  async ngOnInit(): Promise<void> {
    this.setPermisos();
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      this.idGrupo = params.get('grupo');
      this.suscribeFiltroGlobal();
      await Promise.all([this.listar()]);
    });
  }

  async ngOnDestroy(): Promise<void> {
    this.puntos$?.unsubscribe();
    this.filtroGlobal$?.unsubscribe();
  }
}
