export interface IChangeLogNUC {
  version?: string;
  descripcion?: string[];
}

export const CHANGELOG_NUC: IChangeLogNUC[] = [
  {
    version: 'v13',
    descripcion: [
      'Primera versión con todos los cambios de las UN implementadas',
      'Quitada la funcionalidad de consola local',
    ],
  },
  {
    version: 'v14',
    descripcion: [
      'Implementación de Corus 0.75',
      'Optimizaciones de rutinas de 4G',
      'Verificación de CRC en Dresser 2',
      'Correcciones menores en rutinas de armado de registros',
    ],
  },
  {
    version: 'v15',
    descripcion: [
      'Soporte para hardware v2',
      'Configuración de NUC remoto por medio de plataforma (frecuencia de reporte y hora de inicio)',
    ],
  },
  {
    version: 'v16',
    descripcion: [
      'Implementación de UCV Minicor',
      'Implementación de Dresser 2 1.87F',
    ],
  },
  {
    version: 'v17',
    descripcion: ['Implementación de indicaciones LED en hardware v2'],
  },
  {
    version: 'v18',
    descripcion: [
      'Chequeo de integridad de memoria flash de configuraciones de NUC, se borran las configuraciones corruptas',
      'Valores de caudal de Dresser 2',
      'Lectura de nivel de batería en hardware v2',
    ],
  },
  {
    version: 'v19',
    descripcion: [
      'Valores totalizados en Proser',
      'Corrección de compatibilidad con Dresser 2 v1.87',
    ],
  },
  {
    version: 'v20',
    descripcion: [
      'Se agregaron controles para que la falla de conexión a internet (ya sea por DNS o por problemas de SIM) no reinicie la placa y se duerma',
      'Se agregó un feedback visual de LED de 4G para el caso de falla de conexión (parpadeo de LED por 10 segundos)',
    ],
  },
  {
    version: 'v21',
    descripcion: ['Corrección de error en reporte totalizado en Proser'],
  },
  {
    version: 'v22',
    descripcion: [
      'Se arregló el valor de caudal para Corus',
      'Se arregló un overflow de memoria de registros para Dresser v1.87',
    ],
  },
  {
    version: 'v23',
    descripcion: [
      'Se agregó la correctora American Meter',
      'Se agregó la versión 1.86f para Dresser 2',
      'Optimización de comunicación LTE en desconexión con correctora',
    ],
  },
  {
    version: 'v24',
    descripcion: [
      'Se corrigieron errores en el proceso de datos de Minicor',
      'Se agregó el uso de input pulse weight al Minicor',
      'Se mejoró el recupero de mensajes en American Meter',
    ],
  },
  {
    version: 'v25',
    descripcion: [
      'Se corrigieron errores en el proceso de obtener registros de American Meter',
      'Se corrigió el handshake para distintas versiones del American Meter',
    ],
  },
  {
    version: 'v26',
    descripcion: [
      'Se agregó la versión 1.91 para Dresser 2',
      'Se agregó la versión 1.94 para Dresser 2',
      'Se corrigió la respuesta de registro inexistente para Dresser 2',
    ],
  },
  {
    version: 'v27',
    descripcion: ['Se corrigió compatibilidad con Minicor con firmware v2.00'],
  },
  {
    version: 'v28',
    descripcion: ['Se agregó compatibilidad con correctora Elgas Elcor 1.16'],
  },
  {
    version: 'v29',
    descripcion: ['Se corrigió leak de memoria en la comunicación a internet mejorando el rendimiento'],
  },
  {
    version: 'v30',
    descripcion: ['Se agregaron datos de la SIM card en el reporte de configuración'],
  },
  {
    version: 'v31',
    descripcion: ['Se implementó la actualización de firmware remota'],
  },
  {
    version: 'v32',
    descripcion: [
      'Se agregó compatibilidad con correctora Instromet 555',
      'Arreglos menores en rendimiento de actualización de firmware remota',
    ],
  },
  {
    version: 'v33',
    descripcion: ['Se agregó sincronización de hora de las correctoras',
      'Arreglos menores en la actualización de firmware remota', 
      'Arreglos menores generales en proceso de comunicación con todas las correctoras',
    ],
  },
];
