import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { PuntosRoutingModule } from './routing';
import { CrearEditarPuntosMedicionComponent } from './crear-editar-puntos-medicion/crear-editar-puntos-medicion.component';
import { ListadoPuntosMedicionComponent } from './listado-puntos-medicion/listado-puntos-medicion.component';
import { GeocodeService } from 'src/app/auxiliares/geocode.service';
import { DetallesPuntosMedicionComponent } from './detalles-puntos-medicion/detalles-puntos-medicion.component';
import { IconoEstadoComponent } from '../../standalone/icono-estado/icono-estado.component';
import { ResumenPuntoMedicionComponent } from '../../standalone/resumen-punto-medicion/resumen-punto-medicion.component';
import { UltimoRegistroNucComponent } from '../../standalone/ultimo-registro-nuc/ultimo-registro-nuc.component';
import { UltimoReporteUnidadPresionComponent } from '../../standalone/ultimo-reporte-unidad-presion/ultimo-reporte-unidad-presion.component';
import { DetallesNucComponent } from '../../standalone/detalles-nuc/detalles-nuc.component';
import { DetallesNSPComponent } from '../../standalone/detalles-nsp/detalles-nsp.component';
import { DetallesVeriboxComponent } from '../../standalone/detalles-veribox/detalles-veribox.component';
import { UltimaCromatografiaComponent } from '../../standalone/ultima-cromatografia/ultima-cromatografia.component';
import { GraficoCorrectoraComponent } from '../../standalone/grafico-correctora/grafico-correctora.component';
import { ListadoRegistrosComponent } from '../../standalone/listado-registros/listado-registros.component';
import { ListadoAlertasComponent } from '../../standalone/listado-alertas/listado-alertas.component';
import { ListadoRegistrosFaltantesComponent } from '../../standalone/listado-registros-faltantes/listado-registros-faltantes.component';
import { ListadoReportesComponent } from '../../standalone/listado-reportes/listado-reportes.component';
import { ListadoReportesFaltantesComponent } from '../../standalone/listado-reportes-faltantes/listado-reportes-faltantes.component';
import { GraficoMinMaxComponent } from '../../standalone/grafico-min-max/grafico-min-max.component';
import { GraficoMinMaxPromComponent } from '../../standalone/grafico-min-max-prom/grafico-min-max-prom.component';
import { GraficoAlertasComponent } from '../../standalone/grafico-alertas/grafico-alertas.component';
import { GraficoPresionActualComponent } from '../../standalone/grafico-presion-actual/grafico-presion-actual.component';
import { DetallesCorrectoraComponent } from '../../standalone/detalles-correctora/detalles-correctora.component';
import { DetallesUnidadPresionComponent } from '../../standalone/detalles-unidad-presion/detalles-unidad-presion.component';
import { ListadoAuditoriasVeriboxComponent } from '../../standalone/listado-auditorias-veribox/listado-auditorias-veribox.component';
import { ListadoMantenimientosComponent } from 'src/app/standalone/mantenimientos/listado-mantenimientos/listado-mantenimientos.component';
import { DetallesMedidorResidencialComponent } from '../../standalone/detalles-medidor-residencial/detalles-medidor-residencial.component';
import { UltimoReporteMedidorResidencialComponent } from '../../standalone/ultimo-reporte-medidor-residencial/ultimo-reporte-medidor-residencial.component';
import { DetallesSMLComponent } from '../../standalone/detalles-sml/detalles-sml.component';
import { GraficoMedidorResidencialComponent } from '../../standalone/grafico-medidor-residencial/grafico-medidor-residencial.component';
import { ListadoReportesMedidorResidencialComponent } from '../../standalone/listado-reportes-medidor-residencial/listado-reportes-medidor-residencial.component';
import { ConsumoResidencialComponent } from '../../standalone/consumo-residencial/consumo-residencial.component';
import { ListadoLogReportesVeriboxComponent } from '../../standalone/listado-log-reportes-veribox/listado-log-reportes-veribox.component';
import { ListadoLogTwilioComponent } from '../../standalone/listado-log-twilio/listado-log-twilio.component';

@NgModule({
  declarations: [
    DetallesPuntosMedicionComponent,
    CrearEditarPuntosMedicionComponent,
    ListadoPuntosMedicionComponent,
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    PuntosRoutingModule,
    IconoEstadoComponent,
    ResumenPuntoMedicionComponent,
    IconoEstadoComponent,
    UltimoRegistroNucComponent,
    UltimoReporteUnidadPresionComponent,
    DetallesNucComponent,
    DetallesNSPComponent,
    DetallesVeriboxComponent,
    UltimaCromatografiaComponent,
    GraficoCorrectoraComponent,
    ListadoRegistrosComponent,
    ListadoAlertasComponent,
    ListadoRegistrosFaltantesComponent,
    ListadoReportesComponent,
    ListadoReportesFaltantesComponent,
    GraficoMinMaxComponent,
    GraficoMinMaxPromComponent,
    GraficoAlertasComponent,
    GraficoPresionActualComponent,
    DetallesCorrectoraComponent,
    DetallesUnidadPresionComponent,
    ListadoAuditoriasVeriboxComponent,
    ListadoMantenimientosComponent,
    DetallesMedidorResidencialComponent,
    UltimoReporteMedidorResidencialComponent,
    DetallesSMLComponent,
    GraficoMedidorResidencialComponent,
    ListadoReportesMedidorResidencialComponent,
    ConsumoResidencialComponent,
    ListadoLogReportesVeriboxComponent,
    ListadoLogTwilioComponent,
  ],
  providers: [GeocodeService],
})
export class PuntosDeMedicionModule {}
