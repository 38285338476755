<div class="column-center" style="overflow: hidden">
  <div class="filtro-container align-end" style="overflow: auto">
    <!-- Filtro -->
    <app-filtro
      style="width: 100%"
      [search]="search"
      [(query)]="query"
      (queryChange)="paginationChange()"
    >
    </app-filtro>
    @if (puedeCrear) {
      <img
        src="assets/iconos/mas.png"
        alt="crear"
        style="width: 59px; cursor: pointer"
        [matTooltip]="'Crear Punto'"
        (click)="crear()"
      />
    }
  </div>
  <div class="row-center" style="width: 100%; gap: 8px">
    <button
      mat-raised-button
      style="width: 100%"
      matTooltip="Volver"
      (click)="volver()"
    >
      <div class="row-center" style="gap: 5px">
        <span>{{ nombreGrupo }}</span>
        <mat-icon>keyboard_return</mat-icon>
      </div>
    </button>
  </div>
  <br />
  <mat-accordion class="example-headers-align" multi>
    @for (p of puntos; track $index) {
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            @if (helper.esAdminGlobal()) {
              @if (p.posicion) {
                <app-posicion [punto]="p"></app-posicion>
              } @else {
                <app-posicion [punto]="p" [index]="$index"></app-posicion>
              }
            }
            {{ p.nombre }}
          </mat-panel-title>
          <mat-panel-description>
            {{ p.unidadNegocio?.nombre }} -
            {{ p.localidad2?.nombre || "Sin Localidad" }}
            <div class="row-center" style="gap: 3px">
              <img
                src="assets/iconos/buscar.png"
                alt="buscar"
                matTooltip="Detalles"
                class="icono-punto"
                (click)="detalles(p); $event.stopPropagation()"
              />
              @if (puedeEditar) {
                <img
                  src="assets/iconos/editar.png"
                  alt="editar"
                  matTooltip="Editar"
                  class="icono-punto"
                  (click)="editar(p); $event.stopPropagation()"
                />
              }
              <img
                src="assets/iconos/directions.png"
                alt="mapa"
                matTooltip="Abrir en Maps"
                class="icono-punto"
                (click)="abrirEnBrowser(p); $event.stopPropagation()"
              />
              @if (puedeEliminar) {
                <img
                  src="assets/iconos/eliminar.png"
                  alt="eliminar"
                  matTooltip="Eliminar"
                  class="icono-punto"
                  (click)="eliminar(p); $event.stopPropagation()"
                />
              }
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <!-- CONTENIDO: VOY A USAR UN LAYOUT. MATERIAL SOS CACA -->
        <!-- Este son los encabezados -->
        <app-scada-simple></app-scada-simple>
        <br />
        <!-- Estos son los SCADAS -->
        @for (s of p.scadas; track $index) {
          <app-scada-simple
            [scada]="s"
            [configDispositivo]="getConfig(s)"
            [boleano]="s.booleano!"
          ></app-scada-simple>
        }
      </mat-expansion-panel>
    }
  </mat-accordion>
  <!-- <div class="paginator-container fondo-85">
    <app-paginator
      #botPaginator
      [saveName]="'PuntosScada'"
      [length]="totalCount"
      [pageIndex]="+(query.page || 0)"
      [pageSizeOptions]="helper.pageSizeOptions"
      [showFirstLastButtons]="true"
      [(query)]="query"
      (queryChange)="paginationChange()"
    ></app-paginator>
  </div> -->
</div>
