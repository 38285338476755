<div style="height: 100%; display: flex; flex-direction: column">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="titulo" fxLayout="row" fxLayoutAlign="space-between center">
      <h1
        style="
          font-size: 18px;
          font-weight: 900;
          padding: 0.5em 0 0.5em 0.5em;
          margin: 0;
        "
      >
        Último Valor
      </h1>
    </div>
  </div>
  <mat-tab-group>
    @for (s of scadas; track $index) {
      <mat-tab [label]="s.tag!">
        @if (s.booleano) {
          <app-grafico-valor-actual-scada-boolean
            [scada]="s"
          ></app-grafico-valor-actual-scada-boolean>
        } @else {
          <app-grafico-valor-actual-scada
            [scada]="s"
          ></app-grafico-valor-actual-scada>
        }
      </mat-tab>
    }
  </mat-tab-group>
</div>
