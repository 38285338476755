import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Division,
  ICentroOperativo,
  ICreateGrupo,
  IGrupo,
  IListado,
  IQueryParam,
  IUnidadNegocio,
  IUpdateGrupo,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { GruposService } from '../grupos.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';

@Component({
  selector: 'app-crear-editar-grupos',
  templateUrl: './crear-editar-grupos.component.html',
  styleUrls: ['./crear-editar-grupos.component.scss'],
})
export class CrearEditarGruposComponent implements OnInit, OnDestroy {
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;
  public enviando = false;

  public centroOperativoObligatorio = false;

  public unidadNegocios: IUnidadNegocio[] = [];
  public centroOperativos: ICentroOperativo[] = [];
  public divisiones: Division[] = [];

  // Listado Continuo
  public unidadNegocios$?: Subscription;
  public centroOperativos$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IGrupo,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarGruposComponent>,
    private service: GruposService,
    private listadosService: ListadosService,
    private helper: HelperService,
    public loading: LoadingService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Grupo' : 'Crear Grupo';

    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      idUnidadNegocio: [this.data?.idUnidadNegocio, Validators.required],
      idCentroOperativo: [
        this.data?.idCentroOperativo,
        this.centroOperativoObligatorio ? Validators.required : null,
      ],
      division: [this.data?.division, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public mostrarCentroOperativo(centro: ICentroOperativo) {
    const idUnidadNegocio = this.form?.get('idUnidadNegocio')?.value;
    return idUnidadNegocio === centro.idUnidadNegocio;
  }

  //

  private getCreateData() {
    const data: ICreateGrupo = this.form?.value;
    return data;
  }

  private getUpdateData() {
    const data: IUpdateGrupo = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    try {
      this.enviando = true;
      if (this.data?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.enviando = false;
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
  }

  //

  private setDivisiones() {
    if (this.helper.puedeVerCorrectoras()) this.divisiones.push('Correctoras');
    if (this.helper.puedeVerUnidadPresion()) this.divisiones.push('Presión');
    if (this.helper.puedeVerResidencial()) this.divisiones.push('Residencial');
    if (this.helper.puedeVerScadaUnifilares())
      this.divisiones.push('SCADA Unifilares');
    if (this.helper.puedeVerScadaMediciones())
      this.divisiones.push('SCADA Mediciones');

    if (this.divisiones.length === 1) {
      this.form?.patchValue({
        division: this.divisiones[0],
      });
    }
  }

  // Listados

  private async listarUnidadNegocios(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.unidadNegocios$?.unsubscribe();
    this.unidadNegocios$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadNegocios = data.datos;
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listadosService.getLastValue('unidadNegocios', query);
  }

  private async listarCentroOperativos(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
    };
    this.centroOperativos$?.unsubscribe();
    this.centroOperativos$ = this.listadosService
      .subscribe<IListado<IUnidadNegocio>>('centroOperativos', query)
      .subscribe((data) => {
        this.centroOperativos = data.datos;
        console.log(`listado de centroOperativos`, data);
      });
    await this.listadosService.getLastValue('centroOperativos', query);
  }

  private setCentroOperativoObligatorio() {
    const adminGlobal = HelperService.adminGlobal();
    const adminUN = HelperService.rolesEnCualquierUnidadNegocio([
      'Administrador',
    ]);

    // El centro operativo es obligatorio si no es admin global ni admin de UN
    this.centroOperativoObligatorio = !adminGlobal && !adminUN;
  }

  async ngOnInit(): Promise<void> {
    this.setCentroOperativoObligatorio();

    this.createForm();
    this.setDivisiones();

    await Promise.all([
      this.listarUnidadNegocios(),
      this.listarCentroOperativos(),
    ]);
  }

  ngOnDestroy(): void {
    this.unidadNegocios$?.unsubscribe();
  }
}
