import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IConfigDispositivoNUC4G,
  ICorrectora,
  ICreateConfigDispositivo,
  IDispositivo,
  IFilter,
  IListado,
  IMantenimiento,
  IPuntoMedicion,
  IQueryParam,
} from 'modelos/src';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { CorrectorasService } from 'src/app/modulos/correctoras/correctoras.service';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { CrearEditarCorrectorasComponent } from '../../modulos/correctoras/crear-editar-correctoras/crear-editar-correctoras.component';
import { CrearEditarMantenimientoComponent } from '../mantenimientos/crear-editar-mantenimiento/crear-editar-mantenimiento.component';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { ConfigDispositivosService } from '../../modulos/dispositivos/config-dispositivos.service';
import { DialogService } from '../../auxiliares/dialog/dialog.service';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-detalles-correctora',
  templateUrl: './detalles-correctora.component.html',
  styleUrls: ['./detalles-correctora.component.scss'],
})
export class DetallesCorrectoraComponent implements OnInit {
  @Input() puntoMedicion?: IPuntoMedicion;
  @Input() correctora?: ICorrectora;
  @Input() dispositivo?: IDispositivo;

  public mantenimiento?: IMantenimiento;
  public mantenimientos$?: Subscription;

  public mostrarSyncHora?: boolean;

  constructor(
    public helper: HelperService,
    private matDialog: MatDialog,
    public service: CorrectorasService,
    private listados: ListadosService,
    private dialogService: DialogService,
    private configDispositivosService: ConfigDispositivosService,
  ) {}

  public async editarCorrectora(data?: ICorrectora) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarCorrectorasComponent, config);
  }

  public async crearMantenimiento(data?: ICorrectora) {
    const config: MatDialogConfig = {
      data: { correctora: data },
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarMantenimientoComponent, config);
  }

  public async listarUltimoMantenimiento() {
    const filter: IFilter<IMantenimiento> = {
      idAsignado: this.correctora?._id,
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      limit: 1,
      sort: '-fechaCreacion',
    };
    this.mantenimientos$?.unsubscribe();
    this.mantenimientos$ = this.listados
      .subscribe<IListado<IMantenimiento>>('mantenimientos', query)
      .subscribe((data) => {
        this.mantenimiento = data.datos[0];
        console.debug('listado de mantenimientos', data);
      });
    await this.listados.getLastValue('mantenimientos', query);
  }

  public async syncHora() {
    const confirm = await this.dialogService.confirm(
      'Sincronizar hora',
      '¿Activar sincronización horaria? En el siguiente reporte de la correctora sincronizara la hora.',
    );

    if (confirm) {
      const config: IConfigDispositivoNUC4G = {
        syncHora: true,
      };
      const configDisp: ICreateConfigDispositivo = {
        deveui: this.correctora!.deveui!,
        config,
      };
      await firstValueFrom(this.configDispositivosService.upsert(configDisp));
    }
  }

  async ngOnInit(): Promise<void> {
    const modelosConSync: string[] = [
      'Corus',
      'Dresser1',
      'Dresser2',
      'Proser',
      'Minicor',
      'AmericanMeter',
      'Elcor',
      'Instromet',
    ];
    this.mostrarSyncHora = modelosConSync.includes(
      this.correctora?.modelo || '',
    );
    await this.listarUltimoMantenimiento();
  }
}
