import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  IAlerta,
  IConfigDispositivoScada,
  ICorrectora,
  IFilter,
  IListado,
  IPuntoMedicion,
  IQueryParam,
  IReporteScada,
  IScada,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { NOMBRE_ALERTAS_NUC } from '../../../../environments/datos';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { AlertasService } from '../../correctoras/alertas.service';
import { CorrectorasService } from '../../correctoras/correctoras.service';
import { RegistrosService } from '../../correctoras/registros.service';
import { UnidadPresionsService } from '../../unidad-presion/service';
import { IRangePreset } from 'src/app/auxiliares/datetime-range-picker/component';
import { CrearEditarPuntosMedicionComponent } from '../../puntos-medicion/crear-editar-puntos-medicion/crear-editar-puntos-medicion.component';

export enum EstadoCorrectora {
  'Sin Correctora' = 1,
  'OK' = 2,
  'Error' = 3,
}

@Component({
  selector: 'app-detalles-puntos-scada',
  templateUrl: './detalles-puntos-scada.component.html',
  styleUrls: ['./detalles-puntos-scada.component.scss'],
})
export class DetallesPuntosScadaComponent implements OnInit, OnDestroy {
  public nombreAlertas = NOMBRE_ALERTAS_NUC;

  public range?: { from: string; to: string };
  public presets?: IRangePreset[] = [];

  public range15Dias: IRangePreset = {
    from: this.helper.fechaHaceXDias(15).toISOString(),
    to: this.helper.fechaHaceXDias(-1).toISOString(),
  };

  public id?: string | null;
  public puntoMedicion?: IPuntoMedicion;
  public configDeseadaScada?: IConfigDispositivoScada;
  public fechaCreacion?: string;

  public scadas?: IScada[];

  public ultimoReporteScada?: IReporteScada;
  public ultimaAlerta?: IAlerta;

  // Listado Continuo
  public puntoMedicion$?: Subscription;
  public scadas$?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private listadosService: ListadosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    public registrosService: RegistrosService,
    public alertasService: AlertasService,
    public correctorasService: CorrectorasService,
    public presionesService: UnidadPresionsService,
  ) {}

  public async editar(data?: IPuntoMedicion) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarPuntosMedicionComponent, config);
  }

  public onRangeChange(range: { from: string; to: string }) {
    this.range = range;
  }

  //

  public colorDeBateria(bateria: number) {
    if (bateria) {
      if (bateria / 100 < 6 && bateria / 100 > 4) {
        return '#c75150';
      } else if (bateria / 100 >= 6 && bateria / 100 <= 7) {
        return '#fbfb98';
      } else if (bateria / 100 > 7 && bateria / 100 < 10) {
        return '#98fb98';
      } else {
        return '#d3d3d3';
      }
    } else {
      return '#d3d3d3';
    }
  }

  public textoBateria(correctora: ICorrectora): string {
    if (correctora.bateria || correctora.bateria === 0) {
      if (correctora.modelo === 'Mercury') {
        const calculada = correctora.bateria / 100;
        return `${calculada}v`;
      }
    }
    return 'Sin Reportar';
  }

  private rangoInicial() {
    this.range = {
      from: this.helper.fechaHaceXAnios(1).toISOString(),
      to: this.helper.fechaHaceXDias(-1).toISOString(),
    };
  }

  private setPresets() {
    this.presets = HelperService.presetUnidadesPresion;
  }

  // Listados

  private async listarScadas(): Promise<void> {
    const ids = this.puntoMedicion?.idsScada;
    if (!ids) {
      return;
    }
    const filter: IFilter<IScada> = {
      _id: { $in: ids },
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: 'tag',
    };
    this.scadas$?.unsubscribe();
    this.scadas$ = this.listadosService
      .subscribe<IListado<IScada>>('scadas', query)
      .subscribe((data) => {
        this.scadas = data.datos;
        console.log(`listado de scadas`, data);
      });
    await this.listadosService.getLastValue('scadas', query);
  }

  private async listarPuntoMedicion(): Promise<void> {
    this.puntoMedicion$?.unsubscribe();
    if (this.id) {
      this.puntoMedicion$ = this.listadosService
        .subscribe<IPuntoMedicion>('puntoMedicion', this.id)
        .subscribe(async (data) => {
          this.puntoMedicion = data;
          console.log(`listado de puntoMedicion`, data);
        });
      await this.listadosService.getLastValue('puntoMedicion', this.id);
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      this.id = params.get('id');
      await this.listarPuntoMedicion();
      this.rangoInicial();
      await Promise.all([this.listarScadas()]);
      this.setPresets();
    });
  }

  ngOnDestroy(): void {
    this.puntoMedicion$?.unsubscribe();
    this.scadas$?.unsubscribe();
  }
}
