import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ICentroOperativo,
  IConfigNotificacion,
  ICreateConfigNotificacion,
  IFilter,
  IListado,
  ILocalidad,
  IQueryParam,
  IScada,
  IUnidadNegocio,
  IUsuario,
  TipoAlerta,
  TipoEnvio,
} from 'modelos/src';
import { firstValueFrom, Subscription } from 'rxjs';
import { AuxiliaresModule } from 'src/app/auxiliares/auxiliares.module';
import { DialogService } from 'src/app/auxiliares/dialog/dialog.service';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { ConfigNotificacionsService } from '../service';

export interface UsuarioCompleto extends IUsuario {
  localidad?: string;
  centroOperativo?: string;
  unidadNegocio?: string;
}

export interface DataCreateEditNotificacionScada {
  config?: IConfigNotificacion;
  scada?: IScada;
  nombre?: string;
}

@Component({
  selector: 'app-crear-editar-notificaciones-scada',
  templateUrl: './crear-editar-notificaciones-scada.component.html',
  styleUrls: ['./crear-editar-notificaciones-scada.component.scss'],
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
})
export class CrearEditarNotificacionScadaComponent
  implements OnInit, OnDestroy
{
  public form?: FormGroup;
  public title?: string;

  public usuarios: UsuarioCompleto[] = [];
  private usuarios$?: Subscription;

  private unidadesNegocios: IUnidadNegocio[] = [];
  private unidadesNegocios$?: Subscription;

  private localidades: ILocalidad[] = [];
  private localidades$?: Subscription;

  private centrosOperativos: ICentroOperativo[] = [];
  private centrosOperativos$?: Subscription;

  public tiposAlertas: TipoAlerta[] = [
    'SCADA - Cambio de límite',
    'SCADA - Fuera de límite',
  ];
  public tiposEnvio: TipoEnvio[] = [
    'SMS',
    'WhatsApp',
    'Llamada',
    'Notificacion Push',
  ];
  public tiposSeleccionados: TipoEnvio[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DataCreateEditNotificacionScada,
    private dialogRef: MatDialogRef<CrearEditarNotificacionScadaComponent>,
    private listados: ListadosService,
    private dialogService: DialogService,
    private helper: HelperService,
    private service: ConfigNotificacionsService,
  ) {}

  private createForm() {
    this.title = this.data.config
      ? `Editar Notificación - ${this.data?.nombre}`
      : `Crear Notificación - ${this.data?.nombre}`;
    this.form = new FormGroup({
      tipoEnvio: new FormControl(this.data.config?.tipoEnvio),
      tipoAlerta: new FormControl(this.data.config?.tipoAlerta, [
        Validators.required,
      ]),
      idsUsuarios: new FormControl(this.data.config?.idsUsuarios, [
        Validators.required,
      ]),
      tag: new FormControl(this.data.scada?.tag || this.data.config?.tag, [
        Validators.required,
      ]),
    });
  }

  private async listarUsuarios(): Promise<void> {
    const filter: IFilter<IUsuario> = {
      activo: true,
      'datosPersonales.telefono': { $nin: [null, ''], $exists: true },
      $or: [
        {
          $and: [
            {
              'permisos.division':
                this.data?.scada?.division === 'Medición'
                  ? 'SCADA Mediciones'
                  : 'SCADA Unifilares',
            },
            { 'permisos.idsUnidadNegocios': this.data?.scada?.idUnidadNegocio },
          ],
        },
        {
          'permisos.nivel': 'Global',
        },
      ],
    } as any;
    const query: IQueryParam = {
      select: 'username datosPersonales permisos',
      filter: JSON.stringify(filter),
      limit: 0,
      sort: 'datosPersonales.nombre',
    };
    this.usuarios$?.unsubscribe();
    this.usuarios$ = this.listados
      .subscribe<IListado<IUsuario>>('usuarios', query)
      .subscribe((data) => {
        this.usuarios = data.datos;
        this.completarUsuarios();
        console.log(`listado de usuarios`, data);
      });
    await this.listados.getLastValue('usuarios', query);
  }

  private async listarUnidadesNegocios(): Promise<void> {
    // Filtro
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify({}),
    };

    // Listado
    this.unidadesNegocios$?.unsubscribe();
    this.unidadesNegocios$ = this.listados
      .subscribe<IListado<ICentroOperativo>>('unidadNegocios', query)
      .subscribe((data) => {
        this.unidadesNegocios = data.datos;
        console.log(`listado de unidadNegocios`, data);
      });
    await this.listados.getLastValue('unidadNegocios', query);
  }

  private async listarCentrosOperativos(): Promise<void> {
    // Filtro
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify({}),
    };

    // Listado
    this.centrosOperativos$?.unsubscribe();
    this.centrosOperativos$ = this.listados
      .subscribe<IListado<ICentroOperativo>>('centroOperativos', query)
      .subscribe((data) => {
        this.centrosOperativos = data.datos;
        console.log(`listado de centroOperativos`, data);
      });
    await this.listados.getLastValue('centroOperativos', query);
  }

  public async listarLocalidades(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      filter: JSON.stringify({}),
    };
    this.localidades$?.unsubscribe();
    this.localidades$ = this.listados
      .subscribe<IListado<ILocalidad>>('localidads', query)
      .subscribe((data) => {
        this.localidades = data.datos;
        console.log(`listado de localidads`, data);
      });
    await this.listados.getLastValue('localidads', query);
  }

  private completarUsuarios(): void {
    const usuariosCompletos: UsuarioCompleto[] = [];
    for (const u of this.usuarios) {
      const usuarioCompleto = u;
      const idsLocalidades = u.permisos?.map((p) => p.idsLocalidades).flat();
      if (idsLocalidades) {
        const localidades = this.localidades.filter((l) =>
          idsLocalidades.includes(l._id!),
        );

        usuarioCompleto.localidad = localidades.map((l) => l.nombre).join(', ');
      }
      const idsCentrosOperativos = u.permisos
        ?.map((p) => p.idsCentroOperativos)
        .flat();
      if (idsCentrosOperativos) {
        const centrosOperativos = this.centrosOperativos.filter((c) =>
          idsCentrosOperativos.includes(c._id!),
        );

        usuarioCompleto.centroOperativo = centrosOperativos
          .map((c) => c.nombre)
          .join(', ');
      }
      const idsUnidadesNegocios = u.permisos
        ?.map((p) => p.idsUnidadNegocios)
        .flat();
      if (idsUnidadesNegocios) {
        const unidadesNegocios = this.unidadesNegocios.filter((u) =>
          idsUnidadesNegocios.includes(u._id!),
        );
        usuarioCompleto.unidadNegocio = unidadesNegocios
          .map((u) => u.nombre)
          .join(', ');
      }
      usuariosCompletos.push(usuarioCompleto);
    }
    this.usuarios = usuariosCompletos;
  }

  public searchUsuario = (term: string, item: UsuarioCompleto) => {
    if (item.username?.toLowerCase().includes(term.toLowerCase())) return true;
    if (item.localidad?.toLowerCase().includes(term.toLowerCase())) return true;
    if (item.centroOperativo?.toLowerCase().includes(term.toLowerCase()))
      return true;
    if (item.unidadNegocio?.toLowerCase().includes(term.toLowerCase()))
      return true;
    if (
      item.datosPersonales?.nombre?.toLowerCase().includes(term.toLowerCase())
    )
      return true;
    if (
      item.datosPersonales?.telefono?.toLowerCase().includes(term.toLowerCase())
    )
      return true;
    return false;
  };

  private getData() {
    const data = this.form?.value as ICreateConfigNotificacion;
    const envios: ICreateConfigNotificacion[] = [];
    if (!this.tiposSeleccionados.length) {
      this.helper.notifError('Debe seleccionar al menos un tipo de envío');
      return [];
    }
    for (const tipoEnvio of this.tiposSeleccionados) {
      const envio = { ...data, tipoEnvio };
      envios.push(envio);
    }
    return envios;
  }

  public removeUsuario(id: string) {
    const idsUsuarios = this.form?.get('idsUsuarios')?.value as string[];
    const newIdsUsuarios = idsUsuarios.filter((i) => i !== id);
    this.form?.get('idsUsuarios')?.setValue(newIdsUsuarios);
  }

  /// Acciones
  public async onSubmit() {
    const res = await this.dialogService.confirm(
      'Crear notificación',
      '¿Está seguro de crear la notificación?',
    );
    if (!res) return;
    try {
      const data = this.getData();
      if (!data.length) return;
      if (this.data.config) {
        // Update
        // Junto las promesas para enviarlas todas
        const promesas = data.map((d) => {
          return firstValueFrom(
            this.service.editar(this.data!.config!._id!, d),
          );
        });
        await Promise.all(promesas);
        this.dialogRef.close();
      } else {
        // Create
        // Junto las promesas para enviarlas todas
        const promesas = data.map((d) => {
          return firstValueFrom(this.service.crear(d));
        });
        await Promise.all(promesas);
        this.helper.notifSuccess('Notificación creada');
        this.dialogRef.close();
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError('Error al crear la notificación');
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  public onSelectUsuario(usuario: UsuarioCompleto): void {
    console.log(this.form?.value);
    console.log(usuario);
  }

  /// HOOKS

  public async ngOnInit() {
    console.log('SCADA', this.data);
    this.tiposSeleccionados = this.data?.config?.tipoEnvio
      ? [this.data?.config?.tipoEnvio]
      : [];
    this.createForm();
    // Listo los datos primero para compleatar los usuarios con su coso mágico
    await Promise.all([
      this.listarUnidadesNegocios(),
      this.listarLocalidades(),
      this.listarCentrosOperativos(),
    ]);
    // Completo los usuarios con su localidad, centro operativo y unidad de negocio
    await this.listarUsuarios();
  }

  public ngOnDestroy() {
    this.usuarios$?.unsubscribe();
    this.unidadesNegocios$?.unsubscribe();
    this.localidades$?.unsubscribe();
    this.centrosOperativos$?.unsubscribe();
  }
}
