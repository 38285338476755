/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import {
  IListado,
  IUsuario,
  IQueryParam,
  ISocketMessage,
  IDispositivo,
  IUnidadNegocio,
  ICuenca,
  ICorrectora,
  ICromatografia,
  IRegistro,
  IMedidorResidencial,
  IAlerta,
  IAplicacionCromatografia,
  ICentroOperativo,
  IGrupo,
  INotificacion,
  IMedidorResidencialAgua,
  IConfigDispositivo,
  IPuntoMedicion,
  IUnidadPresion,
  IReporte,
  IEnvioSms,
  IAuditoriaVeribox,
  IAgrupacion,
  IMantenimiento,
  IResumenReporte,
  ILocalidad,
  ILogReporte,
  ILogTwilio,
  ILogNuc,
  IScada,
  IConfigNotificacion,
} from 'modelos/src';
import { UsuariosService } from '../modulos/usuarios/usuarios.service';
import { WebSocketService } from './websocket';
import { DispositivosService } from '../modulos/dispositivos/dispositivos.service';
import { UnidadNegociosService } from '../modulos/unidad-negocios/unidad-negocios.service';
import { CuencasService } from '../modulos/cuencas/cuencas.service';
import { CorrectorasService } from '../modulos/correctoras/correctoras.service';
import { CromatografiasService } from '../modulos/cromatografias/cromatografias.service';
import { RegistrosService } from '../modulos/correctoras/registros.service';
import { MedidorResidencialsService } from '../modulos/medidores-residenciales/medidores-residenciales.service';
import { AlertasService } from '../modulos/correctoras/alertas.service';
import { AplicacionCromatografiasService } from '../modulos/correctoras/aplicacionCromatografias';
import { CentroOperativosService } from '../modulos/centroOperativos/centroOperativos.service';
import { GruposService } from '../modulos/grupos/grupos.service';
import { IAuditoria } from 'modelos/src';
import { AuditoriaService } from '../modulos/auditorias/auditorias.service';
import { NotificacionesService } from '../modulos/notificaciones/service';
import { MedidorResidencialesAguaService } from '../modulos/medidores-residenciales-agua/medidores-residenciales-agua.service';
import { ConfigDispositivosService } from '../modulos/dispositivos/config-dispositivos.service';
import { PuntosMedicionService } from '../modulos/puntos-medicion/service';
import { UnidadPresionsService } from '../modulos/unidad-presion/service';
import { ReportesService } from '../modulos/reportes/service';
import { EnvioSmssService } from '../modulos/sms/service';
import { AuditoriaVertiboxService } from '../modulos/unidad-presion/auditorias-veribox.service';
import { AgrupacionsService } from '../modulos/agrupacion/agrupacion.service';
import { MantenimientosService } from '../standalone/mantenimientos/mantenimientos.service';
import { ResumenReportesService } from '../modulos/reportes/resumen-reportes';
import { LocalidadService } from '../modulos/localidad/service';
import { LogReportesService } from '../modulos/reportes/log-reportes.service';
import { LogTwiliosService } from '../modulos/reportes/log-twilio.service';
import { LogNucService } from '../modulos/logNuc/logNuc.service';
import { ScadasService } from '../modulos/scadas/service';
import { ConfigNotificacionsService } from '../modulos/notificaciones-scada/service';

type Tipo =
  | IUsuario
  | IListado<IUsuario>
  | IAuditoria
  | IListado<IAuditoria>
  | IDispositivo
  | IListado<IDispositivo>
  | IUnidadNegocio
  | IListado<IUnidadNegocio>
  | ICuenca
  | IListado<ICuenca>
  | ICorrectora
  | IListado<ICorrectora>
  | ICromatografia
  | IListado<ICromatografia>
  | IRegistro
  | IListado<IRegistro>
  | IMedidorResidencial
  | IListado<IMedidorResidencial>
  | IMedidorResidencialAgua
  | IListado<IMedidorResidencialAgua>
  | IAlerta
  | IListado<IAlerta>
  | IAplicacionCromatografia
  | IListado<IAplicacionCromatografia>
  | ICentroOperativo
  | IListado<ICentroOperativo>
  | IGrupo
  | IListado<IGrupo>
  | IAgrupacion
  | IListado<IAgrupacion>
  | IListado<INotificacion>
  | IListado<IConfigDispositivo>
  | IConfigDispositivo
  | IPuntoMedicion
  | IListado<IPuntoMedicion>
  | IUnidadPresion
  | IListado<IUnidadPresion>
  | IReporte
  | IListado<IReporte>
  | IEnvioSms
  | IListado<IEnvioSms>
  | IAuditoriaVeribox
  | IListado<IAuditoriaVeribox>
  | IMantenimiento
  | IListado<IMantenimiento>
  | ILocalidad
  | IListado<ILocalidad>
  | ILogReporte
  | IListado<ILogReporte>
  | ILogTwilio
  | IListado<ILogTwilio>
  | ILogNuc
  | IListado<ILogNuc>
  | IScada
  | IListado<IScada>
  | IConfigNotificacion
  | IListado<IConfigNotificacion>;

class RequestQueue {
  subscribe: Subject<Tipo>;
  requests: number;
  cache?: Tipo;

  constructor() {
    this.requests = 0;
    this.subscribe = new Subject<Tipo>();
    this.cache = undefined;
  }
}

interface IRequestId {
  fn: (id: string) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IRequestQuery {
  fn: (query: IQueryParam) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IEntidades {
  usuarioPropio: IRequestQuery;
  //
  usuario: IRequestId;
  usuarios: IRequestQuery;
  dispositivo: IRequestId;
  dispositivoDeveui: IRequestId;
  dispositivos: IRequestQuery;
  unidadNegocio: IRequestId;
  unidadNegocios: IRequestQuery;
  cuenca: IRequestId;
  cuencas: IRequestQuery;
  correctora: IRequestId;
  correctoras: IRequestQuery;
  cromatografia: IRequestId;
  cromatografias: IRequestQuery;
  registro: IRequestId;
  registros: IRequestQuery;
  medidorResidencial: IRequestId;
  medidorResidencials: IRequestQuery;
  medidorResidencialAgua: IRequestId;
  medidorResidencialAguas: IRequestQuery;
  alerta: IRequestId;
  alertas: IRequestQuery;
  aplicacionCromatografia: IRequestId;
  aplicacionCromatografias: IRequestQuery;
  centroOperativo: IRequestId;
  centroOperativos: IRequestQuery;
  grupo: IRequestId;
  grupos: IRequestQuery;
  agrupacion: IRequestId;
  agrupacions: IRequestQuery;
  auditoria: IRequestId;
  auditorias: IRequestQuery;
  notificacions: IRequestQuery;
  notificacionsSinLeer: IRequestId;
  configDispositivo: IRequestId;
  configDispositivos: IRequestQuery;
  configDispositivoByTag: IRequestId;
  puntoMedicion: IRequestId;
  puntosMedicion: IRequestQuery;
  unidadPresion: IRequestId;
  unidadPresions: IRequestQuery;
  reporte: IRequestId;
  reportes: IRequestQuery;
  envioSms: IRequestId;
  envioSmss: IRequestQuery;
  auditoriaVeribox: IRequestId;
  auditoriaVeriboxs: IRequestQuery;
  mantenimiento: IRequestId;
  mantenimientos: IRequestQuery;
  resumenReportes: IRequestQuery;
  localidad: IRequestId;
  localidads: IRequestQuery;
  // logReporte: IRequestId;
  logReportes: IRequestQuery;
  // logTwilio: IRequestId;
  logTwilios: IRequestQuery;
  logNucs: IRequestQuery;
  scada: IRequestId;
  scadas: IRequestQuery;
  confignotificacion: IRequestId;
  confignotificacions: IRequestQuery;
}

@Injectable({
  providedIn: 'root',
})
export class ListadosService {
  private entidades: IEntidades = this.getInitCache();

  constructor(
    private webSocketService: WebSocketService,
    private usuariosService: UsuariosService,
    private dispositivosService: DispositivosService,
    private unidadNegociosService: UnidadNegociosService,
    private cuencasService: CuencasService,
    private correctorasService: CorrectorasService,
    private cromatografiasService: CromatografiasService,
    private registrosService: RegistrosService,
    private medidorResidencialsService: MedidorResidencialsService,
    private medidorResidencialAguaService: MedidorResidencialesAguaService,
    private alertasService: AlertasService,
    private aplicacionCromatografiasService: AplicacionCromatografiasService,
    private centroOperativosService: CentroOperativosService,
    private gruposService: GruposService,
    private agrupacionsService: AgrupacionsService,
    private auditoriasService: AuditoriaService,
    private notificacionesService: NotificacionesService,
    private configDispositivosService: ConfigDispositivosService,
    private puntosMedicionService: PuntosMedicionService,
    private unidadPresionsService: UnidadPresionsService,
    private reportesService: ReportesService,
    private envioSmssService: EnvioSmssService,
    private auditoriaVertiboxService: AuditoriaVertiboxService,
    private mantenimientosService: MantenimientosService,
    private resumenReportesService: ResumenReportesService,
    private localidadService: LocalidadService,
    private logReportesService: LogReportesService,
    private logTwiliosService: LogTwiliosService,
    private logNucService: LogNucService,
    private scadaService: ScadasService,
    private configNotificacionService: ConfigNotificacionsService,
  ) {
    this.subscribeWsUpdates();
  }

  //

  // Subscribe

  public subscribe<Tipo>(
    entidad: keyof IEntidades,
    query: IQueryParam | string,
  ): Observable<Tipo> {
    const key = typeof query === 'string' ? query : JSON.stringify(query);
    const ent = this.entidades[entidad];
    if (!this.entidades[entidad]) {
      throw new Error(`No existe la entidad ${entidad}`);
    } else {
      if (!ent.keys[key]) {
        ent.keys[key] = new RequestQueue();
      }
    }
    return ent.keys[key].subscribe.asObservable() as any;
  }

  public async getLastValue(
    entidad: keyof IEntidades,
    query: IQueryParam | string,
  ): Promise<void> {
    const ent = this.entidades[entidad];
    if (!this.entidades[entidad]) {
      throw new Error(`No existe la entidad ${entidad}`);
    } else {
      if (typeof query === 'string') {
        await this.listarId(entidad, query, (ent as IRequestId).fn);
      } else {
        await this.listarQuery(entidad, query, (ent as IRequestQuery).fn);
      }
    }
  }

  // Listados Entidades
  private async listarUsuario(id: string): Promise<IUsuario> {
    const response = await firstValueFrom(this.usuariosService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }
  private async listarUsuarios(
    query: IQueryParam,
  ): Promise<IListado<IUsuario>> {
    const response = await firstValueFrom(this.usuariosService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  /// PROPIO
  private async listarUsuarioPropio(): Promise<IUsuario> {
    const response = await firstValueFrom(this.usuariosService.listarPropio());
    return JSON.parse(JSON.stringify(response));
  }
  ///

  private async listarDispositivo(id: string): Promise<IDispositivo> {
    const response = await firstValueFrom(
      this.dispositivosService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarDispositivoDeveui(deveui: string): Promise<IDispositivo> {
    const response = await this.dispositivosService.listarPorDeveui(deveui);
    return JSON.parse(JSON.stringify(response));
  }
  private async listarDispositivos(
    query: IQueryParam,
  ): Promise<IListado<IDispositivo>> {
    const response = await firstValueFrom(
      this.dispositivosService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarUnidadNegocio(id: string): Promise<IUnidadNegocio> {
    const response = await firstValueFrom(
      this.unidadNegociosService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarUnidadNegocios(
    query: IQueryParam,
  ): Promise<IListado<IUnidadNegocio>> {
    const response = await firstValueFrom(
      this.unidadNegociosService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarCuenca(id: string): Promise<ICuenca> {
    const response = await firstValueFrom(this.cuencasService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }
  private async listarCuencas(query: IQueryParam): Promise<IListado<ICuenca>> {
    const response = await firstValueFrom(this.cuencasService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarCorrectora(id: string): Promise<ICorrectora> {
    const response = await firstValueFrom(
      this.correctorasService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarCorrectoras(
    query: IQueryParam,
  ): Promise<IListado<ICorrectora>> {
    const response = await firstValueFrom(
      this.correctorasService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarCromatografia(id: string): Promise<ICromatografia> {
    const response = await firstValueFrom(
      this.cromatografiasService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarCromatografias(
    query: IQueryParam,
  ): Promise<IListado<ICromatografia>> {
    const response = await firstValueFrom(
      this.cromatografiasService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarRegistro(id: string): Promise<IRegistro> {
    const response = await firstValueFrom(
      this.registrosService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarRegistros(
    query: IQueryParam,
  ): Promise<IListado<IRegistro>> {
    const response = await firstValueFrom(this.registrosService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarMedidorResidencial(
    id: string,
  ): Promise<IMedidorResidencial> {
    const response = await firstValueFrom(
      this.medidorResidencialsService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarMedidorResidencials(
    query: IQueryParam,
  ): Promise<IListado<IMedidorResidencial>> {
    const response = await firstValueFrom(
      this.medidorResidencialsService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarMedidorResidencialAgua(
    id: string,
  ): Promise<IMedidorResidencial> {
    const response = await firstValueFrom(
      this.medidorResidencialAguaService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarMedidorResidencialAguas(
    query: IQueryParam,
  ): Promise<IListado<IMedidorResidencial>> {
    const response = await firstValueFrom(
      this.medidorResidencialAguaService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarAlerta(id: string): Promise<IAlerta> {
    const response = await firstValueFrom(this.alertasService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }
  private async listarAlertas(query: IQueryParam): Promise<IListado<IAlerta>> {
    const response = await firstValueFrom(this.alertasService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarAplicacionCromatografia(
    id: string,
  ): Promise<IAplicacionCromatografia> {
    const response = await firstValueFrom(
      this.aplicacionCromatografiasService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarAplicacionCromatografias(
    query: IQueryParam,
  ): Promise<IListado<IAplicacionCromatografia>> {
    const response = await firstValueFrom(
      this.aplicacionCromatografiasService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarCentroOperativo(id: string): Promise<ICentroOperativo> {
    const response = await firstValueFrom(
      this.centroOperativosService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarCentroOperativos(
    query: IQueryParam,
  ): Promise<IListado<ICentroOperativo>> {
    const response = await firstValueFrom(
      this.centroOperativosService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarGrupo(id: string): Promise<IGrupo> {
    const response = await firstValueFrom(this.gruposService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }
  private async listarGrupos(query: IQueryParam): Promise<IListado<IGrupo>> {
    const response = await firstValueFrom(this.gruposService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarAgrupacion(id: string): Promise<IAgrupacion> {
    const response = await firstValueFrom(
      this.agrupacionsService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarAgrupacions(
    query: IQueryParam,
  ): Promise<IListado<IAgrupacion>> {
    const response = await firstValueFrom(
      this.agrupacionsService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarAuditoria(id: string): Promise<IAuditoria> {
    const response = await firstValueFrom(
      this.auditoriasService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarAuditorias(
    query: IQueryParam,
  ): Promise<IListado<IAuditoria>> {
    const response = await firstValueFrom(this.auditoriasService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarNotificacions(
    query: IQueryParam,
  ): Promise<IListado<INotificacion>> {
    const response = await firstValueFrom(
      this.notificacionesService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async notificacionsSinLeer(): Promise<{
    cantidadSinLeer: number;
  }> {
    const response = await firstValueFrom(
      this.notificacionesService.cantidadSinLeer(),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarConfigDispositivo(deveui: string): Promise<IAuditoria> {
    const response = await firstValueFrom(
      this.configDispositivosService.getByDeveui(deveui),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarConfigDispositivos(
    query: IQueryParam,
  ): Promise<IListado<IConfigDispositivo>> {
    const response = await firstValueFrom(
      this.configDispositivosService.getFiltered(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarConfigDispositivoByTag(
    tag: string,
  ): Promise<IConfigDispositivo> {
    const response = await firstValueFrom(
      this.configDispositivosService.getByTag(tag),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarPuntoMedicion(id: string): Promise<IPuntoMedicion> {
    const response = await firstValueFrom(
      this.puntosMedicionService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarPuntosMedicion(
    query: IQueryParam,
  ): Promise<IListado<IPuntoMedicion>> {
    const response = await firstValueFrom(
      this.puntosMedicionService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarUnidadPresion(id: string): Promise<IUnidadPresion> {
    const response = await firstValueFrom(
      this.unidadPresionsService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarUnidadPresions(
    query: IQueryParam,
  ): Promise<IListado<IUnidadPresion>> {
    const response = await firstValueFrom(
      this.unidadPresionsService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarReporte(id: string): Promise<IReporte> {
    const response = await firstValueFrom(this.reportesService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }
  private async listarReportes(
    query: IQueryParam,
  ): Promise<IListado<IReporte>> {
    const response = await firstValueFrom(this.reportesService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarEnvioSms(id: string): Promise<IEnvioSms> {
    const response = await firstValueFrom(
      this.envioSmssService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarEnvioSmss(
    query: IQueryParam,
  ): Promise<IListado<IEnvioSms>> {
    const response = await firstValueFrom(this.envioSmssService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarAuditoriaVeribox(id: string): Promise<IAuditoriaVeribox> {
    const response = await firstValueFrom(
      this.auditoriaVertiboxService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarAuditoriaVeriboxs(
    query: IQueryParam,
  ): Promise<IListado<IAuditoriaVeribox>> {
    const response = await firstValueFrom(
      this.auditoriaVertiboxService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarMantenimiento(id: string): Promise<IMantenimiento> {
    const response = await firstValueFrom(
      this.mantenimientosService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarMantenimientos(
    query: IQueryParam,
  ): Promise<IListado<IMantenimiento>> {
    const response = await firstValueFrom(
      this.mantenimientosService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarResumenReportes(
    query: IQueryParam,
  ): Promise<IListado<IResumenReporte>> {
    const response = await firstValueFrom(
      this.resumenReportesService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLocalidad(id: string): Promise<ILocalidad> {
    const response = await firstValueFrom(
      this.localidadService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarLocalidads(
    query: IQueryParam,
  ): Promise<IListado<ILocalidad>> {
    const response = await firstValueFrom(this.localidadService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLogReportes(
    query: IQueryParam,
  ): Promise<IListado<ILogReporte>> {
    const response = await firstValueFrom(
      this.logReportesService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLogTwilios(
    query: IQueryParam,
  ): Promise<IListado<ILogTwilio>> {
    const response = await firstValueFrom(this.logTwiliosService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLogNucs(query: IQueryParam): Promise<IListado<ILogNuc>> {
    const response = await firstValueFrom(this.logNucService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarScada(id: string): Promise<IScada> {
    const response = await firstValueFrom(this.scadaService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }
  private async listarScadas(query: IQueryParam): Promise<IListado<IScada>> {
    const response = await firstValueFrom(this.scadaService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarConfigNotificacion(
    id: string,
  ): Promise<IConfigNotificacion> {
    const response = await firstValueFrom(
      this.configNotificacionService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }
  private async listarConfigNotificacions(
    query: IQueryParam,
  ): Promise<IListado<IConfigNotificacion>> {
    const response = await firstValueFrom(
      this.configNotificacionService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  // Borrar cache
  private getInitCache(): IEntidades {
    return {
      usuarioPropio: { fn: this.listarUsuarioPropio.bind(this), keys: {} },
      ///
      usuario: { fn: this.listarUsuario.bind(this), keys: {} },
      usuarios: { fn: this.listarUsuarios.bind(this), keys: {} },
      auditoria: { fn: this.listarAuditoria.bind(this), keys: {} },
      auditorias: { fn: this.listarAuditorias.bind(this), keys: {} },
      dispositivo: { fn: this.listarDispositivo.bind(this), keys: {} },
      dispositivoDeveui: {
        fn: this.listarDispositivoDeveui.bind(this),
        keys: {},
      },
      dispositivos: { fn: this.listarDispositivos.bind(this), keys: {} },
      unidadNegocio: { fn: this.listarUnidadNegocio.bind(this), keys: {} },
      unidadNegocios: { fn: this.listarUnidadNegocios.bind(this), keys: {} },
      cuenca: { fn: this.listarCuenca.bind(this), keys: {} },
      cuencas: { fn: this.listarCuencas.bind(this), keys: {} },
      correctora: { fn: this.listarCorrectora.bind(this), keys: {} },
      correctoras: { fn: this.listarCorrectoras.bind(this), keys: {} },
      cromatografia: { fn: this.listarCromatografia.bind(this), keys: {} },
      cromatografias: { fn: this.listarCromatografias.bind(this), keys: {} },
      registro: { fn: this.listarRegistro.bind(this), keys: {} },
      registros: { fn: this.listarRegistros.bind(this), keys: {} },
      medidorResidencial: {
        fn: this.listarMedidorResidencial.bind(this),
        keys: {},
      },
      medidorResidencials: {
        fn: this.listarMedidorResidencials.bind(this),
        keys: {},
      },
      medidorResidencialAgua: {
        fn: this.listarMedidorResidencialAgua.bind(this),
        keys: {},
      },
      medidorResidencialAguas: {
        fn: this.listarMedidorResidencialAguas.bind(this),
        keys: {},
      },
      alerta: { fn: this.listarAlerta.bind(this), keys: {} },
      alertas: { fn: this.listarAlertas.bind(this), keys: {} },
      aplicacionCromatografia: {
        fn: this.listarAplicacionCromatografia.bind(this),
        keys: {},
      },
      aplicacionCromatografias: {
        fn: this.listarAplicacionCromatografias.bind(this),
        keys: {},
      },
      centroOperativo: {
        fn: this.listarCentroOperativo.bind(this),
        keys: {},
      },
      centroOperativos: {
        fn: this.listarCentroOperativos.bind(this),
        keys: {},
      },
      grupo: { fn: this.listarGrupo.bind(this), keys: {} },
      grupos: { fn: this.listarGrupos.bind(this), keys: {} },
      agrupacion: { fn: this.listarAgrupacion.bind(this), keys: {} },
      agrupacions: { fn: this.listarAgrupacions.bind(this), keys: {} },
      notificacions: { fn: this.listarNotificacions.bind(this), keys: {} },
      notificacionsSinLeer: {
        fn: this.notificacionsSinLeer.bind(this),
        keys: {},
      },
      configDispositivo: {
        fn: this.listarConfigDispositivo.bind(this),
        keys: {},
      },
      configDispositivos: {
        fn: this.listarConfigDispositivos.bind(this),
        keys: {},
      },
      configDispositivoByTag: {
        fn: this.listarConfigDispositivoByTag.bind(this),
        keys: {},
      },
      puntoMedicion: { fn: this.listarPuntoMedicion.bind(this), keys: {} },
      puntosMedicion: { fn: this.listarPuntosMedicion.bind(this), keys: {} },
      unidadPresion: { fn: this.listarUnidadPresion.bind(this), keys: {} },
      unidadPresions: { fn: this.listarUnidadPresions.bind(this), keys: {} },
      reporte: { fn: this.listarReporte.bind(this), keys: {} },
      reportes: { fn: this.listarReportes.bind(this), keys: {} },
      envioSms: { fn: this.listarEnvioSms.bind(this), keys: {} },
      envioSmss: { fn: this.listarEnvioSmss.bind(this), keys: {} },
      auditoriaVeribox: {
        fn: this.listarAuditoriaVeribox.bind(this),
        keys: {},
      },
      auditoriaVeriboxs: {
        fn: this.listarAuditoriaVeriboxs.bind(this),
        keys: {},
      },
      mantenimiento: {
        fn: this.listarMantenimiento.bind(this),
        keys: {},
      },
      mantenimientos: {
        fn: this.listarMantenimientos.bind(this),
        keys: {},
      },
      resumenReportes: {
        fn: this.listarResumenReportes.bind(this),
        keys: {},
      },
      localidad: { fn: this.listarLocalidad.bind(this), keys: {} },
      localidads: { fn: this.listarLocalidads.bind(this), keys: {} },
      // logReporte: { fn: this.listarLogReporte.bind(this), keys: {} },
      logReportes: { fn: this.listarLogReportes.bind(this), keys: {} },
      // logTwilio: { fn: this.listarLogTwilio.bind(this), keys: {} },
      logTwilios: { fn: this.listarLogTwilios.bind(this), keys: {} },
      logNucs: { fn: this.listarLogNucs.bind(this), keys: {} },
      scada: { fn: this.listarScada.bind(this), keys: {} },
      scadas: { fn: this.listarScadas.bind(this), keys: {} },
      confignotificacion: {
        fn: this.listarConfigNotificacion.bind(this),
        keys: {},
      },
      confignotificacions: {
        fn: this.listarConfigNotificacions.bind(this),
        keys: {},
      },
    };
  }

  public borrarCache() {
    this.entidades = this.getInitCache();
  }

  // Actualizar Entidades
  // Actualizar Entidades
  private async actualizarQuery(entidad: keyof IEntidades): Promise<void> {
    const ent = this.entidades[entidad] as IRequestQuery;
    for (const key in ent.keys) {
      if (ent.keys[key].requests === 0) {
        if (Object.prototype.hasOwnProperty.call(ent.keys, key)) {
          ent.keys[key].cache = undefined;
          const query = JSON.parse(key);
          ent.keys[key].requests++;
          while (
            ent.keys[key].requests &&
            ent.keys[key].subscribe.observers.length
          ) {
            ent.keys[key].cache = undefined;
            await this.listarQuery(entidad, query, ent.fn);
            const entidadesAEsperar: (keyof IEntidades)[] = [
              'reporte',
              'reportes',
              'puntosMedicion',
              'scadas',
            ];
            if (entidadesAEsperar.includes(entidad)) await this.wait(10000);
            ent.keys[key].requests--;
          }
        }
      } else if (ent.keys[key].requests < 2) {
        ent.keys[key].requests++;
      }
    }
  }

  private wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private async actualizarId(
    entidad: keyof IEntidades,
    id?: string,
  ): Promise<void> {
    if (id) {
      const ent = this.entidades[entidad] as IRequestId;
      if (ent.keys[id]) {
        if (ent.keys[id].requests === 0) {
          ent.keys[id].cache = undefined;
          if (ent.keys[id].subscribe.observers.length) {
            ent.keys[id].requests++;
            while (ent.keys[id].requests) {
              ent.keys[id].cache = undefined;
              await this.listarId(entidad, id, ent.fn);
              ent.keys[id].requests--;
            }
          }
        } else if (ent.keys[id].requests < 2) {
          ent.keys[id].requests++;
        }
      }
    }
  }

  // Listados Generales

  private async listarQuery(
    entidad: keyof IEntidades,
    query: IQueryParam,
    fn: (query: IQueryParam) => Promise<any>,
  ): Promise<void> {
    const ent = this.entidades[entidad];
    const key = JSON.stringify(query);
    if (!ent.keys[key].cache) {
      const response = await fn(query);
      ent.keys[key].cache = JSON.parse(JSON.stringify(response));
    }
    ent.keys[key].subscribe.next(ent.keys[key].cache!);
  }

  private async listarId(
    entidad: keyof IEntidades,
    id: string,
    fn: (id: string) => Promise<any>,
  ): Promise<void> {
    const ent = this.entidades[entidad];
    if (!ent.keys[id].cache) {
      const response = await fn(id);
      ent.keys[id].cache = JSON.parse(JSON.stringify(response));
    }
    ent.keys[id].subscribe.next(ent.keys[id].cache!);
  }

  // Suscripcion a WS Service para eliminar cache y actualizar entidades
  private subscribeWsUpdates() {
    this.webSocketService.getMessage().subscribe({
      next: this.handleUpdateResponse.bind(this),
    });
  }
  private handleUpdateResponse(message: ISocketMessage) {
    if (message.paths?.includes('usuarios')) {
      this.actualizarQuery('usuarios');
      this.actualizarId('usuario', message.body?._id);
      this.actualizarQuery('usuarioPropio');
    }
    if (message.paths?.includes('auditorias')) {
      this.actualizarQuery('auditorias');
      this.actualizarId('auditoria', message.body?._id);
    }
    if (message.paths?.includes('dispositivos')) {
      const body = message.body as IDispositivo;
      this.actualizarQuery('dispositivos');
      this.actualizarId('dispositivo', message.body?._id);
      this.actualizarId('dispositivoDeveui', body.deveui);
    }
    if (message.paths?.includes('unidadnegocios')) {
      this.actualizarQuery('unidadNegocios');
      this.actualizarId('unidadNegocio', message.body?._id);
    }
    if (message.paths?.includes('cuencas')) {
      this.actualizarQuery('cuencas');
      this.actualizarId('cuenca', message.body?._id);
    }
    if (message.paths?.includes('correctoras')) {
      this.actualizarQuery('correctoras');
      this.actualizarId('correctora', message.body?._id);
    }
    if (message.paths?.includes('cromatografias')) {
      this.actualizarQuery('cromatografias');
      this.actualizarId('cromatografia', message.body?._id);
    }
    if (message.paths?.includes('registros')) {
      this.actualizarQuery('registros');
      this.actualizarId('registro', message.body?._id);
    }
    if (message.paths?.includes('medidorresidencials')) {
      this.actualizarQuery('medidorResidencials');
      this.actualizarId('medidorResidencial', message.body?._id);
    }
    if (message.paths?.includes('medidorresidencialagua')) {
      this.actualizarQuery('medidorResidencialAgua');
      this.actualizarId('medidorResidencialAgua', message.body?._id);
    }
    if (message.paths?.includes('alertas')) {
      this.actualizarQuery('alertas');
      this.actualizarId('alerta', message.body?._id);
    }
    if (message.paths?.includes('aplicacioncromatografias')) {
      this.actualizarQuery('aplicacionCromatografias');
      this.actualizarId('aplicacionCromatografia', message.body?._id);
    }
    if (message.paths?.includes('centrooperativos')) {
      this.actualizarQuery('centroOperativos');
      this.actualizarId('centroOperativo', message.body?._id);
    }
    if (message.paths?.includes('grupos')) {
      this.actualizarQuery('grupos');
      this.actualizarId('grupo', message.body?._id);
    }
    if (message.paths?.includes('agrupacions')) {
      this.actualizarQuery('agrupacions');
      this.actualizarId('agrupacion', message.body?._id);
    }
    if (message.paths?.includes('notificacions')) {
      this.actualizarQuery('notificacions');
      this.actualizarId('notificacionsSinLeer', '0');
    }
    if (message.paths?.includes('configdispositivos')) {
      const body = message.body as IConfigDispositivo;
      this.actualizarId('configDispositivo', body.deveui);
      this.actualizarId('configDispositivoByTag', body.tag);
      this.actualizarQuery('configDispositivos');
    }
    if (message.paths?.includes('puntosdemedicion')) {
      this.actualizarQuery('puntosMedicion');
      this.actualizarId('puntoMedicion', message.body?._id);
    }
    if (message.paths?.includes('unidadpresions')) {
      this.actualizarQuery('unidadPresions');
      this.actualizarId('unidadPresion', message.body?._id);
    }
    if (message.paths?.includes('enviosmss')) {
      this.actualizarQuery('envioSmss');
      this.actualizarId('envioSms', message.body?._id);
    }
    if (message.paths?.includes('auditoriaveriboxs')) {
      this.actualizarQuery('auditoriaVeriboxs');
      this.actualizarId('auditoriaVeribox', message.body?._id);
    }
    if (message.paths?.includes('reportes')) {
      this.actualizarQuery('reportes');
      this.actualizarId('reporte', message.body?._id);
    }
    if (message.paths?.includes('mantenimientos')) {
      this.actualizarQuery('mantenimientos');
      this.actualizarId('mantenimiento', message.body?._id);
    }
    if (message.paths?.includes('resumenReportes')) {
      this.actualizarQuery('resumenReportes');
    }
    if (message.paths?.includes('localidads')) {
      this.actualizarQuery('localidads');
      this.actualizarId('localidad', message.body?._id);
    }
    if (message.paths?.includes('scadas')) {
      this.actualizarQuery('scadas');
      this.actualizarId('scada', message.body?._id);
      this.actualizarQuery('puntosMedicion');
    }
    if (message.paths?.includes('confignotificacions')) {
      this.actualizarQuery('confignotificacions');
      this.actualizarId('confignotificacion', message.body?._id);
    }
  }
}
