import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { LogoutService } from '../../login/logout.service';
import { IUsuario } from 'modelos/src';
import { HelperService } from '../../../auxiliares/helper.service';
import { version } from '../../../../environments/version';
import { env } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { NavigationService } from '../navigation.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChangelogComponent } from '../../correctoras/changelog/changelog.component';
import { LoginService } from '../../login/login.service';
import { WarningTelefonoService } from 'src/app/auxiliares/warning-telefono/warning-telefono.service';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { LoadingService } from 'src/app/auxiliares/loading.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

type Envs = 'Local' | 'Dev' | 'Test' | 'Prod';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') private sidenav?: MatSidenav;
  public usuario?: IUsuario;
  public usuario$?: Subscription;

  public version = version;
  public env: Envs = env;
  public logo?: string = 'assets/images/logo-light.png';

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private breakpointObserver: BreakpointObserver,
    private dialogService: DialogService,
    private logoutService: LogoutService,
    public helper: HelperService,
    private service: NavigationService,
    public matDialog: MatDialog,
    public login: LoginService,
    private warningTelefono: WarningTelefonoService,
    private listados: ListadosService,
    public loading: LoadingService,
    private router: Router,
  ) {}

  public logout() {
    this.dialogService
      .confirm('Confirme la acción', '¿Desea cerrar la sesión?')
      .then((confirm) => {
        if (confirm) {
          this.logoutService.logout();
        }
      });
  }

  public toggleTheme() {
    if (this.document.body.classList.contains('dark-theme')) {
      this.setClaro();
    } else {
      this.setOscuro();
    }
  }

  public setClaro() {
    this.renderer.removeClass(this.document.body, 'dark-theme');
    this.renderer.addClass(this.document.body, 'light-theme');
    this.logo = 'assets/images/logo-light.png';
    this.service.modoOscuro$.next(false);
    localStorage.removeItem('temaOscuro');
  }

  public setOscuro() {
    this.renderer.addClass(this.document.body, 'dark-theme');
    this.renderer.removeClass(this.document.body, 'light-theme');
    this.logo = 'assets/images/logo-dark.png';
    this.service.modoOscuro$.next(true);
    localStorage.setItem('temaOscuro', 'true');
  }

  private temaInicial() {
    if (localStorage.getItem('temaOscuro') === 'true') {
      this.setOscuro();
    } else {
      this.setClaro();
    }
  }

  public async changelog(): Promise<void> {
    const config: MatDialogConfig = {
      width: '1200px',

      panelClass: 'redondo-abajo-izquierda',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(ChangelogComponent, config);
  }

  private async checkUsuario() {
    this.usuario$?.unsubscribe();
    this.usuario$ = this.listados
      .subscribe<IUsuario>('usuarioPropio', {})
      .subscribe((data) => {
        this.usuario = data;
        LoginService.setUsuario(data);
        if (data.datosPersonales?.telefono) {
          this.login.tieneTelefono = true;
        } else {
          this.login.tieneTelefono = false;
        }
        console.log(`listado de usuarioPropio`, data);
      });
    await this.listados.getLastValue('usuarioPropio', {});
    this.checkTel();
    const usuarioScada = this.usuarioScada();
    if (usuarioScada) {
      /// Mover a Puntos Scada y cerrar el cosito
      this.sidenav?.close();
      this.router.navigate(['main/puntosMedicionScada']);
    }
  }

  private usuarioScada() {
    const permisos = this.usuario?.permisos || [];
    const cond1 =
      permisos.length === 1 &&
      (permisos[0].division === 'SCADA Unifilares' ||
        permisos[0].division === 'SCADA Mediciones');
    const cond2 =
      permisos.length === 2 &&
      ((permisos[0].division === 'SCADA Unifilares' &&
        permisos[1].division === 'SCADA Mediciones') ||
        (permisos[0].division === 'SCADA Mediciones' &&
          permisos[1].division === 'SCADA Unifilares'));
    return cond1 || cond2;
  }

  private checkTel() {
    if (!this.login.tieneTelefono) {
      this.warningTelefono.cambiarTelefono();
    }
  }

  //

  async ngOnInit() {
    this.temaInicial();
    await this.checkUsuario();
    this.checkTel();
  }

  ngOnDestroy(): void {
    this.usuario$?.unsubscribe();
  }

  public dispClienteNuc(): boolean {
    return this.usuario?.cliente?.tiposDispositivo?.includes('NUC') || false;
  }

  public dispClienteSml(): boolean {
    return this.usuario?.cliente?.tiposDispositivo?.includes('SML') || false;
  }

  public dispClienteMra(): boolean {
    return this.usuario?.cliente?.tiposDispositivo?.includes('MRA') || false;
  }

  public dispClienteScadaUnifilar(): boolean {
    return (
      this.usuario?.cliente?.tiposDispositivo?.includes('SCADA Unifilares') ||
      false
    );
  }

  public dispClienteScadaMedicion(): boolean {
    return (
      this.usuario?.cliente?.tiposDispositivo?.includes('SCADA Mediciones') ||
      false
    );
  }
}
