import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RolesGlobalesGuard } from '../../auxiliares/guards/rol.guard';
import { CentroOperativosModule } from '../centroOperativos/centroOperativos.module';
import { CorrectorasModule } from '../correctoras/correctoras.module';
import { CromatografiasModule } from '../cromatografias/cromatografias.module';
import { CuencasModule } from '../cuencas/cuencas.module';
import { DispositivosModule } from '../dispositivos/dispositivos.module';
import { ExportarModule } from '../exportar/module';
import { GruposModule } from '../grupos/grupos.module';
import { LandingModule } from '../landing/landing.module';
import { MapaModule } from '../mapa/mapa.module';
import { MedidorResidencialsModule } from '../medidores-residenciales/medidores-residenciales.module';
import { UnidadNegociosModule } from '../unidad-negocios/unidad-negocios.module';
import { UsuariosModule } from '../usuarios/usuarios.module';
import { NavigationComponent } from './navigation/navigation.component';
import { AuditoriasModule } from '../auditorias/auditorias.module';
import { CambiarPasswordModule } from '../cambiar-password/cambiar-password.module';
import { MedidorResidencialesAguaModule } from '../medidores-residenciales-agua/medidores-residenciales-agua.module';
import { PuntosDeMedicionModule } from '../puntos-medicion/module';
import { UnidadPresionsModule } from '../unidad-presion/module';
import { SmsModule } from '../sms/module';
import { EstadisticasModule } from '../estadisticas/module';
import { AgrupacionsModule } from '../agrupacion/agrupacion.module';
import { LocalidadModule } from '../localidad/module';
import { LogNucModule } from '../logNuc/logNuc.module';
import { ClientesModule } from '../apn/clientes.module';
import { ScadasModule } from '../scadas/module';
import { PuntosMedicionScadaModule } from '../puntos-medicion-scada/module';
import { ConfigNotificacionesModule } from '../notificaciones-scada/module';

const routes: Routes = [
  {
    path: '',
    component: NavigationComponent,
    children: [
      {
        path: 'landing',
        loadChildren: () => LandingModule,
      },
      {
        path: 'puntosMedicion',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador', 'Usuario', 'Visualizar'] },
        loadChildren: () => PuntosDeMedicionModule,
      },
      {
        path: 'puntosMedicionScada',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador', 'Usuario', 'Visualizar'] },
        loadChildren: () => PuntosMedicionScadaModule,
      },
      {
        path: 'correctoras',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador', 'Usuario', 'Visualizar'] },
        loadChildren: () => CorrectorasModule,
      },
      {
        path: 'presiones',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador', 'Usuario', 'Visualizar'] },
        loadChildren: () => UnidadPresionsModule,
      },
      {
        path: 'scadas',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador', 'Usuario', 'Visualizar'] },
        loadChildren: () => ScadasModule,
      },
      {
        path: 'auditorias',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador'], niveles: ['Global'] },
        loadChildren: () => AuditoriasModule,
      },
      {
        path: 'medidores',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador', 'Usuario', 'Visualizar'] },
        loadChildren: () => MedidorResidencialsModule,
      },
      {
        path: 'medidoresAgua',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador', 'Usuario', 'Visualizar'] },
        loadChildren: () => MedidorResidencialesAguaModule,
      },
      {
        path: 'usuarios',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador'], niveles: ['Global'] },
        loadChildren: () => UsuariosModule,
      },
      {
        path: 'dispositivos',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador', 'Usuario', 'Visualizar'] },
        loadChildren: () => DispositivosModule,
      },
      {
        path: 'logNuc',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador', 'Usuario', 'Visualizar'] },
        loadChildren: () => LogNucModule,
      },
      {
        path: 'unidadnegocios',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador'], niveles: ['Global'] },
        loadChildren: () => UnidadNegociosModule,
      },
      {
        path: 'centroOperativos',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador'], niveles: ['Global'] },
        loadChildren: () => CentroOperativosModule,
      },
      {
        path: 'localidades',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador'], niveles: ['Global'] },
        loadChildren: () => LocalidadModule,
      },
      {
        path: 'cuencas',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador'], niveles: ['Global'] },
        loadChildren: () => CuencasModule,
      },
      {
        path: 'grupos',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador'] },
        loadChildren: () => GruposModule,
      },
      {
        path: 'agrupaciones',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador'] },
        loadChildren: () => AgrupacionsModule,
      },
      {
        path: 'cromatografias',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador', 'Croma'] },
        loadChildren: () => CromatografiasModule,
      },
      {
        path: 'mapa',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador', 'Usuario', 'Visualizar'] },
        loadChildren: () => MapaModule,
      },
      {
        path: 'exportar',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador', 'Usuario', 'Visualizar'] },
        loadChildren: () => ExportarModule,
      },
      {
        path: 'estadisticas',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador', 'Usuario', 'Visualizar'] },
        loadChildren: () => EstadisticasModule,
      },
      {
        path: 'usuario',
        // canActivate: [RolesGlobalesGuard],
        // data: { roles: ['Administrador', 'Usuario', 'Visualizar'] },
        loadChildren: () => CambiarPasswordModule,
      },
      {
        path: 'sms',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador'] },
        loadChildren: () => SmsModule,
      },
      {
        path: 'notificaciones-scada',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador'] },
        loadChildren: () => ConfigNotificacionesModule,
      },
      {
        path: 'apn',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Administrador'], niveles: ['Global'] },
        loadChildren: () => ClientesModule,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NavigationRoutingModule {}
