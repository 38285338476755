import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IConfigDispositivo,
  ICreateConfigDispositivo,
  IListado,
  IQueryParam,
} from 'modelos/src';
import { HttpService } from '../../auxiliares/http.service';
import { HelperService } from '../../auxiliares/helper.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigDispositivosService {
  constructor(private http: HttpService) {}

  public getFiltered(
    queryParams?: IQueryParam,
  ): Observable<IListado<IConfigDispositivo>> {
    const params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/configdispositivos`, { params });
  }

  public getByDeveui(deveui: string): Observable<IConfigDispositivo> {
    return this.http.get(`/configdispositivos/deveui/${deveui}`);
  }

  public getByTag(tag: string): Observable<IConfigDispositivo> {
    return this.http.get(`/configdispositivos/tag/${tag}`);
  }

  public upsert(
    dato: ICreateConfigDispositivo,
    veribox: boolean = false,
    enviarSms: boolean = true,
    enviarInmediatamente: boolean = false,
  ): Observable<IConfigDispositivo> {
    return this.http.post(`/configdispositivos/upsert`, {
      dato,
      veribox,
      enviarSms,
      enviarInmediatamente,
    });
  }

  public upsertLimitesScada(
    dato: ICreateConfigDispositivo,
  ): Observable<IConfigDispositivo> {
    return this.http.post(`/configdispositivos/upsert/limitesScada`, {
      dato,
    });
  }

  public reaplicarConfig(tag: string): Observable<void> {
    return this.http.post(`/configdispositivos/reaplicarConfig/${tag}`, {});
  }

  public eliminar(deveui: string): Observable<IConfigDispositivo> {
    return this.http.delete(`/configdispositivos/deveui/${deveui}`);
  }

  public eliminarByTag(tag: string): Observable<IConfigDispositivo> {
    return this.http.delete(`/configdispositivos/tag/${tag}`);
  }
}
