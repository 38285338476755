<div
  class="transparente"
  style="
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  "
>
  @if (mostrar) {
    <app-chart
      *ngIf="chartValorActual"
      [options]="chartValorActual"
      style="width: 100%; max-height: 400px; max-width: 400px; display: block"
    >
    </app-chart>
    <div style="translate: 0 -30px">
      {{ scada?.ultimoRegistro?.valores?.timestamp | date: "dd/MM/yyyy HH:mm" }}
    </div>
  } @else {
    <div style="display: flex; justify-content: center; align-items: center">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  }
</div>
