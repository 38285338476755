import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IFilter, IGrupo, IListado, IQueryParam } from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import {
  IFiltroGlobal,
  NavigationService,
} from '../../navigation/navigation.service';
import { HelperService } from 'src/app/auxiliares/helper.service';

@Component({
  selector: 'app-landing-grupos',
  standalone: false,
  templateUrl: './landing-grupos.component.html',
  styleUrl: './landing-grupos.component.scss',
})
export class LandingGruposComponent implements OnInit, OnDestroy {
  public gruposU: IGrupo[] = [];
  public gruposM: IGrupo[] = [];

  private gruposU$?: Subscription;
  private gruposM$?: Subscription;

  private filtroGlobal: IFiltroGlobal = {};
  private filtroGlobal$?: Subscription;
  public hayDistintasUN: boolean = false;

  constructor(
    private listados: ListadosService,
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    public helper: HelperService,
  ) {}

  private setHayDistintasUN(): void {
    const un1Unif = this.gruposU[0]?.idUnidadNegocio;
    const distUnif = this.gruposU.find((g) => g.idUnidadNegocio !== un1Unif);
    const un1Med = this.gruposM[0]?.idUnidadNegocio;
    const distMed = this.gruposM.find((g) => g.idUnidadNegocio !== un1Med);
    this.hayDistintasUN = !!(distUnif || distMed);
    console.log(`hayDistintasUN`, this.hayDistintasUN);
  }

  public async listarU(): Promise<void> {
    const filter: IFilter<IGrupo> = {
      division: 'SCADA Unifilares',
    };
    // Agrega el filtro de unidad de negocio seleccionada
    if (this.filtroGlobal?.unidadNegocio) {
      filter.idUnidadNegocio = this.filtroGlobal?.unidadNegocio?._id;
    } else {
      delete filter.idUnidadNegocio;
    }

    const populate = {
      path: 'unidadNegocio',
      select: 'nombre',
    };

    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
    };

    this.gruposU$?.unsubscribe();
    this.gruposU$ = this.listados
      .subscribe<IListado<IGrupo>>('grupos', query)
      .subscribe((data) => {
        this.gruposU = data.datos;
        this.setHayDistintasUN();
        console.log(`listado de grupos U`, data);
      });
    await this.listados.getLastValue('grupos', query);
  }

  public async listarM(): Promise<void> {
    const filter: IFilter<IGrupo> = {
      division: 'SCADA Mediciones',
    };
    // Agrega el filtro de unidad de negocio seleccionada
    if (this.filtroGlobal?.unidadNegocio) {
      filter.idUnidadNegocio = this.filtroGlobal?.unidadNegocio?._id;
    } else {
      delete filter.idUnidadNegocio;
    }

    const populate = {
      path: 'unidadNegocio',
      select: 'nombre',
    };

    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      select: 'nombre idUnidadNegocio',
      sort: 'nombre',
    };
    this.gruposM$?.unsubscribe();
    this.gruposM$ = this.listados
      .subscribe<IListado<IGrupo>>('grupos', query)
      .subscribe((data) => {
        this.gruposM = data.datos;
        this.setHayDistintasUN();
        console.log(`listado de grupos M`, data);
      });
    await this.listados.getLastValue('grupos', query);
  }
  private suscribeFiltroGlobal() {
    this.filtroGlobal$ = this.navigationService
      .subscribeFiltroGlobal()
      .subscribe((filtro) => {
        console.log(`filtro global`, filtro);
        this.filtroGlobal = filtro;
        this.listarM();
        this.listarU();
      });
    this.filtroGlobal = this.navigationService.getFiltroGlobal();
  }
  /// Navegar
  public navegar(id: string): void {
    this.router.navigate([id], { relativeTo: this.route });
  }
  /// HOOKS
  public async ngOnInit(): Promise<void> {
    this.suscribeFiltroGlobal();
    await Promise.all([this.listarU(), this.listarM()]);
  }

  public ngOnDestroy(): void {
    this.gruposU$?.unsubscribe();
    this.gruposM$?.unsubscribe();
    this.filtroGlobal$?.unsubscribe();
  }
}
